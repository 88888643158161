export enum FeatureTitle {
  Dashboard = "Dashboard",
  TransactionManager = "Transaction Management",
  UserManagement = "User Management",
  FeeReport = "PSP fees report",
  Settings = "Settings",
  SystemErrors = "Manage System Errors",
  ErrorMapping = "Manage Error Mapping",
  Configurations = "Configurations",
  Operators = "Operators",
  Merchants = "Merchants",
}
