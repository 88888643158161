import {IActionsButtonItem} from "@/components/ActionsButton";
import {IErrorMappingData} from "@/features/error-mapping/type";

export enum ErrorMappingActionTitle {
  EditErrorMapping = "Edit",
  DeleteErrorMapping = "Delete",
}

export interface IErrorMappingActionsItemsData {
  data: IErrorMappingData,
  showEditDialog: (rowData: IErrorMappingData) => void
}

export interface IErrorMappingActionsItem extends IActionsButtonItem<ErrorMappingActionTitle> {}
