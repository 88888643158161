export enum TransactionFiltersFieldName {
  OperatorsAndMerchants = "operatorsAndMerchants",
  MerchantIds = "merchantIds",
  DateType = "dateType",
  DateRange = "dateRange",
  FromDate = "fromDate",
  ToDate = "toDate",
  UtcOffset = "utcOffset",
  PpaNames = "ppaNames",
  Providers = "providers",
  PaymentMethods = "paymentMethods",
  Statuses = "statuses",
  MerchantCurrency = "merchantCurrency",
  MidCurrency = "midCurrency",
  MerchantTransactionId = "merchantTxnId",
  SystemTransactionId = "internalTxnId",
  PspTransactionId = "paymentSystemTxnId",
  KeywordType = "keywordType",
  Keyword = "keyword",
  BlockedOnly = "blockedOnly",
}

