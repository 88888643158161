import {FC} from "react";
import {ActionsButton} from "@/components/ActionsButton";
import {IOperatorActionsItemsData} from "@/features/operators/components/OperatorActionButtons/type";
import {useOperatorActions} from "@/features/operators/components/OperatorActionButtons/useOperatorActions";

export const OperatorActionButtons: FC<IOperatorActionsItemsData> = ({ data, showEditDialog }) => {
  const { operatorActionsItems } = useOperatorActions({ data, showEditDialog });

  return <ActionsButton items={operatorActionsItems} />;
};
