import { api } from "@/store/api";
import {
  IGetMerchantsRequestParams,
  IGetMerchantsResponseBody,
  IMerchantDetails,
  IMerchantDetailsRequest,
  IMerchantSettingsRequest,
} from "@/features/merchants/types";
import { PAGE_SIZE_WITHOUT_PAGINATION } from "@/constants/common";
import {ApiTag} from "@/types/api";

const API_PREFIX = "/merchants";

const merchantsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMerchants: builder.query<
      IGetMerchantsResponseBody,
      IGetMerchantsRequestParams
    >({
      query: (params) => ({
        url: API_PREFIX,
        params: {
          pageSize: PAGE_SIZE_WITHOUT_PAGINATION,
          ...params,
        },
      }),
      providesTags: [ApiTag.Merchant],
    }),
    createMerchant: builder.mutation<IMerchantDetails, IMerchantDetailsRequest>({
      query: (body) => ({
        url: `operators/${API_PREFIX}`,
        method: "POST",
        body,
      }),
      invalidatesTags: [ApiTag.Merchant],
    }),
    getMerchantDetails: builder.query<IMerchantDetails, { id: number }>({
      query: ({ id }) => ({
        url: `${API_PREFIX}/${id}`,
      }),
    }),
    updateMerchantDetails: builder.mutation<IMerchantDetails, IMerchantDetailsRequest>({
      query: (data) => ({
        url: `${API_PREFIX}/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [ApiTag.Merchant],
    }),
    updateMerchantSettings: builder.mutation<IMerchantDetails, IMerchantSettingsRequest>({
      query: (data) => ({
        url: `${API_PREFIX}/${data.id}/settings`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: [ApiTag.Merchant],
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetMerchantsQuery,
  useGetMerchantDetailsQuery,
  useUpdateMerchantDetailsMutation,
  useCreateMerchantMutation,
  useUpdateMerchantSettingsMutation,
} = merchantsApi;
