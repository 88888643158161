import {FC, useCallback} from "react";
import {toast} from "react-toastify";
import {IOperatorDialogProps} from "@/features/operators/types";
import {OperatorForm, OperatorFormValues} from "@/features/operators/components/OperatorForm";
import {useCreateOperatorMutation} from "@/features/operators/operatorsApi";

export const OperatorCreateDialog: FC<IOperatorDialogProps> = ({ open, closeDialog }) => {
  const [createOperator, { isLoading }] = useCreateOperatorMutation();
  const handleSubmit = useCallback(
    async ({
             name,
           }: OperatorFormValues) => {
      await createOperator({
        name
      }).unwrap();

      toast.success(`Operator ${name} has been created.`);
    },
    [createOperator],
  );

  return (
    <OperatorForm
      title={`Add New Operator`}
      submitButtonText="Create"
      isSubmitting={isLoading}
      defaultValues={{}}
      onSubmit={handleSubmit}
      closeDialog={closeDialog}
      open={open}
    />
  );
};
