import {MerchantsSettingsFieldName} from "@/features/merchants/components/MerchantsSettingsForm/type";

export const merchantsSettingsFieldLabelMap: Record<
  MerchantsSettingsFieldName,
  string
  > = {
  [MerchantsSettingsFieldName.CallbackRetryType]: "Callback Retries",
  [MerchantsSettingsFieldName.CallbackRetryTimeInterval]: "Interval for first retry",
  [MerchantsSettingsFieldName.CallbackRetryTimeIntervalParam]: "",
  [MerchantsSettingsFieldName.CallbackRetryTimeStep]: "Time step",
  [MerchantsSettingsFieldName.CallbackRetryTimeStepParam]: "",
  [MerchantsSettingsFieldName.CallbackRetries]: "Number of Retries",
  [MerchantsSettingsFieldName.CallbackRetryTimeStepMultiplier]: "Time step multiplier",
};
