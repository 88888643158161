import {DataGrid, IDataGridColumns} from "@/components/DataGrid";
import {FC} from "react";
import {ErrorTranslationActionButtons} from "@/features/system-errors/components/ErrorTranslationActionButtons";

enum ErrorTranslationTableFields {
  Code = 'code',
  Sp = 'sp',
  En = 'en',
  De = 'de',
}

enum ErrorTranslationTableLabels {
  Code = 'Code',
  Sp = 'Spanish',
  En = 'English',
  De = 'German',
}

const columns: IDataGridColumns<any, ErrorTranslationTableFields> = [
  {
    field: ErrorTranslationTableFields.Code,
    headerName: ErrorTranslationTableLabels.Code,
    headCellSx: {
      minWidth: 240,
    },
  },
  {
    field: ErrorTranslationTableFields.En,
    headerName: ErrorTranslationTableLabels.En,
    headCellSx: {
      minWidth: 200,
    },
  },
  {
    field: ErrorTranslationTableFields.Sp,
    headerName: ErrorTranslationTableLabels.Sp,
    headCellSx: {
      minWidth: 200,
    },
  },
  {
    field: ErrorTranslationTableFields.De,
    headerName: ErrorTranslationTableLabels.De,
    headCellSx: {
      minWidth: 200,
    },
  },

  {
    field: "actions",
    headerName: "Actions",
    headCellSx: {
      width: "1px",
    },
    renderCell: ({ rowData }) => <ErrorTranslationActionButtons translation={rowData} />,
  },
];

interface IErrorTranslationsTableProps {
  className?: string;
  isFetching: boolean;
  items: any;
}

export const ErrorTranslationTable: FC<IErrorTranslationsTableProps> = ({
  className,
  isFetching,
  items,
}) => {
  return (
    <DataGrid
      className={className}
      columns={columns}
      data={items}
      emptyMessage="Whoops! There're no system error at the moment :("
      loading={{
        isLoadingTable: isFetching,
      }}
    />
  );
};
