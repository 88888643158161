const getRandomInt = (max: number): number => {
  return Math.floor(Math.random() * max);
};

const getNumberCharacters = (): string => {
  const numbersCharacters = [2, 3, 4, 5, 6, 7, 8, 9];
  return numbersCharacters[getRandomInt(numbersCharacters.length - 1)].toString();
};

const getRandomCharacters = (): string => {
  const characters = 'abcdefghjkmnpqrstuvwxyz';
  return characters.charAt(getRandomInt(characters.length - 1));
};

const getRandomSymbols = (): string => {
  const symbols = '@#$%';
  return symbols.charAt(getRandomInt(symbols.length - 1));
};

const detectSimilarCharacters = (value: string, func: () => string, count = 0): string => {
  const maxRetry = 1000;
  const funcValue = func();
  const isDuplicate = value.toLowerCase().includes(funcValue.toString().toLowerCase());

  if (isDuplicate && count < maxRetry) {
    count +=1;
    return detectSimilarCharacters(value, func, count);
  }

  return funcValue;
};

export const generateSecret = () => {
  const arr = new Array(16).fill(0, 0, 16);
  return arr.reduce((acc, cur, index) => {
    let value = '';
    switch (index) {
      case 0:
        value = getRandomCharacters().toUpperCase();
        break;
      case 1:
        value = detectSimilarCharacters(acc, getRandomCharacters);
        break;
      case 2:
        value = detectSimilarCharacters(acc, getNumberCharacters);
        break;
      case 3:
        value = detectSimilarCharacters(acc, getRandomSymbols);
        break;
      default:
        const func = [getRandomCharacters, getNumberCharacters, getRandomSymbols];
        const funcIndex = getRandomInt(func.length - 1);
        value = funcIndex === 0 && index % 2 ? detectSimilarCharacters(acc, func[funcIndex]).toUpperCase() : detectSimilarCharacters(acc, func[funcIndex]);
        break;
    }

    return acc + value;
  }, '').split('').sort(() => 0.5 - Math.random()).join('');
};
