import {api} from "@/store/api";
import {IFeeReportDrillDownRequest, IFeeReportDrillDownResponse, IFeeReportResponse} from "@/features/fee-report/types";

const URL_PREFIX = "/fee-report";

const feeReportApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFilters: builder.query<any, any>({
      query: (body) => ({
        url: `${URL_PREFIX}/filters`,
        method: "POST",
        body,
      }),
    }),
    getMain: builder.query<IFeeReportResponse, any>({
      query: (body) => ({
        url: `${URL_PREFIX}/main`,
        method: "POST",
        body,
      }),
    }),
    getDrillDownData: builder.query<IFeeReportDrillDownResponse, IFeeReportDrillDownRequest>({
      query: (body) => ({
        url: `${URL_PREFIX}/drill-down-data`,
        method: "POST",
        body,
      }),
    }),
    exportReport: builder.query<any, any>({
      query: (body) => ({
        url: `${URL_PREFIX}/export`,
        method: "POST",
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyGetFiltersQuery,
  useLazyGetMainQuery,
  useGetDrillDownDataQuery,
  useLazyExportReportQuery,
} = feeReportApi;
