export enum FeeReportFiltersFieldName {
  TransactionType = "transactionType",
  MerchantIds = "merchantIds",
  DateRange = "dateRange",
  FromDate = "fromDate",
  ToDate = "toDate",
  UtcOffset = "utcOffset",
  PpaNames = "ppaNames",
  Providers = "providers",
  PaymentMethods = "paymentMethods",
  Currencies = "currency",
  OperatorsAndMerchants = "operatorsAndMerchants",
  ProcessingCurrency = "useProcessingCurrency"
}
