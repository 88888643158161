import { useMemo } from "react";
import { useAppSelector } from "@/hooks/useAppSelector";
import { IUserPermissions, UserRole } from "@/types/user";
import { IAuthUser } from "@/features/auth/types";

const getPermissionsByUser = (user: IAuthUser | null): IUserPermissions => ({
  transactions: {
    withOperatorsFilter: user?.role === UserRole.Admin,
    withMerchantsFilter: user?.role === UserRole.Operator,
  },
  users: !!user && [UserRole.Admin, UserRole.Operator].includes(user.role),
  feeReport: {
    withAdminFilter: user?.role === UserRole.Admin,
    withOperatorsFilter: user?.role === UserRole.Operator,
    withMerchantsFilter: user?.role === UserRole.Merchant,
  },
  settings: user?.role === UserRole.Admin,
  operators: user?.role === UserRole.Admin,
  merchants: user?.role === UserRole.Operator || user?.role === UserRole.Admin,
});

export const useUser = () => {
  const { user } = useAppSelector((state) => state.auth);

  const permissions = useMemo<IUserPermissions>(
    () => getPermissionsByUser(user),
    [user],
  );

  return { user, permissions };
};
