import {ChangeEvent, FC, MouseEvent, useCallback, useEffect, useState} from "react";
import {handleError} from "@/utils/handleError";
import {Dialog, DialogContent} from "@mui/material";
import {DialogHeader} from "@/components/DialogHeader";
import {IFeeReportDrillDownDialogProps} from "@/features/fee-report/components/FeeReportDrillDownDialog/types";
import {useGetDrillDownDataQuery} from "@/features/fee-report/feeReportApi";
import * as Styled from "./FeeReportDrillDownDialog.styles";
import {FeeReportDrillDownTable} from "@/features/fee-report/components/FeeReportDrillDownTable";
import {FeeReportExportButton} from "@/features/fee-report/components/FeeReportExportButton/FeeReportExportButton";
import {IFeeReportDrillDownRequest} from "@/features/fee-report/types";

const DEFAULT_PAGE_INDEX = 1;
const DEFAULT_PAGE_SIZE = 10;

export const FeeReportDrillDownDialog: FC<IFeeReportDrillDownDialogProps> = (
  props,
) => {
  const [pageIndex, setPageIndex] = useState<number>(DEFAULT_PAGE_INDEX);
  const [pageSize, setPageSize] = useState<number>(DEFAULT_PAGE_SIZE);



  const getFilters = useCallback((): IFeeReportDrillDownRequest => {
    let filters: IFeeReportDrillDownRequest = {
      pageIndex,
      pageSize,
      transactionStatus: props.isConfirmed ? 'confirmed' : 'declined',
    };

    if (!!props.rowData) {
      filters = {
        ...filters,
        ppaNames: props.rowData.ppaName ? [props.rowData.ppaName] : [],
        providers: props.rowData.providerName ? [props.rowData.providerName] : [],
        paymentMethods: props.rowData.paymentMethod ? [props.rowData.paymentMethod] : [],
        currency: props.rowData.currency,
        merchantIds: props.rowData.merchantId ? [props.rowData.merchantId] : [],
      }
    }

    const propsKeys = ['transactionType', 'fromDate', 'toDate', 'utcOffset', 'useProcessingCurrency'];

    propsKeys.forEach(e => {
      /*@ts-ignore*/
      filters[e] = props.filters[e];
    });

    return filters;
  }, [props]);

  const { data, isFetching, error } = useGetDrillDownDataQuery(
    {
      ...getFilters(),
      pageIndex,
      pageSize
    }
  );


  const handlePageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPageIndex: number) => {
      setPageIndex(newPageIndex + 1);
    },
    [props],
  );

  const handlePageSizeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPageIndex(DEFAULT_PAGE_INDEX);
      setPageSize(Number(event?.target?.value));
    },
    [props],
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  return (
    <Dialog open={props.isOpen} maxWidth={false}  onClose={() => (props.handleClose())}>
      <DialogHeader onClose={() => (props.handleClose())}>
        {props?.rowData?.ppaName || ' '}
      </DialogHeader>
      <DialogContent dividers>
        <Styled.Container>
          <FeeReportExportButton filters={getFilters()} fileTitle={props?.rowData?.ppaName || ''}/>
        </Styled.Container>

        <Styled.DataGridToolbar>
          <Styled.Title>
            Transaction fees for {props.isConfirmed ? 'Confirmed' : 'Declined'} deposits
          </Styled.Title>
          <Styled.FeeReportPagination
            count={data?.totalCount || 0}
            page={pageIndex - 1}
            onPageChange={handlePageChange}
            rowsPerPage={pageSize}
            onRowsPerPageChange={handlePageSizeChange}
          />
        </Styled.DataGridToolbar>

        <FeeReportDrillDownTable items={data?.items || []} isFetching={isFetching} />
      </DialogContent>
    </Dialog>
  );
};
