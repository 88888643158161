import { api } from "@/store/api";
import {
  IGetOperatorsRequestParams,
  IGetOperatorsResponseBody,
  IOperatorsCreateRequest, IOperatorsEditRequest
} from "@/features/operators/types";
import { PAGE_SIZE_WITHOUT_PAGINATION } from "@/constants/common";
import {ApiTag} from "@/types/api";
import {IOperatorDetails} from "@/types/operator";

const API_PREFIX = "/operators";

const operatorsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOperators: builder.query<IGetOperatorsResponseBody, IGetOperatorsRequestParams | undefined>({
      query: (params?) => ({
        url: API_PREFIX,
        params: params ? {
          ...params,
          pageSize: params.pageSize || PAGE_SIZE_WITHOUT_PAGINATION,
        } : {
          pageSize: PAGE_SIZE_WITHOUT_PAGINATION,
        },
      }),
      providesTags: [ApiTag.Operator],
    }),
    createOperator: builder.mutation<IOperatorDetails, IOperatorsCreateRequest>({
      query: (body) => ({
        url: `${API_PREFIX}`,
        method: "POST",
        body,
      }),
      invalidatesTags: [ApiTag.Operator],
    }),
    updateOperatorDetails: builder.mutation<IOperatorDetails, IOperatorsEditRequest>({
      query: ({name, id}) => ({
        url: `${API_PREFIX}/${id}`,
        method: "PUT",
        body: {name},
      }),
      invalidatesTags: [ApiTag.Operator],
    }),
  }),
  overrideExisting: false,
});

export const { useGetOperatorsQuery, useCreateOperatorMutation, useUpdateOperatorDetailsMutation } = operatorsApi;
