import { FC } from "react";
import { Button } from "@/components/Button";
import { ROUTES } from "@/routing/constants";
import * as Styled from "./ErrorTranslationsTableToolbar.styles";

export const ErrorTranslationsTableToolbar: FC = () => {
  return (
    <Styled.ActionContainer>
      <Button href={ROUTES.errorTranslationsCreate.createURL()}>
        Create New Error
      </Button>
    </Styled.ActionContainer>
  );
};
