import * as yup from "yup";
import {DefaultValues, useForm} from "react-hook-form";
import {Dialog, DialogActions, DialogContent, DialogProps, DialogTitle} from "@mui/material";
import {FC, useCallback, useEffect, useId} from "react";
import { useNavigate } from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import {handleError} from "@/utils/handleError";
import * as Styled
  from "@/features/error-mapping/components/ErrorMappingForm/ErrorMappingForm.styles";
import {FormSelectField, FormSelectFieldOptions, FormTextField} from "@/components/form";
import {Button} from "@/components/Button";
import {useSystemErrorOptions} from "@/features/error-mapping/components/ErrorMappingForm/useSystemErrorsOptions";

const FIELD_SIZE = "small";

export enum ErrorMappingFieldName {
  PspName = 'pspName',
  PspCode = 'pspCode',
  SystemCode = 'systemCode',
  Message = 'message'
}

const errorMappingFormSchema = yup
  .object({
    [ErrorMappingFieldName.PspName]: yup
      .string()
      .required()
      .trim()
      .label("PSP name"),
    [ErrorMappingFieldName.PspCode]: yup.string().required().trim().label("Psp Code"),
    [ErrorMappingFieldName.SystemCode]: yup.string().required().trim().label("System Error"),
    [ErrorMappingFieldName.Message]: yup.string().trim().label("Message"),
  })
  .required();


export type ErrorMappingFormValues = yup.InferType<typeof errorMappingFormSchema>;
export type ErrorMappingDefaultValues = DefaultValues<ErrorMappingFormValues>;

interface IErrorMappingFormProps extends Omit<DialogProps, "onSubmit"> {
  title: string;
  submitButtonText: string;
  defaultValues: ErrorMappingDefaultValues;
  isSubmitting?: boolean;
  disabledFields?: ErrorMappingFieldName[];
  onSubmit: (values: ErrorMappingFormValues) => void;
  providersOptions: FormSelectFieldOptions;
  providerCodeOptions: FormSelectFieldOptions;
  closeDialog: (param?: any) => void;
  isCreateMode?: boolean;
}

export const ErrorMappingForm: FC<IErrorMappingFormProps> = ({
  title,
  submitButtonText,
  defaultValues,
  isSubmitting,
  disabledFields,
  onSubmit,
  providerCodeOptions,
  providersOptions,
  closeDialog,
  isCreateMode,
  ...restProps
}) => {
  const formId = useId();
  const navigate = useNavigate();

  const { control, handleSubmit, watch, reset, setValue } = useForm<ErrorMappingFormValues>({
    resolver: yupResolver<ErrorMappingFormValues>(errorMappingFormSchema),
    mode: "onBlur",
    defaultValues,
  });

  const {
    isFetchingSystemError,
    systemErrorOptions,
  } = useSystemErrorOptions();

  const handleClose = useCallback(() => {
    reset();
    closeDialog();
  }, [navigate]);

  const handleFormSubmit = useCallback(
    async (values: ErrorMappingFormValues) => {
      try {
        await onSubmit(values);
        handleClose();
      } catch (error) {
        closeDialog();
        handleError(error);
      }
    },
    [onSubmit, handleClose],
  );

  const isFieldDisabled = useCallback(
    (fieldName: ErrorMappingFieldName) => {
      if (isSubmitting) {
        return true;
      }

      return !!disabledFields?.includes(fieldName);
    }, [isSubmitting, disabledFields]);

  const renderSelectField = useCallback(
    (name: ErrorMappingFieldName, options: FormSelectFieldOptions, label: string) => (
      <FormSelectField
        name={name}
        label={label}
        control={control}
        options={options}
        disabled={!options.length || isFieldDisabled(name)}
        readOnly={isFetchingSystemError}
        size={FIELD_SIZE}
        showSearch={true}
      />
    ),
    [control, isFetchingSystemError],
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Dialog fullWidth disableRestoreFocus {...restProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
          <Styled.Fields>
            {renderSelectField(
              ErrorMappingFieldName.PspName,
              providersOptions,
              'PSP Name'
            )}
            {isCreateMode ?
              <>
                <FormTextField
                  name={ErrorMappingFieldName.PspCode}
                  control={control}
                  label="PSP Code"
                  disabled={isFieldDisabled(ErrorMappingFieldName.PspCode)}
                  size={FIELD_SIZE}
                />
                <FormTextField
                  name={ErrorMappingFieldName.Message}
                  control={control}
                  label="Message"
                  disabled={isFieldDisabled(ErrorMappingFieldName.Message)}
                  size={FIELD_SIZE}
                />
              </>: renderSelectField(
                ErrorMappingFieldName.PspCode,
                providerCodeOptions,
                'PSP Code'
              )
            }

            {renderSelectField(
              ErrorMappingFieldName.SystemCode,
              systemErrorOptions,
              'System Error'
            )}
          </Styled.Fields>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" disabled={isSubmitting} onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" form={formId} isLoading={isSubmitting}>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
