import {styled, TablePagination} from "@mui/material";

export const FeeReportPagination = styled(TablePagination)`
  flex-shrink: 0;
  border-bottom: none;
`;

export const Container = styled("div")`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const DataGridToolbar = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled("h3")`
  margin: 0;
`;
