import { FC, useCallback, useEffect } from "react";
import { useGetUsersQuery } from "@/features/users/usersApi";
import {IGetUsersRequestParams, IUserFilterParams} from "@/features/users/types";
import { PageContent } from "@/components/PageContent";
import { UsersDataGridToolbar } from "@/features/users/components/UsersDataGridToolbar";
import { FeatureTitle } from "@/types/featureTitle";
import { useSearchParams } from "@/hooks/useSearchParams";
import { useDebouncedValue } from "@/hooks/useDebouncedValue";
import { handleError } from "@/utils/handleError";
import { Outlet } from "react-router-dom";
import { PAGE_SIZE_WITHOUT_PAGINATION } from "@/constants/common";
import * as Styled from "./UsersPage.styles";

export const UsersPage: FC = () => {
  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<IGetUsersRequestParams>();
  const debouncedParsedSearchParams = useDebouncedValue(parsedSearchParams);

  const { data, isFetching, error } = useGetUsersQuery({
    ...debouncedParsedSearchParams,
    pageSize: PAGE_SIZE_WITHOUT_PAGINATION,
  });

  const { items = [] } = data || {};

  const handleFiltersChange = useCallback(
    ({search, operatorIds, merchantIds}: IUserFilterParams) => {
      mergeSearchParams({
        filters: {
          search: search || undefined,
          operatorIds: operatorIds || undefined,
          merchantIds: merchantIds || undefined
        }
      });
    },
    [mergeSearchParams],
  );

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  return (
    <PageContent title={FeatureTitle.UserManagement}>
      <UsersDataGridToolbar
        defaultFiltersValues={parsedSearchParams?.filters || {}}
        onFilterChange={handleFiltersChange}
      />
      <Styled.UsersDataGrid isFetching={isFetching} items={items} />
      <Outlet />
    </PageContent>
  );
};
