import {FC} from "react";
import {ActionsButton} from "@/components/ActionsButton";
import {IMerchantsActionsItemsData} from "@/features/merchants/components/MerchantsActionButtons/type";
import {useMerchantsActions} from "@/features/merchants/components/MerchantsActionButtons/useMerchantsActions";

export const MerchantsActionButtons: FC<IMerchantsActionsItemsData> = ({ data, showEditDialog, showSettingsDialog }) => {
  const { merchantsActionsItems } = useMerchantsActions({ data, showEditDialog, showSettingsDialog });

  return <ActionsButton items={merchantsActionsItems} />;
};
