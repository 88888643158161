import * as yup from "yup";
import {MerchantsSettingsFieldName} from "@/features/merchants/components/MerchantsSettingsForm/type";
import {IMerchantSettingsRequest, MerchantSettingsRetryTypes} from "@/features/merchants/types";
import {DefaultValues, useForm} from "react-hook-form";
import {Dialog, DialogActions, DialogContent, DialogProps, DialogTitle} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {FC, useCallback, useEffect, useId} from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import {handleError} from "@/utils/handleError";
import * as Styled from "@/features/merchants/components/MerchantsSettingsForm/MerchantsSettingsForm.styles";
import {FormNumberField, FormSelectField, FormSelectFieldOptions} from "@/components/form";
import {Button} from "@/components/Button";
import {useMerchantsSettingsForm} from "@/features/merchants/components/MerchantsSettingsForm/useMerchantSettingsForm";
import {merchantsSettingsFieldLabelMap} from "@/features/merchants/components/MerchantsSettingsForm/constants";

const merchantsSettingsFormSchema = yup
  .object({
    [MerchantsSettingsFieldName.CallbackRetryType]: yup
      .string()
      .required()
      .label("Callback Retries"),
    [MerchantsSettingsFieldName.CallbackRetryTimeInterval]: yup
      .number()
      .when(MerchantsSettingsFieldName.CallbackRetryType, ([callbackRetryType], schema) => {
        if (callbackRetryType !== MerchantSettingsRetryTypes.Disabled) {
          return schema;
        }

        return schema.required();
      })
      .label("Interval for first retry"),
    [MerchantsSettingsFieldName.CallbackRetryTimeIntervalParam]: yup
      .number()
      .when(MerchantsSettingsFieldName.CallbackRetryType, ([callbackRetryType], schema) => {
        if (callbackRetryType !== MerchantSettingsRetryTypes.Disabled) {
          return schema;
        }

        return schema.required();
      }),
    [MerchantsSettingsFieldName.CallbackRetryTimeStep]: yup
      .number()
      .when(MerchantsSettingsFieldName.CallbackRetryType, ([callbackRetryType], schema) => {
        if (callbackRetryType !== MerchantSettingsRetryTypes.Incremental) {
          return schema;
        }

        return schema.required();
      })
      .label("Time step"),
    [MerchantsSettingsFieldName.CallbackRetryTimeStepParam]: yup
      .number()
      .when(MerchantsSettingsFieldName.CallbackRetryType, ([callbackRetryType], schema) => {
        if (callbackRetryType !== MerchantSettingsRetryTypes.Incremental) {
          return schema;
        }

        return schema.required();
      }),
    [MerchantsSettingsFieldName.CallbackRetries]: yup
      .number()
      .max(10)
      .when(MerchantsSettingsFieldName.CallbackRetryType, ([callbackRetryType], schema) => {
        if (callbackRetryType !== MerchantSettingsRetryTypes.Disabled) {
          return schema;
        }

        return schema.required();
      })
      .label("Number of Retries"),
    [MerchantsSettingsFieldName.CallbackRetryTimeStepMultiplier]: yup
      .number()
      .when(MerchantsSettingsFieldName.CallbackRetryType, ([callbackRetryType], schema) => {
        if (callbackRetryType !== MerchantSettingsRetryTypes.Exponential) {
          return schema;
        }

        return schema.required();
      })
      .label("Time step multiplier"),
  })
  .required();

export type MerchantSettingsFormValues = yup.InferType<typeof merchantsSettingsFormSchema>;
export type MerchantSettingsDefaultValues = DefaultValues<MerchantSettingsFormValues>;

interface IMerchantsSettingsFormProps extends Omit<DialogProps, "onSubmit"> {
  title: string;
  submitButtonText: string;
  defaultValues: MerchantSettingsDefaultValues;
  isSubmitting?: boolean;
  isFetchingData?: boolean;
  onSubmit: (values: IMerchantSettingsRequest) => void;
  closeDialog: (param: boolean) => void;
}

const FIELD_SIZE = "small";

const calcSeconds = (value: number | undefined, multiplier: number | undefined): number | undefined => {
  return value && multiplier ? value * multiplier : undefined;
};

export const MerchantsSettingsForm: FC<IMerchantsSettingsFormProps> = ({
  title,
  submitButtonText,
  defaultValues,
  isSubmitting,
  isFetchingData,
  onSubmit,
  closeDialog,
  ...restProps
}) => {
  const formId = useId();
  const navigate = useNavigate();

  const { control, handleSubmit, watch, reset, setValue, getValues, formState: { isDirty}, } =
    useForm<MerchantSettingsFormValues>({
    resolver: yupResolver<MerchantSettingsFormValues>(merchantsSettingsFormSchema),
    mode: "onBlur",
    defaultValues
  });

  const {retryTypeOptions, timeSettingsOptions} = useMerchantsSettingsForm();

  const handleClose = useCallback(() => {
    reset();
    closeDialog(false);
  }, [navigate]);

  const transformValueForBackEnd = useCallback((values: MerchantSettingsFormValues): IMerchantSettingsRequest | null => {
    switch(values[MerchantsSettingsFieldName.CallbackRetryType]) {
      case MerchantSettingsRetryTypes.Incremental:
        return {
          [MerchantsSettingsFieldName.CallbackRetryType]: values[MerchantsSettingsFieldName.CallbackRetryType] as MerchantSettingsRetryTypes,
          [MerchantsSettingsFieldName.CallbackRetryTimeInterval]: calcSeconds(values[MerchantsSettingsFieldName.CallbackRetryTimeInterval], values[MerchantsSettingsFieldName.CallbackRetryTimeIntervalParam]),
          [MerchantsSettingsFieldName.CallbackRetryTimeStep]: calcSeconds(values[MerchantsSettingsFieldName.CallbackRetryTimeStep], values[MerchantsSettingsFieldName.CallbackRetryTimeStepParam]),
          [MerchantsSettingsFieldName.CallbackRetries]: values[MerchantsSettingsFieldName.CallbackRetries],
        };
      case MerchantSettingsRetryTypes.Exponential:
        return {
          [MerchantsSettingsFieldName.CallbackRetryType]: values[MerchantsSettingsFieldName.CallbackRetryType] as MerchantSettingsRetryTypes,
          [MerchantsSettingsFieldName.CallbackRetries]: values[MerchantsSettingsFieldName.CallbackRetries],
          [MerchantsSettingsFieldName.CallbackRetryTimeStepMultiplier]: values[MerchantsSettingsFieldName.CallbackRetryTimeStepMultiplier],
          [MerchantsSettingsFieldName.CallbackRetryTimeInterval]: calcSeconds(values[MerchantsSettingsFieldName.CallbackRetryTimeInterval], values[MerchantsSettingsFieldName.CallbackRetryTimeIntervalParam]),
        };
      default:
        return {
          [MerchantsSettingsFieldName.CallbackRetryType]: values[MerchantsSettingsFieldName.CallbackRetryType] as MerchantSettingsRetryTypes
        }
    }
  }, []);

  const handleFormSubmit = useCallback(
    async (values: MerchantSettingsFormValues) => {
      try {
        const transformedValues = transformValueForBackEnd(values);
        if (!transformedValues) {
          return;
        }
        await onSubmit(transformedValues);
        handleClose();
      } catch (error) {
        closeDialog(false);
        handleError(error);
      }
    },
    [onSubmit, handleClose],
  );

  const renderSelectField = useCallback(
    (name: MerchantsSettingsFieldName, options: FormSelectFieldOptions<number | string>) => (
      <FormSelectField
        name={name}
        label={merchantsSettingsFieldLabelMap[name]}
        control={control}
        options={options}
        disabled={!options.length}
        readOnly={isFetchingData}
        size={FIELD_SIZE}
      />
    ),
    [control, isFetchingData],
  );

  const renderNumberField = useCallback(
    (name: MerchantsSettingsFieldName) => (
      <FormNumberField
        name={name}
        label={merchantsSettingsFieldLabelMap[name]}
        control={control}
        size={FIELD_SIZE}
        InputProps={{
          readOnly: isFetchingData
        }}
      />
    ),
    [control, isFetchingData],
  );

  const retiesType = watch(MerchantsSettingsFieldName.CallbackRetryType);

  useEffect(() => {
    reset({...defaultValues});
  }, [defaultValues, reset]);

  return (
    <Dialog fullWidth disableRestoreFocus {...restProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
          <Styled.Fields>
            {renderSelectField(
              MerchantsSettingsFieldName.CallbackRetryType,
              retryTypeOptions
            )}
            {retiesType !== MerchantSettingsRetryTypes.Disabled &&
              <>
                <Styled.FieldsRowWrapper>
                  {renderNumberField(MerchantsSettingsFieldName.CallbackRetryTimeInterval)}
                  {renderSelectField(
                    MerchantsSettingsFieldName.CallbackRetryTimeIntervalParam,
                    timeSettingsOptions
                  )}
                </Styled.FieldsRowWrapper>
                <Styled.FieldsRowWrapper>
                  {
                    retiesType === MerchantSettingsRetryTypes.Incremental &&
                      <>
                        {renderNumberField(MerchantsSettingsFieldName.CallbackRetryTimeStep)}
                        {renderSelectField(
                          MerchantsSettingsFieldName.CallbackRetryTimeStepParam,
                          timeSettingsOptions
                        )}
                      </> }
                  {
                    retiesType === MerchantSettingsRetryTypes.Exponential && renderNumberField(MerchantsSettingsFieldName.CallbackRetryTimeStepMultiplier)
                  }
                </Styled.FieldsRowWrapper>
                <Styled.FieldsRowWrapper>
                  {renderNumberField(MerchantsSettingsFieldName.CallbackRetries)}
                </Styled.FieldsRowWrapper>
              </>
            }
          </Styled.Fields>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" disabled={isSubmitting} onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" form={formId} isLoading={isSubmitting} disabled={isFetchingData || !isDirty}>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
