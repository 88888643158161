import {
  IconButton as MuiIconButton,
  DialogTitle as MuiDialogTitle,
  styled,
} from "@mui/material";

export const DialogTitle = styled(MuiDialogTitle)`
  position: relative;
  padding-right: 3.5rem;
  min-height: 4rem;
`;

export const IconButton = styled(MuiIconButton)(({ theme }) => ({
  position: "absolute",
  right: "0.5rem",
  top: "0.5rem",
  color: theme.palette.grey[500],
}));
