import {api} from "@/store/api";
import {ICountryItem} from "@/features/countries/type";

const API_PREFIX = "/app/countries";

const countriesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCountries: builder.query<Array<ICountryItem>, void>({
      query: () => ({
        url: `${API_PREFIX}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export const { useGetCountriesQuery } = countriesApi;
