import { useCallback, useState } from "react";
import {Control, FieldValues, Path} from "react-hook-form";
import { VisibilitySharp, VisibilityOffSharp } from "@mui/icons-material";
import { FormTextField, FormTextFieldProps } from "../FormTextField";
import * as Styled from "./FormPasswordField.styles";
import {TextFieldProps} from "@mui/material";
import {MaskedInputProps} from "react-text-mask";

export type FormTextPasswordFieldProps<TFormValues extends FieldValues> = Omit<
  TextFieldProps,
  "defaultValue" | "value"
  > & {
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  maskedInputProps?: MaskedInputProps;
  additionalEndAdornment?: any;
};

export const FormPasswordField = <TFormValues extends FieldValues>({
  name,
  control,
  label = "Password",
  InputProps,
  additionalEndAdornment,
  ...restProps
}: FormTextPasswordFieldProps<TFormValues>) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const handleSwitchPasswordVisibility = useCallback(() => {
    setIsPasswordVisible((prevValue) => !prevValue);
  }, []);

  return (
    <FormTextField
      name={name}
      control={control}
      label={label}
      type={isPasswordVisible ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <>
            <Styled.PasswordVisibilityButton
              onClick={handleSwitchPasswordVisibility}
            >
              {isPasswordVisible ? <VisibilitySharp /> : <VisibilityOffSharp />}
            </Styled.PasswordVisibilityButton>
            {additionalEndAdornment}
          </>
        ),
        ...InputProps,
      }}
      {...restProps}
    />
  );
};
