import { FC } from "react";
import {DataGrid, IDataGridColumns} from "@/components/DataGrid";
import {EMPTY_VALUE_FALLBACK} from "@/constants/common";
import {IFeeReportDrillDownResponseItem} from "@/features/fee-report/types";

enum FeeReportDrillDownFields {
  TransactionId = 'slavnaTxnId',
  TransactionAmount = 'transactionAmount',
  TotalFee = 'totalFee',
  PSPFee = 'pspFee',
  PSPFeePercent = 'pspFeePercent',
  SlavnaPayFee = 'slavnaFee',
  SlavnaPayFeePercent = 'slavnaFeePercent',
  PSPFixedFee = 'pspFixedFee',
  Currency = 'currency',
}

enum FeeReportDrillDownLabels {
  TransactionId = 'SlavnaPay Transaction Id',
  TransactionAmount = 'Transaction Amount',
  TotalFee = 'Total fee',
  PSPFee = 'PSP fee',
  PSPFeePercent = 'PSP fee, %',
  SlavnaPayFee = 'SlavnaPay fee',
  SlavnaPayFeePercent = 'SlavnaPay fee, %',
  PSPFixedFee = 'PSP Fixed fee'
}

const idKey = 'slavnaTxnId';
const minWidth = 150;

interface IFeeReportDrillDownTableProps {
  isFetching: boolean;
  items: Array<IFeeReportDrillDownResponseItem>;
  className?: string;
}

export const FeeReportDrillDownTable: FC<IFeeReportDrillDownTableProps> = ({isFetching, items, className}) => {
  const columns: IDataGridColumns<IFeeReportDrillDownResponseItem, FeeReportDrillDownFields> = [
    {
      field: FeeReportDrillDownFields.TransactionId,
      headerName: FeeReportDrillDownLabels.TransactionId,
      headCellSx: {
        minWidth: 240,
      },
    },
    {
      field: FeeReportDrillDownFields.TransactionAmount,
      headerName: FeeReportDrillDownLabels.TransactionAmount,
      headCellSx: {
        minWidth,
      },
      renderCell: ({rowData}) => {
        return `${rowData[FeeReportDrillDownFields.TransactionAmount]}  ${rowData[FeeReportDrillDownFields.Currency]}` ||  EMPTY_VALUE_FALLBACK;
      },
    },
    {
      field: FeeReportDrillDownFields.TotalFee,
      headerName: FeeReportDrillDownLabels.TotalFee,
      headCellSx: {
        minWidth,
      },
      renderCell: ({rowData}) => {
        return `${rowData[FeeReportDrillDownFields.TotalFee]}  ${rowData[FeeReportDrillDownFields.Currency]}` ||  EMPTY_VALUE_FALLBACK;
      },
    },
    {
      field: FeeReportDrillDownFields.PSPFee,
      headerName: FeeReportDrillDownLabels.PSPFee,
      headCellSx: {
        minWidth,
      },
      renderCell: ({rowData}) => {
        return `${rowData[FeeReportDrillDownFields.PSPFee]}  ${rowData[FeeReportDrillDownFields.Currency]}` ||  EMPTY_VALUE_FALLBACK;
      },
    },
    {
      field: FeeReportDrillDownFields.PSPFeePercent,
      headerName: FeeReportDrillDownLabels.PSPFeePercent,
      headCellSx: {
        minWidth,
      },
      renderCell: ({rowData}) => {
        return `${rowData[FeeReportDrillDownFields.TotalFee]}%`;
      },
    },
    {
      field: FeeReportDrillDownFields.SlavnaPayFee,
      headerName: FeeReportDrillDownLabels.SlavnaPayFee,
      headCellSx: {
        minWidth,
      },
      renderCell: ({rowData}) => {
        return `${rowData[FeeReportDrillDownFields.SlavnaPayFee]}  ${rowData[FeeReportDrillDownFields.Currency]}` ||  EMPTY_VALUE_FALLBACK;
      },
    },
    {
      field: FeeReportDrillDownFields.SlavnaPayFeePercent,
      headerName: FeeReportDrillDownLabels.SlavnaPayFeePercent,
      headCellSx: {
        minWidth,
      },
      renderCell: ({rowData}) => {
        return `${rowData[FeeReportDrillDownFields.SlavnaPayFeePercent]}%`;
      },
    },
    {
      field: FeeReportDrillDownFields.PSPFixedFee,
      headerName: FeeReportDrillDownLabels.PSPFixedFee,
      headCellSx: {
        minWidth,
      },
      renderCell: ({rowData}) => {
        return `${rowData[FeeReportDrillDownFields.PSPFixedFee]}  ${rowData[FeeReportDrillDownFields.Currency]}` ||  EMPTY_VALUE_FALLBACK;
      },
    },
  ];

  return (
    <>
      <DataGrid
        className={className}
        columns={columns}
        data={items}
        emptyMessage="Whoops! There're no transactions fee at the moment :("
        loading={{
          isLoadingTable: isFetching,
        }}
        idKey={idKey}
      />
    </>
  );
};
