import * as yup from "yup";
import {DefaultValues, useForm} from "react-hook-form";
import {Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Tooltip} from "@mui/material";
import {FC, useCallback, useEffect, useId} from "react";
import {useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import {handleError} from "@/utils/handleError";
import * as Styled
  from "@/features/merchants/components/MerchantsForm/MerchantsForm.styles";
import {
  FormCheckboxField,
  FormCheckboxTreeSelectField,
  FormPasswordField,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField
} from "@/components/form";
import {Button} from "@/components/Button";
import {MerchantsFieldName} from "@/features/merchants/components/MerchantsForm/types";
import {merchantsFieldLabelMap} from "@/features/merchants/components/MerchantsForm/constants";
import {CheckboxTreeNodes} from "@/components/CheckboxTree";
import {useMerchantsForm} from "@/features/merchants/components/MerchantsForm/useMerchantsForm";
import {generateSecret} from "@/features/merchants/components/MerchantsForm/utils/generateSecret";
import {Refresh as RefreshIcon} from "@mui/icons-material";
import {useConfirmDialog} from "@/hooks/useConfirmDialog";

const FIELD_SIZE = "small";

const merchantsFormSchema = yup
  .object({
    [MerchantsFieldName.Name]: yup
      .string()
      .required()
      .min(2)
      .max(128)
      .trim()
      .label("Merchant name"),
    [MerchantsFieldName.SecretKey]: yup
      .string()
      .required()
      .min(2)
      .max(128)
      .trim()
      .label("Secret Key"),
    [MerchantsFieldName.AuthCodeDeposit]: yup
      .boolean()
      .label("Auth Code Deposit"),
    [MerchantsFieldName.AuthCodeWithdrawal]: yup
      .boolean()
      .label("Auth Code Withdrawal"),
    [MerchantsFieldName.UseMerchantTxnIDtoPSP]: yup
      .boolean()
      .label("Use Merchant TxnID to PSP"),
    [MerchantsFieldName.AutoApprove]: yup
      .boolean()
      .label("Auto Approve"),
    [MerchantsFieldName.CountryIds]: yup
      .array()
      .of(yup.string().required())
      .label("Countries"),
    [MerchantsFieldName.CurrencyIds]: yup
      .array()
      .of(yup.string().required())
      .label("Currencies"),
    [MerchantsFieldName.OperatorId]: yup.number()
      .when(MerchantsFieldName.AdminCreateType, ([isCreateType], schema) => {
        if (!isCreateType) {
          return schema;
        }

        return schema.required();
      })
      .label("Operator"),
    [MerchantsFieldName.AdminCreateType]: yup.boolean()
  })
  .required();


export type MerchantFormValues = yup.InferType<typeof merchantsFormSchema>;
export type MerchantDefaultValues = DefaultValues<MerchantFormValues>;

interface IMerchantsFormProps extends Omit<DialogProps, "onSubmit"> {
  title: string;
  submitButtonText: string;
  defaultValues: MerchantDefaultValues;
  isSubmitting?: boolean;
  isFetchingData?: boolean;
  onSubmit: (values: MerchantFormValues) => void;
  closeDialog: (param: boolean) => void;
  operatorsOptions?: FormSelectFieldOptions<number>;
  isAdminCreate?: boolean;
}

export const MerchantsForm: FC<IMerchantsFormProps> = ({
  title,
  submitButtonText,
  defaultValues,
  isSubmitting,
  isFetchingData,
  onSubmit,
  operatorsOptions = [],
  isAdminCreate = false,
  closeDialog,
  ...restProps
}) => {
  const formId = useId();
  const navigate = useNavigate();

  const { control, handleSubmit, watch, reset, setValue, getValues, formState: { isDirty}, } = useForm<MerchantFormValues>({
    resolver: yupResolver<MerchantFormValues>(merchantsFormSchema),
    mode: "onBlur",
    defaultValues
  });

  const generateSK = useCallback(() => {
    const sk = generateSecret();
    setValue(MerchantsFieldName.SecretKey, sk,  { shouldDirty: true });
  }, []);

  const {
    isFetching,
    currenciesOptions,
    countriesOptions,
  } = useMerchantsForm();

  const handleClose = useCallback(() => {
    reset();
    closeDialog(false);
  }, [navigate]);

  const handleFormSubmit = useCallback(
    async (values: MerchantFormValues) => {
      try {
        await onSubmit(values);
        handleClose();
      } catch (error) {
        closeDialog(false);
        handleError(error);
      }
    },
    [onSubmit, handleClose],
  );

  const { showConfirmDialog } = useConfirmDialog();

  const handleShowConfirmDialog = useCallback(
    () => {
      showConfirmDialog({
        message: "Are you sure?",
        approveBtnText: 'Yes',
        rejectBtnText: 'No',
        onConfirm: generateSK,
      });
    },
    [showConfirmDialog],
  );

  const renderCheckboxField = useCallback(
    (name: MerchantsFieldName, tooltipText: string) => (
      <>
      <FormCheckboxField
        name={name}
        control={control}
        label={merchantsFieldLabelMap[name]}
        readOnly={isFetching}
        size="small"
      >
        <Tooltip
          title={tooltipText}
          placement="right"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -8],
                  },
                },
              ],
            },
          }}
        >
          <Styled.TooltipWrapper />
        </Tooltip>
      </FormCheckboxField>
      </>
    ),
    [control, isFetching],
  );

  const getDefaultCurrencyValue = useCallback(() => {
    const defaultCurrency = ['USD', 'EUR'];
    return  defaultCurrency.filter(cur => currenciesOptions.find(item => item.value === cur));
  }, [currenciesOptions]);

  const renderCheckboxTreeSelectField = useCallback(
    (name: MerchantsFieldName, nodes: CheckboxTreeNodes) => (
      <FormCheckboxTreeSelectField
        name={name}
        label={merchantsFieldLabelMap[name]}
        control={control}
        nodes={nodes}
        disabled={!nodes.length}
        readOnly={isFetching}
        size={FIELD_SIZE}
      />
    ),
    [control, isFetching],
  );

  const renderSelectField = useCallback(
    (name: MerchantsFieldName, options: FormSelectFieldOptions<number>, label: string) => (
      <FormSelectField
        name={name}
        label={label}
        control={control}
        options={options}
        disabled={!options.length}
        readOnly={isFetching}
        size={FIELD_SIZE}
      />
    ),
    [control, isFetching],
  );

  useEffect(() => {
    reset({
      [MerchantsFieldName.SecretKey]: generateSecret(),
      [MerchantsFieldName.AuthCodeDeposit]: false,
      [MerchantsFieldName.AuthCodeWithdrawal]: false,
      [MerchantsFieldName.UseMerchantTxnIDtoPSP]: false,
      [MerchantsFieldName.AutoApprove]: false,
      [MerchantsFieldName.AdminCreateType]: isAdminCreate,
      [MerchantsFieldName.CurrencyIds]: getDefaultCurrencyValue(),
      ...defaultValues});
  }, [defaultValues, reset]);

  useEffect(() => {
    const currency = getValues(MerchantsFieldName.CurrencyIds) || [];
    if (!currenciesOptions?.length || currency.length) {
      return;
    }
    const defaultCurrencyValue = getDefaultCurrencyValue();
    setValue(MerchantsFieldName.CurrencyIds, defaultCurrencyValue);
  }, [currenciesOptions]);

  return (
    <Dialog fullWidth disableRestoreFocus {...restProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
          <Styled.Fields>
            <FormTextField
              name={MerchantsFieldName.Name}
              control={control}
              label={merchantsFieldLabelMap[MerchantsFieldName.Name]}
              size={FIELD_SIZE}
            />
            {renderCheckboxTreeSelectField(
              MerchantsFieldName.CountryIds,
              countriesOptions,
            )}
            <FormPasswordField
              name={MerchantsFieldName.SecretKey}
              control={control}
              label={merchantsFieldLabelMap[MerchantsFieldName.SecretKey]}
              size={FIELD_SIZE}
              disabled={true}
              additionalEndAdornment={
                <Styled.RefreshSkBtn onClick={handleShowConfirmDialog}>
                  <RefreshIcon />
                </Styled.RefreshSkBtn>
              }
            />
            {renderCheckboxTreeSelectField(
              MerchantsFieldName.CurrencyIds,
              currenciesOptions,
            )}
            {isAdminCreate && renderSelectField(
              MerchantsFieldName.OperatorId,
              operatorsOptions,
              'Operator'
            )}
          </Styled.Fields>
          <Styled.FieldsOneColumn>
            {renderCheckboxField(
              MerchantsFieldName.AuthCodeDeposit,
              'End user 2FA code will be provided in SlavnaPay response to Create API request for deposit flow.')}
            {renderCheckboxField(
              MerchantsFieldName.AuthCodeWithdrawal,
              'End user 2FA code will be provided in SlavnaPay response to Create API request for withdrawal flow.')}
            {renderCheckboxField(MerchantsFieldName.UseMerchantTxnIDtoPSP,
              'Use the Merchant Transaction ID instead of SlavnaPay Transaction ID is all API communication with Payment Service Providers.')}
            {renderCheckboxField(MerchantsFieldName.AutoApprove,
              'Skip “approve” API request and send the withdrawal for processing right after the Create request.')}
          </Styled.FieldsOneColumn>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" disabled={isSubmitting} onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" form={formId} isLoading={isSubmitting} disabled={isFetchingData || !isDirty}>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
