import {FC, useCallback, useEffect, useState} from "react";
import {PageContent} from "@/components/PageContent";
import {FeatureTitle} from "@/types/featureTitle";
import {FeeReportFilters} from "@/features/fee-report/components/FeeReportFilters/FeeReportFilters";
import {FeeReportTreeTable} from "@/features/fee-report/components/FeeReportTreeTable/FeeReportTreeTable";
import {handleError} from "@/utils/handleError";
import { useLazyGetMainQuery } from "@/features/fee-report/feeReportApi";
import {FeeReportDrillDownDialog} from "@/features/fee-report/components/FeeReportDrillDownDialog";
import {useUser} from "@/hooks/useUser";
import {
  IFeeReportResponse,
  IFeeReportResponseOperatorItem,
  IFeeReportTransformedResponse,
  IFeeReportTransformedResponseItem
} from "@/features/fee-report/types";
import {IFeeReportDrillDownDialogRowData} from "@/features/fee-report/components/FeeReportDrillDownDialog/types";

const emptyMessage = 'Empty data';

enum FeeReportTransformKeysEnum {
  OperatorId = 'operatorId',
  MerchantId = 'merchantId',
  PpaName = 'ppaName',
}

interface ISelectedRowData {
  isConfirmed: boolean;
  rowData: IFeeReportDrillDownDialogRowData
}

export const FeeReportPage: FC = () => {
  const [selectingRowData, setSelectingRowData] = useState<ISelectedRowData | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [defaultFilters, setDefaultFilters] = useState({});
  const [appliedFilters, setAppliedFilters] = useState(null);
  const [transformedData, setTransformedData] = useState<IFeeReportTransformedResponse | null>(null);
  const { permissions } = useUser();

  const {
    feeReport: { withAdminFilter },
  } = permissions;

  const changeDialogState = (rowData?: IFeeReportDrillDownDialogRowData, isConfirmed = true) => {
    if (rowData) {
      setSelectingRowData({
        rowData: {...rowData},
        isConfirmed
      });
    }

    setOpenDialog(!openDialog);
  };

  const [
    getFeeReportData,
    {
      data: feeReportData,
      isFetching: isFetchingFeeReport,
      error: feeReportError,
    },
  ] = useLazyGetMainQuery();

  const handleFiltersChange = useCallback(
    (filters: any) => {
      setAppliedFilters(filters);
      getFeeReportData(filters);
    }, []);

  const handleClearAllFilters = useCallback((filters: any) => {
    handleFiltersChange(filters);
  }, [handleFiltersChange]);


  useEffect(() => {
    if (feeReportError) {
      handleError(feeReportError);
    }
  }, [feeReportError]);

  const transformFeeReportResponse = (res: IFeeReportResponse | undefined): IFeeReportTransformedResponse => {
    if (!res) {
      return {
        deposits: [],
        withdrawals: []
      }
    }

    return {
      deposits: res.deposits.reduce((acc: Array<IFeeReportTransformedResponseItem>, curr: IFeeReportResponseOperatorItem, index: number) => {
        transformReport(curr, acc, index);
        return acc;
      }, []),
      withdrawals: res.withdrawals.reduce((acc: Array<IFeeReportTransformedResponseItem>, curr: IFeeReportResponseOperatorItem, index: number) => {
        transformReport(curr, acc, index);
        return acc;
      }, []),
    }
  };

  const transformReport = (row: any, rows: Array<any>, index: number) => {
    const [key, arrVal]: any = Object.entries(row).find(([k, v]) => Array.isArray(v)) || ['last', []];

    let elem;
    switch(true) {
      case !!row[FeeReportTransformKeysEnum.OperatorId]:
        elem = {...row, [key]: undefined};
        rows.push(elem);
        break;
      case !!row[FeeReportTransformKeysEnum.MerchantId]:
        elem = {
          ...rows[rows.length - 1],
          ...row,
          [key]: undefined,
          collapsedRows: [],
        };
        index === 0 ? (rows[rows.length - 1] = elem) : rows.push(elem);
        break;
      case !!row[FeeReportTransformKeysEnum.PpaName]:
        rows.push({...row, [key]: undefined});
        break;
      default:
        elem = {
          ...rows[rows.length - 1],
          ...row,
          [key]: undefined
        };
        index === 0 ? (rows[rows.length - 1] = elem) : rows.push(elem);
        break;
    }

    if (arrVal?.length) {
      arrVal.map((e: any, i: number) => transformReport(e, (!row[FeeReportTransformKeysEnum.MerchantId] ? rows : rows[rows.length - 1].collapsedRows), i));
    }
  };

  useEffect(() => {
    setTransformedData(transformFeeReportResponse(feeReportData));
  }, [feeReportData]);

  return (
    <PageContent title={FeatureTitle.FeeReport}>
      <FeeReportFilters isDataExist={!!transformedData} onClearAllClick={handleClearAllFilters} onApply={handleFiltersChange} />
      <>
        <FeeReportTreeTable
          title="Deposits"
          isOnlyMerchant={!withAdminFilter}
          isFetching={isFetchingFeeReport}
          items={transformedData?.deposits || []}
          openDrillDownAction = {changeDialogState}
        />
        <FeeReportTreeTable
          title="Withdrawals"
          isOnlyMerchant={!withAdminFilter}
          isFetching={isFetchingFeeReport}
          items={transformedData?.withdrawals || []}
          openDrillDownAction = {changeDialogState}
        />
      </>
      {openDialog && <FeeReportDrillDownDialog
        filters={appliedFilters}
        handleClose={changeDialogState}
        isOpen={openDialog}
        isConfirmed={!!selectingRowData?.isConfirmed}
        rowData={selectingRowData?.rowData}
      />}
    </PageContent>
  );
};
