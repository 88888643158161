import {useMemo} from "react";
import {convertArrayToSelectOptions} from "@/utils/convertToOptions";
import {MerchantSettingsRetryTypes, MerchantSettingsTimes} from "@/features/merchants/types";
import {FormSelectFieldOptions} from "@/components/form";

export const useMerchantsSettingsForm = () => {
  const {retryTypeOptions, timeSettingsOptions} = useMemo( () => (
    {
      retryTypeOptions: convertArrayToSelectOptions(Object.values(MerchantSettingsRetryTypes)) || [],
      timeSettingsOptions: Object.entries(MerchantSettingsTimes).filter(([key, value]) => typeof value === "number").map(([key, value]) => {
        return {
          label: key.toUpperCase(),
          value
        }
      }) as FormSelectFieldOptions<number> || [],
    }
  ), []);

  return {retryTypeOptions, timeSettingsOptions};
};
