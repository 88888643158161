import {styled} from "@mui/material";
import {SearchTextField as SearchTextFieldBase} from "@/components/SearchTextField";

export const Root = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const SearchTextField = styled(SearchTextFieldBase)`
  width: 18.75rem;
`;

export const FilterWrapper = styled("div")`
  display: flex;
  align-items: center;
`;
