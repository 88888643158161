import {IActionsButtonItem} from "@/components/ActionsButton";
import {IMerchant} from "@/types/merchant";

export enum MerchantsActionsTitle {
  EditMerchant = "Edit Merchant",
  ViewMerchant = "Merchant Settings",
  ViewUsers = "View Users",
}

export interface IMerchantsActionsItemsData {
  data: IMerchant,
  showEditDialog: (rowData: IMerchant) => void;
  showSettingsDialog: (rowData: IMerchant) => void;
}

export interface IMerchantsActionsItem extends IActionsButtonItem<MerchantsActionsTitle> {}
