import {FC, useCallback, useEffect, useMemo} from "react";
import { useParams } from "react-router-dom";
import {useGetErrorTranslationQuery, useUpdateErrorTranslationMutation} from "@/features/system-errors/systemErrorsApi";
import {toast} from "react-toastify";
import {handleError} from "@/utils/handleError";
import {
  ErrorTranslationDefaultValues, ErrorTranslationDialog,
  ErrorTranslationFormValues
} from "@/features/system-errors/components/ErrorTranslationFormDialog";
import {LoadingOverlay} from "@/components/LoadingOverlay";
import {ErrorTranslationFieldName} from "@/features/system-errors/type";

const disabledFields = [
  ErrorTranslationFieldName.Code,
];

export const ErrorTranslationUpdateDialog: FC<{open: boolean}> = ({ open }) => {

  const { code = '' } = useParams();

  const {
    isFetching: isFetchingErrorTranslation,
    data: errorTranslation,
    error: errorTranslationFetchingError,
  } = useGetErrorTranslationQuery({ code });

  const [updateErrorTranslation, { isLoading: isUpdating }] = useUpdateErrorTranslationMutation();

  const defaultValues: ErrorTranslationDefaultValues = useMemo(() => {
    if (!errorTranslation) {
      return {};
    }

    const {
      code,
      translations: {
        en,
        de,
        sp
      }
    } = errorTranslation;

    return {code, en, de, sp};
  }, [errorTranslation]);

  const handleSubmit = useCallback(
    async ({
            code,
             en,
             de,
             sp
           }: ErrorTranslationFormValues) => {
      await updateErrorTranslation({
        code,
        translations: {
          en,
          de,
          sp
        }
      }).unwrap();

      toast.success(`Error ${code} has been successfully updated.`);
    },
    [code, updateErrorTranslation],
  );

  useEffect(() => {
    if (errorTranslationFetchingError) {
      handleError(errorTranslationFetchingError);
    }
  }, [errorTranslationFetchingError]);

  return (
    <ErrorTranslationDialog
      title={`Edit Error`}
      submitButtonText="Update"
      defaultValues={defaultValues}
      disabledFields={disabledFields}
      isSubmitting={isUpdating}
      onSubmit={handleSubmit}
      open={open}
    >
      <LoadingOverlay open={isFetchingErrorTranslation} />
    </ErrorTranslationDialog>
  );
};
