import {FeeReportFiltersFieldName} from "@/features/fee-report/components/FeeReportFilters/types";

export const feeReportFiltersFieldLabelMap: Record<
  FeeReportFiltersFieldName,
  string
  > = {
  [FeeReportFiltersFieldName.TransactionType]: "Transaction Type",
  [FeeReportFiltersFieldName.OperatorsAndMerchants]: "Operators/Merchants",
  [FeeReportFiltersFieldName.MerchantIds]: "Merchants",
  [FeeReportFiltersFieldName.DateRange]: "Date range",
  [FeeReportFiltersFieldName.FromDate]: "From date",
  [FeeReportFiltersFieldName.ToDate]: "To date",
  [FeeReportFiltersFieldName.UtcOffset]: "UTC Offset",
  [FeeReportFiltersFieldName.PpaNames]: "PPA names",
  [FeeReportFiltersFieldName.Providers]: "Providers",
  [FeeReportFiltersFieldName.PaymentMethods]: "Payment Methods",
  [FeeReportFiltersFieldName.Currencies]: "Display Currency",
  [FeeReportFiltersFieldName.ProcessingCurrency]: "Use Processing currency",
};


