import {MerchantsFieldName} from "@/features/merchants/components/MerchantsForm/types";

export const merchantsFieldLabelMap: Record<
  MerchantsFieldName,
  string
  > = {
  [MerchantsFieldName.Name]: "Merchant Name",
  [MerchantsFieldName.SecretKey]: "Secret Key",
  [MerchantsFieldName.AuthCodeDeposit]: "Auth Code Deposit",
  [MerchantsFieldName.AuthCodeWithdrawal]: "Auth Code Withdrawal",
  [MerchantsFieldName.UseMerchantTxnIDtoPSP]: "Use Merchant TxnID to PSP",
  [MerchantsFieldName.AutoApprove]: "Auto Approve",
  [MerchantsFieldName.CountryIds]: "Countries",
  [MerchantsFieldName.CurrencyIds]: "Currencies",
  [MerchantsFieldName.OperatorId]: "Operator",
  [MerchantsFieldName.AdminCreateType]: ""
};
