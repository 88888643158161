import {FC, useEffect, useId} from "react";
import * as Styled from "@/features/merchants/components/MerchantsDataGridToolbar/MerchantsDataGridYoolbar.styles";
import {Button} from "@/components/Button";
import {FormSelectField, FormSelectFieldOptions, FormTextField} from "@/components/form";
import * as yup from "yup";
import {DefaultValues, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {UserRole} from "@/types/user";
import {useUser} from "@/hooks/useUser";
import {IMerchantsFilters} from "@/features/merchants/types";

enum MerchantsFilterName {
  OperatorId = "operatorId",
  Search = "search",
}

const formSchema = yup
  .object()
  .shape(
    {
      [MerchantsFilterName.OperatorId]: yup.number(),
      [MerchantsFilterName.Search]: yup.string().trim(),
    }
  )
  .required();

export type MerchantsFiltersFormValues = yup.InferType<typeof formSchema>;
export type MerchantsFiltersFormDefaultValues = DefaultValues<MerchantsFiltersFormValues>;

interface IMerchantsDataGridToolbarProps {
  showCreateDialog: (isOpen: boolean) => void;
  onFilterChange: (filter: IMerchantsFilters) => void;
  defaultFilterValues: IMerchantsFilters;
  filterOptions: FormSelectFieldOptions<number>;
}

export const MerchantsDataGridToolbar: FC<IMerchantsDataGridToolbarProps> = ({
  showCreateDialog,
  defaultFilterValues,
  onFilterChange,
  filterOptions
}) => {
  const formId = useId();

  const { control, getValues, watch, reset} =
    useForm<MerchantsFiltersFormValues>({
      resolver: yupResolver<MerchantsFiltersFormValues>(formSchema),
      mode: "onBlur",
      defaultValues: defaultFilterValues as MerchantsFiltersFormDefaultValues,
    });

  const { user: currentUser } = useUser();

  const operatorId = watch(MerchantsFilterName.OperatorId);
  const search = watch(MerchantsFilterName.Search);

  useEffect(() => {
    if (operatorId || search) {
      onFilterChange(getValues());
    }
  }, [operatorId, search]);

  useEffect(() => {
    reset(defaultFilterValues as MerchantsFiltersFormDefaultValues)
  }, [defaultFilterValues]);
  return (
    <Styled.Root>
      <div>
        {currentUser?.role === UserRole.Admin && <Styled.Form id={formId}>
          <FormSelectField
            name={MerchantsFilterName.OperatorId}
            label="Select Operator"
            control={control}
            options={filterOptions}
            disabled={!filterOptions.length}
            size="small"
          />
          <FormTextField
            name={MerchantsFilterName.Search}
            control={control}
            label="Search"
            type="search"
            size="small"
            sx={{width: '14.5rem'}}
          />
        </Styled.Form>}
      </div>
      <Button onClick={() => {showCreateDialog(true)}}>
        Add New Merchant
      </Button>
    </Styled.Root>
  );
};
