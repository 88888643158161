import {useEffect, useMemo} from "react";
import {convertArrayToSelectOptions} from "@/utils/convertToOptions";
import {handleError} from "@/utils/handleError";
import {useLazyGetDashboardFiltersQuery} from "@/features/dashboard/dashboardApi";
import {useGetEnumsQuery} from "@/features/app/appApiSlice";
import {DashboardFilterTypes} from "@/features/dashboard/type";

export const useDashboardFilters = () => {
  const {
    data: enumsData,
    isFetching: isFetchingEnums,
    error: enumsFetchError,
  } = useGetEnumsQuery();

  const [
    getDashboardFilters,
    {
      data: dashboardFiltersData,
      isFetching: isFetchingDashboardFilters,
      error: dashboardFiltersError,
    },
  ] = useLazyGetDashboardFiltersQuery();

  const {
    utcOffset: utcOffsetOptions = [],
  } = enumsData || {};

  const { operators = [], merchants = [] } = dashboardFiltersData || {};

  const {
    currenciesOptions,
    typeOptions
  } = useMemo(
    () => ({
      currenciesOptions: convertArrayToSelectOptions(dashboardFiltersData?.currencies || []),
      typeOptions: convertArrayToSelectOptions(Object.values(DashboardFilterTypes) || []),
    }),
    [dashboardFiltersData],
  );

  useEffect(() => {
    if (enumsFetchError) {
      handleError(
        enumsFetchError,
        "Whoops! An error happen while fetching dashboard filters enums. Please try again later.",
      );
    }
  }, [enumsFetchError]);

  useEffect(() => {
    if (dashboardFiltersError) {
      handleError(
        dashboardFiltersError,
        "Whoops! An error happen while fetching dashboard filters. Please try again later.",
      );
    }
  }, [dashboardFiltersError]);

  return {
    isFetching: isFetchingDashboardFilters || isFetchingEnums,
    operators,
    merchants,
    currenciesOptions,
    dashboardFiltersData,
    utcOffsetOptions,
    typeOptions,
    getFiltersData: getDashboardFilters,
  };
};
