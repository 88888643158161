import { api } from "@/store/api";
import {
  ICreateUserRequestData,
  IGetUsersRequestParams,
  IGetUsersResponseBody,
  ISwitchUserStatusRequestData,
  ISwitchUserStatusResponseBody,
  IUpdateUserRequestData,
  IUser,
  IUsersFiltersResponse,
} from "@/features/users/types";
import { Id } from "@/types/base";
import { ApiTag } from "@/types/api";

const URL_PREFIX = "/users";

const usersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    createUser: builder.mutation<IUser, ICreateUserRequestData>({
      query: (body) => ({
        url: URL_PREFIX,
        method: "POST",
        body,
      }),
      invalidatesTags: [ApiTag.UsersList],
    }),
    getUsersFilters: builder.query<IUsersFiltersResponse, void>({
      query: () => ({
        url: `${URL_PREFIX}/filters`,
      }),
    }),
    getUsers: builder.query<IGetUsersResponseBody, IGetUsersRequestParams>({
      query: (params) => ({
        url: URL_PREFIX,
        params,
      }),
      providesTags: [ApiTag.UsersList],
    }),
    getUser: builder.query<IUser, { id: Id }>({
      query: ({ id }) => ({
        url: `${URL_PREFIX}/${id}`,
      }),
      providesTags: (user) => [{ type: ApiTag.User, id: user?.id }],
    }),
    updateUser: builder.mutation<IUser, IUpdateUserRequestData>({
      query: (data) => ({
        url: `${URL_PREFIX}/${data.id}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: (user) => [
        ApiTag.UsersList,
        { type: ApiTag.User, id: user?.id },
      ],
    }),
    switchUserStatus: builder.mutation<
      ISwitchUserStatusResponseBody,
      ISwitchUserStatusRequestData
    >({
      query: ({ id, status }) => ({
        url: `${URL_PREFIX}/status/${id}`,
        method: "PATCH",
        body: { status },
      }),
      invalidatesTags: [ApiTag.UsersList],
    }),
    resetPassword: builder.mutation<void, { id: Id }>({
      query: ({ id }) => ({
        url: `${URL_PREFIX}/${id}/password/reset`,
        method: "PUT",
      }),
      invalidatesTags: [ApiTag.UsersList],
    }),
    resetQRCode: builder.mutation<void, { id: Id }>({
      query: ({ id }) => ({
        url: `${URL_PREFIX}/${id}/qrcode/reset`,
        method: "PUT",
      }),
      invalidatesTags: [ApiTag.UsersList],
    }),
    deleteUser: builder.mutation<void, { id: Id }>({
      query: ({ id }) => ({
        url: `${URL_PREFIX}/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [ApiTag.UsersList],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateUserMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useUpdateUserMutation,
  useSwitchUserStatusMutation,
  useResetPasswordMutation,
  useResetQRCodeMutation,
  useDeleteUserMutation,
  useGetUsersFiltersQuery
} = usersApi;
