import {IErrorTranslationsDetailsResponse} from "@/features/system-errors/type";
import {FormSelectFieldOptions} from "@/components/form";

export interface IErrorMappingResponseItem {
  pspName: string;
  hasUnmapped: boolean;
  count: number;
}

export interface IErrorMappingCreateRequest {
  pspName: string;
  pspCode: string;
  message?: string;
  systemCode: string;
}

export interface IErrorMappingData {
  pspName: string;
  code: string;
  message: string;
  error: IErrorTranslationsDetailsResponse;
}

export enum ErrorMappingFilterName {
  PspName = 'pspName'
}

export interface IErrorMappingDialogProps {
  providersOptions: FormSelectFieldOptions;
  providerCodeOptions: Array<IErrorMappingData>;
  open: boolean;
  rowData?: IErrorMappingData;
  selectedPspName?: string;
  closeDialog: (param: any) => void;
}
