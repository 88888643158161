import {api} from "@/store/api";
import {
  IErrorTranslationsDetailsResponse,
  IErrorTranslationsResponseItem,
} from "@/features/system-errors/type";
import {ApiTag} from "@/types/api";

type Code = string;

const URL_PREFIX = "/error-translations";

const systemErrorTranslationsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getErrorTranslations: builder.query<Array<IErrorTranslationsResponseItem>, void>({
      query: () => ({
        url: `${URL_PREFIX}`,
        method: "GET",
      }),
      providesTags: [ApiTag.ErrorTranslations],
    }),
    createErrorTranslations: builder.mutation<IErrorTranslationsDetailsResponse, IErrorTranslationsDetailsResponse>({
      query: (body) => ({
        url: `${URL_PREFIX}`,
        method: "POST",
        body,
      }),
      invalidatesTags: [ApiTag.ErrorTranslations],
    }),
    getErrorTranslation: builder.query<IErrorTranslationsDetailsResponse, { code: Code }>({
      query: ({ code }) => ({
        url: `${URL_PREFIX}/${code}`,
      }),
      providesTags: (eTranslation) => [{ type: ApiTag.ErrorTranslations, id: eTranslation?.code }],
    }),
    updateErrorTranslation: builder.mutation<IErrorTranslationsDetailsResponse, IErrorTranslationsDetailsResponse>({
      query: (data) => ({
        url: `${URL_PREFIX}/${data.code}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: (eTranslation) => [{ type: ApiTag.ErrorTranslations, code: eTranslation?.code }],
    }),
    deleteErrorTranslation: builder.mutation<void, { code: Code }>({
      query: ({ code }) => ({
        url: `${URL_PREFIX}/${code}`,
        method: "DELETE",
      }),
      invalidatesTags: [ApiTag.ErrorTranslations],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetErrorTranslationsQuery,
  useCreateErrorTranslationsMutation,
  useGetErrorTranslationQuery,
  useUpdateErrorTranslationMutation,
  useDeleteErrorTranslationMutation,
} = systemErrorTranslationsApi;
