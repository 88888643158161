import { FormControl as FormControlBase, styled } from "@mui/material";

export const FormControl = styled(FormControlBase)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
`;

export const LabelWrapper = styled("span")`
  display: inline-flex;
`;
