import {FC, useMemo} from "react";
import {DataGrid, IDataGridColumns} from "@/components/DataGrid";
import {MerchantsActionButtons} from "@/features/merchants/components/MerchantsActionButtons";
import {formatDate} from "@/utils/dataFormat/formatDate";
import {useUser} from "@/hooks/useUser";
import {UserRole} from "@/types/user";
import {IMerchant, Merchants} from "@/types/merchant";

enum MerchantsTableFields {
  OperatorName = "operatorName",
  MerchantName = "name",
  MId = "id",
  Created = "createdAt",
  Updated = "updatedAt",
  PPA = "ppaCount",
  Users = "userCount",
}

enum MerchantsTableLabels {
  OperatorName = "Operator Name",
  MerchantName = "Merchant Name",
  MId = "MID",
  Created = "Created",
  Updated = "Updated",
  PPA = "PPA",
  Users = "Users",
}

interface IMerchantsTableProps {
  className?: string;
  isFetching: boolean;
  items: Merchants;
  showEditDialog: (rowData: IMerchant) => void;
  showSettingsDialog: (rowData: IMerchant) => void;
}


export const MerchantsTable: FC<IMerchantsTableProps> = ({
  className,
  isFetching,
  items,
  showEditDialog,
  showSettingsDialog
}) => {
  const {user} = useUser();
  const columns: IDataGridColumns<IMerchant, MerchantsTableFields> = [
    {
      field: MerchantsTableFields.OperatorName,
      headerName: MerchantsTableLabels.OperatorName,
      headCellSx: {
        minWidth: 240,
      },
    },
    {
      field: MerchantsTableFields.MerchantName,
      headerName: MerchantsTableLabels.MerchantName,
      headCellSx: {
        minWidth: 240,
      },
    },
    {
      field: MerchantsTableFields.MId,
      headerName: MerchantsTableLabels.MId,
      headCellSx: {
        minWidth: 90
      },
    },
    {
      field: MerchantsTableFields.Created,
      headerName: MerchantsTableLabels.Created,
      headCellSx: {
        minWidth: 215
      },
      renderCell: ({ rowData }) => formatDate(rowData[MerchantsTableFields.Created]),
    },
    {
      field: MerchantsTableFields.Updated,
      headerName: MerchantsTableLabels.Updated,
      headCellSx: {
        minWidth: 215
      },
      renderCell: ({ rowData }) => formatDate(rowData[MerchantsTableFields.Updated]),
    },

    {
      field: MerchantsTableFields.PPA,
      headerName: MerchantsTableLabels.PPA,
      headCellSx: {
        minWidth: 90
      },
    },
    {
      field: MerchantsTableFields.Users,
      headerName: MerchantsTableLabels.Users,
      headCellSx: {
        minWidth: 90
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headCellSx: {
        width: "1px",
      },
      renderCell: ({ rowData }) => (<MerchantsActionButtons data={rowData} showEditDialog={showEditDialog} showSettingsDialog={showSettingsDialog} />),
    },
  ];

  const updatedConfig = useMemo(() => {
    return columns.filter((item, index) => user?.role !== UserRole.Operator || index !== 0);
  }, [user]);

  return (
    <DataGrid
      className={className}
      columns={updatedConfig}
      data={items}
      emptyMessage="Whoops! There're no merchants at the moment :("
      loading={{
        isLoadingTable: isFetching,
      }}
      idKey={MerchantsTableFields.MId}
    />
  );
};
