import { FC, useCallback, useEffect, useId, useMemo } from "react";
import { DefaultValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { TransactionType } from "@/types/transaction";
import {
  FormCheckboxField,
  FormCheckboxTreeSelectField,
  FormDateField,
  DatePickerProps,
  FormSelectField,
  FormSelectFieldOptions,
  FormTextField,
  FormNumberField,
  FormTextFieldProps,
  MerchantsField,
  OperatorsAndMerchantsField,
} from "@/components/form";
import {
  TransactionFiltersFieldName,
} from "@/features/transactionsManager/components/TransactionFilters/types";
import {
  dateRangeOptions,
  transactionFiltersFieldLabelMap,
} from "@/features/transactionsManager/components/TransactionFilters/constants";
import { useTransactionFilters } from "@/features/transactionsManager/components/TransactionFilters/useTransactionFilters";
import { Button } from "@/components/Button";
import { useUser } from "@/hooks/useUser";
import { CheckboxTreeNodes } from "@/components/CheckboxTree";
import { getBrowserUtcOffset } from "@/utils/getBrowserUtcOffset";
import { formatDateForBackend } from "@/utils/dataFormat/formatDateForBackend";
import {
  IGetDepositTransactionsFiltersRequestData,
  IGetWithdrawTransactionsFiltersRequestData,
} from "@/features/transactionsManager/types";
import * as Styled from "./TransactionFilters.styles";
import {FilterDateRange} from "@/types/base";
import {getDateRangeValues} from "@/utils/getDateRangeValues";

const FIELD_SIZE = "small";

const formSchema = yup
  .object()
  .shape(
    {
      [TransactionFiltersFieldName.OperatorsAndMerchants]: yup.object().shape({
        operatorIds: yup.array().of(yup.number().required()),
        merchantIds: yup.array().of(yup.number().required()),
      }),
      [TransactionFiltersFieldName.MerchantIds]: yup
        .array()
        .of(yup.number().required()),
      [TransactionFiltersFieldName.DateType]: yup.string(),
      [TransactionFiltersFieldName.DateRange]: yup
        .mixed<FilterDateRange>()
        .oneOf(Object.values(FilterDateRange))
        .required(),
      [TransactionFiltersFieldName.FromDate]: yup
        .date()
        .when(TransactionFiltersFieldName.ToDate, ([toDate], schema) => {
          if (!toDate) {
            return schema;
          }

          return schema
            .required()
            .max(
              toDate,
              `"${transactionFiltersFieldLabelMap.fromDate}" can't be after "${transactionFiltersFieldLabelMap.toDate}"`,
            );
        })
        .label(transactionFiltersFieldLabelMap.fromDate),
      [TransactionFiltersFieldName.ToDate]: yup
        .date()
        .when(TransactionFiltersFieldName.FromDate, ([fromDate], schema) => {
          if (!fromDate) {
            return schema;
          }

          return schema
            .required()
            .min(
              fromDate,
              `"${transactionFiltersFieldLabelMap.toDate}" can't be before "${transactionFiltersFieldLabelMap.fromDate}"`,
            );
        })
        .label(transactionFiltersFieldLabelMap.toDate),
      [TransactionFiltersFieldName.UtcOffset]: yup.string(),
      [TransactionFiltersFieldName.PpaNames]: yup
        .array()
        .of(yup.string().required()),
      [TransactionFiltersFieldName.Providers]: yup
        .array()
        .of(yup.string().required()),
      [TransactionFiltersFieldName.PaymentMethods]: yup
        .array()
        .of(yup.string().required()),
      [TransactionFiltersFieldName.Statuses]: yup
        .array()
        .of(yup.string().required()),
      [TransactionFiltersFieldName.MerchantCurrency]: yup.string(),
      [TransactionFiltersFieldName.MidCurrency]: yup.string(),
      [TransactionFiltersFieldName.MerchantTransactionId]: yup.string(),
      [TransactionFiltersFieldName.SystemTransactionId]: yup.number(),
      [TransactionFiltersFieldName.PspTransactionId]: yup.string(),
      [TransactionFiltersFieldName.KeywordType]: yup.string(),
      [TransactionFiltersFieldName.Keyword]: yup.string(),
      [TransactionFiltersFieldName.BlockedOnly]: yup.boolean(),
    },
    [
      [
        TransactionFiltersFieldName.FromDate,
        TransactionFiltersFieldName.ToDate,
      ],
    ],
  )
  .required();

export type TransactionFiltersFormValues = yup.InferType<typeof formSchema>;

type TransactionFiltersFormDefaultValues =
  DefaultValues<TransactionFiltersFormValues>;

interface ITransactionFiltersProps {
  className?: string;
  transactionType: TransactionType;
  defaultValues?: TransactionFiltersFormDefaultValues;
  onClearAllClick: () => void;
  onApply: (values: TransactionFiltersFormValues) => void;
}

export const TransactionFilters: FC<ITransactionFiltersProps> = ({
  className,
  transactionType,
  defaultValues,
  onClearAllClick,
  onApply,
}) => {
  const { permissions } = useUser();
  const {
    transactions: { withOperatorsFilter, withMerchantsFilter },
  } = permissions;
  const {
    isFetching,
    isLiveFilter,
    operators,
    merchants,
    dateTypeOptions,
    utcOffsetOptions,
    ppaNamesOptions,
    providersOptions,
    paymentMethodsOptions,
    statusesOptions,
    merchantCurrencyOptions,
    midCurrencyOptions,
    keywordOptions,
    getFiltersData,
  } = useTransactionFilters({
    transactionType,
  });

  const mergedDefaultValues = useMemo(() => {
    let result = Object.values(TransactionFiltersFieldName).reduce(
      (accumulator, fieldName) => ({
        ...accumulator,
        [fieldName]: undefined,
      }),
      {} as TransactionFiltersFormDefaultValues,
    );

    result = {
      ...result,
      [TransactionFiltersFieldName.DateType]: dateTypeOptions?.[0]?.value,
      [TransactionFiltersFieldName.DateRange]: FilterDateRange.Today,
      [TransactionFiltersFieldName.UtcOffset]: getBrowserUtcOffset(),
      ...defaultValues,
    };

    return result;
  }, [defaultValues, dateTypeOptions]);

  const formId = useId();

  const { control, handleSubmit, getValues, watch, reset } =
    useForm<TransactionFiltersFormValues>({
      resolver: yupResolver<TransactionFiltersFormValues>(formSchema),
      mode: "onBlur",
    });

  const dateRangeValue = watch(TransactionFiltersFieldName.DateRange);
  const fromDateValue = watch(TransactionFiltersFieldName.FromDate);
  const toDateValue = watch(TransactionFiltersFieldName.ToDate);
  const keywordTypeValue = watch(TransactionFiltersFieldName.KeywordType);

  const handleGetFiltersData = useCallback(() => {
    const values = getValues();

    getFiltersData(transformValuesForBackend(values));
  }, [getValues, getFiltersData]);

  const handleLiveFiltersRefresh = useCallback(() => {
    if (isLiveFilter) {
      handleGetFiltersData();
    }
  }, [isLiveFilter, handleGetFiltersData]);

  const handleClearAllClick = useCallback(() => {
    getFiltersData();
    onClearAllClick();
  }, [getFiltersData, onClearAllClick]);

  const handleFormSubmit = useCallback(
    (values: TransactionFiltersFormValues) => {
      handleGetFiltersData();
      onApply(values);
    },
    [handleGetFiltersData, onApply],
  );

  useEffect(() => {
    handleGetFiltersData();
  }, [handleGetFiltersData]);

  useEffect(() => {
    reset(mergedDefaultValues);
  }, [reset, mergedDefaultValues]);

  const getCommonTextFieldProps = useCallback(
    (
      name: TransactionFiltersFieldName,
    ): FormTextFieldProps<TransactionFiltersFormValues> => ({
      name,
      control,
      label: transactionFiltersFieldLabelMap[name],
      size: FIELD_SIZE,
      InputProps: {
        readOnly: isFetching,
      },
      onBlur: handleLiveFiltersRefresh,
    }),
    [control, isFetching, handleLiveFiltersRefresh],
  );

  const renderTextField = useCallback(
    (
      name: TransactionFiltersFieldName,
      textFieldProps?: Partial<
        FormTextFieldProps<TransactionFiltersFormValues>
      >,
    ) => (
      <FormTextField {...getCommonTextFieldProps(name)} {...textFieldProps} />
    ),
    [getCommonTextFieldProps],
  );

  const renderNumberField = useCallback(
    (name: TransactionFiltersFieldName) => (
      <FormNumberField {...getCommonTextFieldProps(name)} />
    ),
    [getCommonTextFieldProps],
  );

  const renderSelectField = useCallback(
    (name: TransactionFiltersFieldName, options: FormSelectFieldOptions) => (
      <FormSelectField
        name={name}
        label={transactionFiltersFieldLabelMap[name]}
        control={control}
        options={options}
        disabled={!options.length}
        readOnly={isFetching}
        size={FIELD_SIZE}
        onClose={handleLiveFiltersRefresh}
      />
    ),
    [control, isFetching, handleLiveFiltersRefresh],
  );

  const renderCheckboxTreeSelectField = useCallback(
    (name: TransactionFiltersFieldName, nodes: CheckboxTreeNodes) => (
      <FormCheckboxTreeSelectField
        name={name}
        label={transactionFiltersFieldLabelMap[name]}
        control={control}
        nodes={nodes}
        disabled={!nodes.length}
        readOnly={isFetching}
        size={FIELD_SIZE}
        onClose={handleLiveFiltersRefresh}
      />
    ),
    [control, isFetching, handleLiveFiltersRefresh],
  );

  const renderCheckboxField = useCallback(
    (name: TransactionFiltersFieldName) => (
      <FormCheckboxField
        name={name}
        control={control}
        label={transactionFiltersFieldLabelMap[name]}
        readOnly={isFetching}
        onChange={handleLiveFiltersRefresh}
      />
    ),
    [control, isFetching, handleLiveFiltersRefresh],
  );

  const renderDateField = useCallback(
    (name: TransactionFiltersFieldName, datePickerProps?: DatePickerProps) => (
      <FormDateField
        name={name}
        control={control}
        label={transactionFiltersFieldLabelMap[name]}
        readOnly={isFetching}
        slotProps={{
          textField: {
            size: FIELD_SIZE,
          },
        }}
        onClose={handleLiveFiltersRefresh}
        {...datePickerProps}
      />
    ),
    [control, isFetching, handleLiveFiltersRefresh],
  );

  return (
    <div className={className}>
      <Styled.Form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
        <Styled.Fields>
          {renderSelectField(
            TransactionFiltersFieldName.DateType,
            dateTypeOptions,
          )}
          {renderSelectField(
            TransactionFiltersFieldName.DateRange,
            dateRangeOptions,
          )}
          {dateRangeValue === FilterDateRange.CustomPeriod && (
            <>
              {renderDateField(TransactionFiltersFieldName.FromDate, {
                maxDate: toDateValue,
              })}
              {renderDateField(TransactionFiltersFieldName.ToDate, {
                minDate: fromDateValue,
              })}
            </>
          )}
          {renderSelectField(
            TransactionFiltersFieldName.UtcOffset,
            utcOffsetOptions,
          )}
        </Styled.Fields>
        <Styled.Fields>
          {withOperatorsFilter && (
            <OperatorsAndMerchantsField
              name={TransactionFiltersFieldName.OperatorsAndMerchants}
              control={control}
              operators={operators}
              label={
                transactionFiltersFieldLabelMap[
                  TransactionFiltersFieldName.OperatorsAndMerchants
                ]
              }
              disabled={!operators.length}
              readOnly={isFetching}
              size={FIELD_SIZE}
              onClose={handleLiveFiltersRefresh}
            />
          )}
          {withMerchantsFilter && (
            <MerchantsField
              name={TransactionFiltersFieldName.MerchantIds}
              control={control}
              merchants={merchants}
              label={
                transactionFiltersFieldLabelMap[
                  TransactionFiltersFieldName.MerchantIds
                ]
              }
              disabled={!merchants.length}
              readOnly={isFetching}
              size={FIELD_SIZE}
              onClose={handleLiveFiltersRefresh}
            />
          )}
          {renderCheckboxTreeSelectField(
            TransactionFiltersFieldName.PpaNames,
            ppaNamesOptions,
          )}
          {renderCheckboxTreeSelectField(
            TransactionFiltersFieldName.Providers,
            providersOptions,
          )}
          {renderCheckboxTreeSelectField(
            TransactionFiltersFieldName.PaymentMethods,
            paymentMethodsOptions,
          )}
          {renderCheckboxTreeSelectField(
            TransactionFiltersFieldName.Statuses,
            statusesOptions,
          )}
          {renderSelectField(
            TransactionFiltersFieldName.MerchantCurrency,
            merchantCurrencyOptions,
          )}
          {renderSelectField(
            TransactionFiltersFieldName.MidCurrency,
            midCurrencyOptions,
          )}
        </Styled.Fields>
        <Styled.Fields>
          {renderTextField(TransactionFiltersFieldName.MerchantTransactionId)}
          {renderNumberField(TransactionFiltersFieldName.SystemTransactionId)}
          {renderTextField(TransactionFiltersFieldName.PspTransactionId)}
          {renderSelectField(
            TransactionFiltersFieldName.KeywordType,
            keywordOptions,
          )}
          {renderTextField(TransactionFiltersFieldName.Keyword, {
            disabled: !keywordTypeValue,
          })}
          {transactionType === TransactionType.Withdrawal &&
            renderCheckboxField(TransactionFiltersFieldName.BlockedOnly)}
        </Styled.Fields>
        <Styled.LoadingOverlay open={isFetching} />
      </Styled.Form>
      <Styled.Actions>
        <Button variant="outlined" onClick={handleClearAllClick}>
          Clear All
        </Button>
        <Button type="submit" form={formId}>
          Apply Filters
        </Button>
      </Styled.Actions>
    </div>
  );
};

export const transformValuesForBackend = (
  // TODO: Move this function out of component
  formValues: TransactionFiltersFormValues,
):
  | IGetDepositTransactionsFiltersRequestData
  | IGetWithdrawTransactionsFiltersRequestData => {
  const {
    operatorsAndMerchants,
    merchantIds,
    dateType,
    dateRange,
    fromDate,
    toDate,
    ...restFormValues
  } = formValues;

  const result: ReturnType<typeof transformValuesForBackend> = {
    ...restFormValues,
    dateType,
    operatorIds: operatorsAndMerchants?.operatorIds,
    merchantIds: merchantIds || operatorsAndMerchants?.merchantIds,
  };

  if (dateType && dateRange) {
    const dateRangeValues = getDateRangeValues(dateRange);
    const fromDateValue = fromDate || dateRangeValues?.fromDate;
    const toDateValue = toDate || dateRangeValues?.toDate;

    if (fromDateValue && toDateValue) {
      result.fromDate = formatDateForBackend(fromDateValue);
      result.toDate = formatDateForBackend(toDateValue);
    }
  }

  return result;
};
