export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

export interface ISortData<TSortKey> {
  column: TSortKey;
  order: SortOrder;
}

export interface IRequestDataWithSorting<TSortKey>
  extends Partial<ISortData<TSortKey>> {}

export interface IPaginatedResponseBody<TItems extends Array<unknown>> {
  items: TItems;
  pageIndex: number;
  pageSize: number;
  totalCount: number;
}

export interface IPaginatedRequestData {
  pageIndex?: number;
  pageSize?: number;
}

export interface IFilteredRequestData<TFilters> {
  filters?: TFilters;
}

export type Id = number;

export enum FilterDateRange {
  Today = "Today",
  Yesterday = "Yesterday",
  CurrentWeek = "Current week",
  CurrentMonth = "Current month",
  LastWeek = "Last week",
  Last30Days = "Last 30 days",
  Last180Days = "Last 180 days",
  LastYear = "Last year",
  CustomPeriod = "Custom period",
}
