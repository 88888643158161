import {api} from "@/store/api";
import {ICurrencyItem} from "@/features/currencies/type";

const API_PREFIX = "/app/currencies";

const currenciesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCurrencies: builder.query<Array<ICurrencyItem>, void>({
      query: () => ({
        url: `${API_PREFIX}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export const { useGetCurrenciesQuery } = currenciesApi;
