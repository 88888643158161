import {FC, useCallback} from "react";
import {toast} from "react-toastify";
import {useCreateMerchantMutation} from "@/features/merchants/merchantsApi";
import {IMerchantDialogProps} from "@/features/merchants/types";
import {MerchantFormValues, MerchantsForm} from "@/features/merchants/components/MerchantsForm/MerchantsForm";
import {MerchantsFieldName} from "@/features/merchants/components/MerchantsForm/types";
import {useUser} from "@/hooks/useUser";
import {UserRole} from "@/types/user";

export const MerchantCreateDialog: FC<IMerchantDialogProps> = ({ open, closeDialog, operatorsOptions }) => {
  const [createMerchant, { isLoading }] = useCreateMerchantMutation();
  const { user: currentUser } = useUser();
  const handleSubmit = useCallback(
    async (data: MerchantFormValues) => {
      await createMerchant(data).unwrap();

      toast.success(`Merchant ${data.name} has been created.`);
    },
    [createMerchant],
  );

  return (
    <MerchantsForm
      title={`Add New Merchant`}
      submitButtonText="Create"
      isSubmitting={isLoading}
      defaultValues={{[MerchantsFieldName.AdminCreateType]: currentUser?.role === UserRole.Admin}}
      onSubmit={handleSubmit}
      closeDialog={closeDialog}
      open={open}
      operatorsOptions={operatorsOptions}
      isAdminCreate={currentUser?.role === UserRole.Admin}
    />
  );
};
