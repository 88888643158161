import {FC, useMemo} from "react";
import {DataGrid, IDataGridColumns} from "@/components/DataGrid";
import {
  DashboardVolumeProps,
  IDashboardCountResponseItem,
} from "@/features/dashboard/type";

enum DashboardCountTableFields {
  Operator = 'operatorName',
  Merchant = 'merchantName',
  DepositsTotal = 'depositTotal',
  DepositsProcessingCancelled = 'depositProcessing',
  DepositsConfirmed = 'depositConfirmed',
  DepositsDeclinedFailed = 'depositDeclined',
  DepositsAr = 'depositAr',
  DepositsCr = 'depositCr',
  WithdrawalsTotal = 'withdrawalTotal',
  WithdrawalsConfirmed = 'withdrawalConfirmed',
  WithdrawalsDeclinedFailed = 'withdrawalDeclined',
  WithdrawalsAr = 'withdrawalAr',
  PPAName = 'ppaName',
}

enum DashboardCountTableLabels {
  Operator = 'Operator',
  Merchant = 'Merchant',
  DepositsTotal = 'Deposits Total',
  DepositsProcessingCancelled = 'Deposits Processing/Cancelled',
  DepositsConfirmed = 'Deposits Confirmed',
  DepositsDeclinedFailed = 'Deposits Declined/Failed',
  DepositsAr = 'Deposits Ar',
  DepositsCr = 'Deposits Cr',
  WithdrawalsTotal = 'Withdrawals Total',
  WithdrawalsConfirmed = 'Withdrawals Confirmed',
  WithdrawalsDeclinedFailed = 'Withdrawals Declined/Failed',
  WithdrawalsAr = 'Withdrawals Ar',
}

const childrenKeys = 'perPpa';
const idRowKey = 'merchantId';

export const DashboardCountTable: FC<DashboardVolumeProps<IDashboardCountResponseItem>> = ({isFetching, items, className, title, isOnlyMerchant, isExpanded}) => {
  const columns: IDataGridColumns<any> = [
    {
      field: DashboardCountTableFields.Operator,
      headerName: DashboardCountTableLabels.Operator,
      headCellSx: {
        minWidth: 180
      },
      emptyValue: ' ',
    },
    {
      field: DashboardCountTableFields.Merchant,
      childrenFieldKey: DashboardCountTableFields.PPAName,
      headerName: DashboardCountTableLabels.Merchant,
      headCellSx: {
        minWidth: 180
      },
    },
    {
      field: DashboardCountTableFields.DepositsTotal,
      headerName: DashboardCountTableLabels.DepositsTotal,
      headCellSx: {
        minWidth: 90,
        whiteSpace: "wrap",
      },
      emptyValue: '0',
    },
    {
      field: DashboardCountTableFields.DepositsProcessingCancelled,
      headerName: DashboardCountTableLabels.DepositsProcessingCancelled,
      headCellSx: {
        minWidth: 90,
        whiteSpace: "wrap",
      },
      emptyValue: '0',
    },
    {
      field: DashboardCountTableFields.DepositsConfirmed,
      headerName: DashboardCountTableLabels.DepositsConfirmed,
      headCellSx: {
        minWidth: 90,
        whiteSpace: "wrap",
      },
      emptyValue: '0',
    },
    {
      field: DashboardCountTableFields.DepositsDeclinedFailed,
      headerName: DashboardCountTableLabels.DepositsDeclinedFailed,
      headCellSx: {
        minWidth: 90,
        whiteSpace: "wrap",
      },
      emptyValue: '0',
    },
    {
      field: DashboardCountTableFields.DepositsAr,
      headerName: DashboardCountTableLabels.DepositsAr,
      headCellSx: {
        minWidth: 90,
        whiteSpace: "wrap",
      },
    },
    {
      field: DashboardCountTableFields.DepositsCr,
      headerName: DashboardCountTableLabels.DepositsCr,
      headCellSx: {
        minWidth: 90,
        whiteSpace: "wrap",
      },
    },
    {
      field: DashboardCountTableFields.WithdrawalsTotal,
      headerName: DashboardCountTableLabels.WithdrawalsTotal,
      headCellSx: {
        minWidth: 90,
        whiteSpace: "wrap",
      },
      emptyValue: '0',
    },
    {
      field: DashboardCountTableFields.WithdrawalsConfirmed,
      headerName: DashboardCountTableLabels.WithdrawalsConfirmed,
      headCellSx: {
        minWidth: 90,
        whiteSpace: "wrap",
      },
      emptyValue: '0',
    },

    {
      field: DashboardCountTableFields.WithdrawalsDeclinedFailed,
      headerName: DashboardCountTableLabels.WithdrawalsDeclinedFailed,
      headCellSx: {
        minWidth: 90,
        whiteSpace: "wrap",
      },
      emptyValue: '0',
    },
    {
      field: DashboardCountTableFields.WithdrawalsAr,
      headerName: DashboardCountTableLabels.WithdrawalsAr,
      headCellSx: {
        minWidth: 90,
        whiteSpace: "wrap",
      },
    },
  ];

  const updatedConfig = useMemo(() => {
    return columns.filter((item, index) => !isOnlyMerchant || index !== 0);
  }, [isOnlyMerchant]);

  return (
    <>
      <h3>{title}</h3>
      <DataGrid
        className={className}
        columns={updatedConfig}
        data={items}
        emptyMessage="Whoops! There're no count data at the moment :("
        loading={{
          isLoadingTable: isFetching,
        }}
        childrenKeys={childrenKeys}
        idKey={idRowKey}
        isExpandedChildren={isExpanded}
      />
    </>
  );
};
