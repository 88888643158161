import {FormSelectFieldOptions} from "@/components/form";
import {FilterDateRange} from "@/types/base";

export const convertArrayToSelectOptions = (
  items: string[],
): FormSelectFieldOptions => {
  return items.map((item) => ({
    label: item,
    value: item,
  }));
};

export const dateRangeOptions = Object.values(FilterDateRange).map((value) => ({
  value,
  label: value,
}));
