import {FC, useEffect} from "react";
import {PageContent} from "@/components/PageContent";
import {FeatureTitle} from "@/types/featureTitle";
import { Outlet } from "react-router-dom";
import {ErrorTranslationTable} from "@/features/system-errors/components/ErrorTranslationTable";
import {useGetErrorTranslationsQuery} from "@/features/system-errors/systemErrorsApi";
import {handleError} from "@/utils/handleError";
import {ErrorTranslationsTableToolbar} from "@/features/system-errors/components/ErrorTranslationTableToolbar";

export const SystemErrorTranslationPage: FC = () => {
  const { data, isFetching, error } = useGetErrorTranslationsQuery();

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);
  return (
    <PageContent title={FeatureTitle.SystemErrors}>
      <ErrorTranslationsTableToolbar/>
      <ErrorTranslationTable isFetching={isFetching} items={data || []} />
      <Outlet />
    </PageContent>
  )
};
