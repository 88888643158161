import {IActionsButtonItem} from "@/components/ActionsButton";
import {IErrorTranslationsResponseItem} from "@/features/system-errors/type";

export enum ErrorTranslationActionTitle {
  EditErrorTranslation = "Edit",
  DeleteErrorTranslation = "Delete",
}

export interface IErrorTranslationActionsItemsData {
  translation: IErrorTranslationsResponseItem
}

export interface IErrorTranslationActionsItem extends IActionsButtonItem<ErrorTranslationActionTitle> {}
