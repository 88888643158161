import {DashboardFiltersFieldName} from "@/features/dashboard/components/DashboardFilters/type";

export const dashboardFiltersFieldLabelMap: Record<
  DashboardFiltersFieldName,
  string
  > = {
  [DashboardFiltersFieldName.OperatorsAndMerchants]: "Operators/Merchants",
  [DashboardFiltersFieldName.MerchantIds]: "Merchants",
  [DashboardFiltersFieldName.DateRange]: "Date range",
  [DashboardFiltersFieldName.FromDate]: "From date",
  [DashboardFiltersFieldName.ToDate]: "To date",
  [DashboardFiltersFieldName.Currencies]: "Display Currency",
  [DashboardFiltersFieldName.UtcOffset]: "UTC Offset",
  [DashboardFiltersFieldName.Type]: "Type",
  [DashboardFiltersFieldName.Expanded]: "Show expanded",
};
