import {
  TransactionFiltersFieldName,
} from "@/features/transactionsManager/components/TransactionFilters/types";
import {FilterDateRange} from "@/types/base";

export const transactionFiltersFieldLabelMap: Record<
  TransactionFiltersFieldName,
  string
> = {
  [TransactionFiltersFieldName.OperatorsAndMerchants]: "Operators/Merchants",
  [TransactionFiltersFieldName.MerchantIds]: "Merchants",
  [TransactionFiltersFieldName.DateType]: "Date type",
  [TransactionFiltersFieldName.DateRange]: "Date range",
  [TransactionFiltersFieldName.FromDate]: "From date",
  [TransactionFiltersFieldName.ToDate]: "To date",
  [TransactionFiltersFieldName.UtcOffset]: "UTC Offset",
  [TransactionFiltersFieldName.PpaNames]: "PPA names",
  [TransactionFiltersFieldName.Providers]: "Providers",
  [TransactionFiltersFieldName.PaymentMethods]: "Payment Methods",
  [TransactionFiltersFieldName.Statuses]: "Statuses",
  [TransactionFiltersFieldName.MerchantCurrency]: "Merchant currency",
  [TransactionFiltersFieldName.MidCurrency]: "PSP MID currency",
  [TransactionFiltersFieldName.MerchantTransactionId]: "Merchant Txn ID",
  [TransactionFiltersFieldName.SystemTransactionId]: "System Txn ID",
  [TransactionFiltersFieldName.PspTransactionId]: "PSP Txn ID",
  [TransactionFiltersFieldName.KeywordType]: "Select keyword type",
  [TransactionFiltersFieldName.Keyword]: "Keyword",
  [TransactionFiltersFieldName.BlockedOnly]: "Blocked only",
};

export const dateRangeOptions = Object.values(FilterDateRange).map((value) => ({
  value,
  label: value,
}));
