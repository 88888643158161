import {useGetErrorTranslationsQuery} from "@/features/system-errors/systemErrorsApi";
import {useEffect, useMemo} from "react";
import {handleError} from "@/utils/handleError";
import {IErrorTranslationsResponseItem} from "@/features/system-errors/type";

export const useSystemErrorOptions = () => {
  const { data, isFetching: isFetchingSystemError, error } = useGetErrorTranslationsQuery();
  const systemErrorOptions = useMemo(() => {
    return data?.map((item: IErrorTranslationsResponseItem) => {
      return {
        label: item.en ? `${item.code} - ${item.en}` : item.code,
        value: item.code
      }
    }) || [];
  }, [data]);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  return {
    isFetchingSystemError,
    systemErrorOptions
  }
};
