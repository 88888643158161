import {useGetCountriesQuery} from "@/features/countries/countriesApi";
import {useGetCurrenciesQuery} from "@/features/currencies/currenciesApi";
import {useEffect, useMemo} from "react";
import {handleError} from "@/utils/handleError";
import {ICountryItem} from "@/features/countries/type";
import {ICurrencyItem} from "@/features/currencies/type";


export const useMerchantsForm = () => {
  const {
    data: countries,
    isFetching: isFetchingCountries,
    error: countriesFetchError,
  } = useGetCountriesQuery();

  const {
    data: currencies,
    isFetching: isFetchingCurrencies,
    error: currenciesFetchError,
  } = useGetCurrenciesQuery();

  const {countriesOptions} = useMemo( () => (
    {
      countriesOptions: countries?.map((item: ICountryItem) => ({
        value: item.alpha3Code,
        label: item.name,
      })) || [],
    }
  ), [countries]);

  const {currenciesOptions} = useMemo( () => (
    {
      currenciesOptions: currencies?.map((item: ICurrencyItem) => ({
        value: item.code,
        label: item.code,
      })) || [],
    }
  ), [currencies]);

  useEffect(() => {
    if (countriesFetchError) {
      handleError(
        countriesFetchError,
        "Whoops! An error happen while fetching countries. Please try again later.",
      );
    }
  }, [countriesFetchError]);

  useEffect(() => {
    if (currenciesFetchError) {
      handleError(
        currenciesFetchError,
        "Whoops! An error happen while fetching currencies. Please try again later.",
      );
    }
  }, [currenciesFetchError]);

  return {
    isFetching: isFetchingCountries || isFetchingCurrencies,
    countriesOptions,
    currenciesOptions
  }
};
