export enum UserRole {
  Admin = "Admin",
  Operator = "Operator",
  Merchant = "Merchant",
}

export enum UserAuthType {
  None = "None",
  GoogleAuthenticator = "GoogleAuthenticator",
  Email = "Email",
}

export interface IUserPermissions {
  transactions: {
    withOperatorsFilter: boolean;
    withMerchantsFilter: boolean;
  };
  users: boolean;
  feeReport: {
    withAdminFilter: boolean;
    withOperatorsFilter: boolean;
    withMerchantsFilter: boolean;
  };
  settings: boolean;
  operators: boolean;
  merchants: boolean;
}
