import {FC, useCallback, useMemo} from "react";
import {toast} from "react-toastify"
import {IOperatorDialogProps} from "@/features/operators/types";
import {OperatorDefaultValues, OperatorForm, OperatorFormValues} from "@/features/operators/components/OperatorForm";
import {useUpdateOperatorDetailsMutation} from "@/features/operators/operatorsApi";

export const OperatorEditDialog: FC<IOperatorDialogProps> = (
  { open, closeDialog, rowData }) => {
  const [updateOperatorDetails, { isLoading }] = useUpdateOperatorDetailsMutation();
  const handleSubmit = useCallback(
    async ({
             name,
           }: OperatorFormValues) => {
      if (!rowData?.id) {
        return;
      }
      await updateOperatorDetails({
        id: rowData.id,
        name
      }).unwrap();

      toast.success(`Operator ${name} has been updated.`);
    },
    [updateOperatorDetails, rowData],
  );

  const defaultValues: OperatorDefaultValues = useMemo(() => {
    if (!rowData) {
      return {};
    }

    const {
      name
    } = rowData;

    return {name};
  }, [rowData]);

  return (
    <OperatorForm
      title={`Edit Operator ${rowData?.name || ''}`}
      submitButtonText="Update"
      isSubmitting={isLoading}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      closeDialog={closeDialog}
      open={open}
    />
  );
};
