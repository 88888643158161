import {FC} from "react";
import {ActionsButton} from "@/components/ActionsButton";
import {IErrorMappingActionsItemsData} from "@/features/error-mapping/components/ErrorMappingActionButtons/type";
import {useErrorMappingActions} from "@/features/error-mapping/components/ErrorMappingActionButtons/useErrorMappingActions";

export const ErrorMappingActionButtons: FC<IErrorMappingActionsItemsData> = ({ data, showEditDialog }) => {
  const { errorMappingActionsItems } = useErrorMappingActions({ data, showEditDialog });

  return <ActionsButton items={errorMappingActionsItems} />;
};
