import {IconButton, styled} from "@mui/material";
import {Info as InfoIcon} from "@mui/icons-material";

export const Fields = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;

export const FieldsOneColumn = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 0.5rem
`;

export const RefreshSkBtn = styled(IconButton)`
  padding: 0;
  margin-left: 0.25rem;
`;

export const TooltipWrapper = styled(InfoIcon)`
  margin-left: 0.5rem;
`;
