import {FC} from "react";
import {useErrorTranslationActions} from "@/features/system-errors/components/ErrorTranslationActionButtons/useErrorTranslationActions";
import {ActionsButton} from "@/components/ActionsButton";
import {IErrorTranslationActionsItemsData} from "@/features/system-errors/components/ErrorTranslationActionButtons/type";

export const ErrorTranslationActionButtons: FC<IErrorTranslationActionsItemsData> = ({ translation }) => {
  const { errorTranslationsActionsItems } = useErrorTranslationActions({ translation });

  return <ActionsButton items={errorTranslationsActionsItems} />;
};
