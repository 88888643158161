import {formatDateForBackend} from "@/utils/dataFormat/formatDateForBackend";
import {getDateRangeValues} from "@/utils/getDateRangeValues";

export const transformValuesForBackend = (
  formValues: any,
): any => {
  const {
    operatorsAndMerchants,
    merchantIds,
    dateRange,
    fromDate,
    toDate,
    ...restFormValues
  } = formValues;

  const result: ReturnType<typeof transformValuesForBackend> = {
    ...restFormValues,
    operatorIds: operatorsAndMerchants?.operatorIds,
    merchantIds: merchantIds || operatorsAndMerchants?.merchantIds,
  };

  if (dateRange) {
    const dateRangeValues = getDateRangeValues(dateRange);
    const fromDateValue = fromDate || dateRangeValues?.fromDate;
    const toDateValue = toDate || dateRangeValues?.toDate;

    if (fromDateValue && toDateValue) {
      result.fromDate = formatDateForBackend(fromDateValue);
      result.toDate = formatDateForBackend(toDateValue);
    }
  }

  return result;
};
