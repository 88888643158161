import {IPaginatedRequestData, IPaginatedResponseBody} from "@/types/base";
import { Merchants } from "@/types/merchant";
import {FormSelectFieldOptions} from "@/components/form";

export interface IGetMerchantsResponseBody extends IPaginatedResponseBody<Merchants> {}

export interface IMerchantsListResponseItem {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  ppaCount: number;
  ppaNames: Array<string>;
  userCount: number;
  userIds: Array<number>;
}

export interface IMerchantDetails {
  id: number;
  name: string;
  secretKey: string;
  requireAuthCodeDeposit: boolean;
  requireAuthCodeWithdrawal: boolean;
  useMerchantTxnIDtoPSP: boolean;
  autoApprove: boolean;
  currencyCodes: Array<string>;
  countryCodes: Array<string>;
  createdAt: string;
  updatedAt: string;
  callbackRetryType: MerchantSettingsRetryTypes;
  callbackRetryTimeInterval: number;
  callbackRetryTimeStep: number;
  callbackRetryTimeStepMultiplier: number;
  callbackRetries: number;
}

export interface IMerchantSettingsRequest {
  id?: number;
  callbackRetryType: MerchantSettingsRetryTypes;
  callbackRetryTimeInterval?: number;
  callbackRetryTimeStep?: number;
  callbackRetryTimeStepMultiplier?: number;
  callbackRetries?: number;
}

export enum MerchantSettingsRetryTypes {
  Incremental = "Incremental",
  Disabled = "Disabled",
  Exponential = "Exponential"
}

export interface IMerchantDetailsRequest {
  id?: number;
  name: string;
  secretKey: string;
  requireAuthCodeDeposit?: boolean;
  requireAuthCodeWithdrawal?: boolean;
  useMerchantTxnIDtoPSP?: boolean;
  autoApprove?: boolean;
  currencyCodes?: Array<string>;
  countryCodes?: Array<string>;
}

export interface IMerchantDialogProps {
  open: boolean;
  rowData?: IMerchantsListResponseItem;
  closeDialog: (param: boolean) => void;
  operatorsOptions?: FormSelectFieldOptions<number>;
}

export interface IMerchantEditDialogProps extends IMerchantDialogProps {
  rowData: IMerchantsListResponseItem;
}

export interface IMerchantSettingsDialogProps {
  open: boolean;
  rowData: IMerchantsListResponseItem;
  closeDialog: (param: boolean) => void;
}

export interface IGetMerchantsRequestParams extends IPaginatedRequestData {
  filters?: IMerchantsFilters
}

export interface IMerchantsFilters {
  search?: string;
  operatorId?: number | null;
}

 export enum MerchantSettingsTimes {
   Sec = 1,
   Min = 60,
   Hour = 3600
 }
