import { alpha, styled } from "@mui/material";
import {LoadingOverlay as LoadingOverlayBase} from "@/components/LoadingOverlay";

export const Form = styled("form")`
  position: relative;
  display: grid;
  grid-gap: 0.75rem;
  padding-top: 1rem;
  margin-top: -1rem;
`;

export const Fields = styled("div")`
  display: grid;
  grid-template-columns: repeat(auto-fill, 12.5rem);
  grid-gap: 0.75rem;
`;

export const LoadingOverlay = styled(LoadingOverlayBase)(({ theme }) => ({
  backgroundColor: alpha(theme.palette.background.default, 0.2),
  backdropFilter: "blur(1px)",
}));

export const Actions = styled("div")`
  display: flex;
  justify-content: flex-end;
  margin-top: 0.75rem;
  gap: 0.5rem;
`;
