import {FC, useCallback, useMemo} from "react";
import {useUpdateErrorMappingDetailsMutation} from "@/features/error-mapping/errorMappingApi";
import {toast} from "react-toastify";
import {
  ErrorMappingDefaultValues,
  ErrorMappingFieldName,
  ErrorMappingForm,
  ErrorMappingFormValues
} from "@/features/error-mapping/components/ErrorMappingForm/ErrorMappingForm";
import {IErrorMappingData, IErrorMappingDialogProps} from "@/features/error-mapping/type";

const disabledFields = [
  ErrorMappingFieldName.PspCode,
  ErrorMappingFieldName.PspName,
];

export const ErrorMappingEditDialog: FC<IErrorMappingDialogProps> = (
  { open, providersOptions, providerCodeOptions, closeDialog, rowData }) => {
  const [updateErrorMappingDetails, { isLoading }] = useUpdateErrorMappingDetailsMutation();
  const handleSubmit = useCallback(
    async ({
             pspName,
             pspCode,
             systemCode,
           }: ErrorMappingFormValues) => {
      await updateErrorMappingDetails({
        pspName,
        pspCode,
        systemCode,
      }).unwrap();

      toast.success(`Mapping for ${pspName} ${pspCode} has been updated.`);
    },
    [updateErrorMappingDetails],
  );

  const filteredProviderCodeOptions = useMemo(
    () => (
      providerCodeOptions.map((item: IErrorMappingData) => {
        return {
          label: item.message ? `${item.code} - ${item.message}` : item.code,
          value: item.code,
        }
      }) || []
    ),
    [providerCodeOptions],
  );

  const defaultValues: ErrorMappingDefaultValues = useMemo(() => {
    if (!rowData) {
      return {};
    }

    const {
      pspName,
      code: pspCode,
      error: {
        code: systemCode
      }
    } = rowData;

    return {pspName, pspCode, systemCode};
  }, [rowData]);

  return (
    <ErrorMappingForm
      title={`Edit Mapping`}
      submitButtonText="Edit"
      isSubmitting={isLoading}
      disabledFields={disabledFields}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      providerCodeOptions={filteredProviderCodeOptions}
      providersOptions={providersOptions}
      closeDialog={closeDialog}
      open={open}
    />
  );
};
