import { styled } from "@mui/material";

export const Root = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
`;

export const Form = styled("form")`
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: 14.5rem 14.5rem;
  padding-top: 1rem;
  margin-top: -1rem;
  align-items: flex-start;
`;
