import {Operators} from "@/types/operator";
import {Merchants} from "@/types/merchant";

export interface IDashboardFilterRequest {
  operatorIds: Array<number>;
  merchantIds: Array<number>;
}

export interface IDashboardFilterResponse {
  currencies: Array<string>;
  operators?: Operators;
  merchants?: Merchants;
}

export interface IDashboardFormFilter extends IDashboardMainRequest {
  type?: Array<DashboardFilterTypes>;
  expanded?: boolean;
}

export interface IDashboardMainRequest {
  fromDate?: string;
  toDate?: string;
  utcOffset?: string;
  currencyCode?: string;
  operatorIds?: Array<number>;
  merchantIds?: Array<number>;
}

export interface IDashboardVolumeResponseItem {
  operatorId: number;
  operatorName: string;
  merchantId: number;
  merchantName: string;
  currencyCode: string;
  depositSum: number;
  withdrawalSum: number;
  perPpa: Array<IDashboardVolumePerPPAItem>;
}

export interface IDashboardVolumePerPPAItem {
  ppaName: string;
  depositSum: number;
  withdrawalSum: number;
}

export interface IDashboardCountResponseItem {
  operatorId: number;
  operatorName: string;
  merchantId: number;
  merchantName: string;
  depositTotal: number;
  depositConfirmed: number;
  depositDeclined: number;
  depositFailed: number;
  depositProcessing: number;
  depositCancelled: number;
  depositAr: string;
  depositCr: string;
  withdrawalTotal: number;
  withdrawalConfirmed: number;
  withdrawalDeclined: number;
  withdrawalFailed: number;
  withdrawalAr: string;
  perPpa: Array<IDashboardCountPerPPAItem>;
}

export interface IDashboardCountPerPPAItem {
  ppaName: string;
  depositPpaTotal: number;
  depositPpaConfirmed: number;
  depositPpaDeclined: number;
  depositPpaFailed: number;
  depositPpaProcessing: number;
  depositPpaCancelled: number;
  depositPpaAr: string;
  depositPpaCr: string;
  withdrawalPpaTotal: number;
  withdrawalPpaConfirmed: number;
  withdrawalPpaDeclined: number;
  withdrawalPpaFailed: number;
  withdrawalPpaAr: string;
}

export enum DashboardFilterTypes {
  Volume = 'Volume',
  Count = 'Count',
}

export interface DashboardVolumeProps<T = any> {
  isFetching: boolean;
  items: Array<T>;
  className?: string;
  title: string;
  isOnlyMerchant: boolean;
  isExpanded: boolean;
}
