import {styled} from "@mui/material";

export const Fields = styled("div")`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
`;

export const FieldsRowWrapper = styled("div")`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
`;
