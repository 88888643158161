import qs, { IStringifyOptions } from "qs";
import { Id } from "@/types/base";
import { TransactionId, TransactionType } from "@/types/transaction";

const USERS_ROOT_PATH = "/users";
const FEE_REPORT_ROOT_PATH = "/fee-report";
const TRANSACTIONS_ROOT_PATH = "/transactions-manager/:transactionType";
const ERROR_TRANSLATIONS_ROOT_PATH = "/error-translations";
const ERROR_MAPPING_ROOT_PATH = "/error-mapping";
const OPERATORS_ROOT_PATH = "/operators";
const MERCHANTS_ROOT_PATH = "/merchants";

const QS_STRINGIFY_OPTIONS: IStringifyOptions = {
  addQueryPrefix: true,
};

// TODO: Use more concrete types for "searchParams" instead of Record<string, any>
export const ROUTES = {
  signIn: {
    path: "/sign-in",
    createURL: () => ROUTES.signIn.path,
  },
  emailVerification: {
    path: "/email-verification",
    createURL: () => ROUTES.emailVerification.path,
  },
  dashboard: {
    path: "/dashboard",
    createURL: () => ROUTES.dashboard.path,
  },
  transactionsManager: {
    path: TRANSACTIONS_ROOT_PATH,
    createURL: (
      transactionType = TransactionType.Deposit,
      searchParams?: Record<string, any>,
    ) => {
      const searchString = qs.stringify(searchParams, QS_STRINGIFY_OPTIONS);

      return `${ROUTES.transactionsManager.path.replace(
        ":transactionType",
        transactionType,
      )}${searchString}`;
    },
  },
  users: {
    path: USERS_ROOT_PATH,
    createURL: (searchString = "") => {
      return `${ROUTES.users.path}${searchString}`;
    },
  },
  usersCreate: {
    path: `${USERS_ROOT_PATH}/create`,
    createURL: (searchString = "") => {
      return `${ROUTES.usersCreate.path}${searchString}`;
    },
  },
  usersEdit: {
    path: `${USERS_ROOT_PATH}/:id/edit`,
    createURL: (id: Id, searchString = "") => {
      return `${ROUTES.usersEdit.path.replace(
        ":id",
        id.toString(),
      )}${searchString}`;
    },
  },
  feeReport: {
    path: FEE_REPORT_ROOT_PATH,
    createURL: () => ROUTES.feeReport.path,
  },
  errorTranslations: {
    path: ERROR_TRANSLATIONS_ROOT_PATH,
    createURL: () => ROUTES.errorTranslations.path,
  },
  errorTranslationsCreate: {
    path: `${ERROR_TRANSLATIONS_ROOT_PATH}/create`,
    createURL: () => ROUTES.errorTranslationsCreate.path,
  },
  errorTranslationsUpdate: {
    path: `${ERROR_TRANSLATIONS_ROOT_PATH}/:code/edit`,
    createURL: (code: string) => {
      return `${ROUTES.errorTranslationsUpdate.path.replace(
        ":code",
        code.toString(),
      )}`;
    },
  },
  errorMapping: {
    path: ERROR_MAPPING_ROOT_PATH,
    createURL: () => ROUTES.errorMapping.path,
  },
  operators: {
    path: OPERATORS_ROOT_PATH,
    createURL: () => ROUTES.operators.path,
  },
  merchants: {
    path: MERCHANTS_ROOT_PATH,
    createURL: (searchString = "") => {
      return `${ROUTES.merchants.path}${searchString}`;
    },
  },
  transactionsDetails: {
    path: `${TRANSACTIONS_ROOT_PATH}/:id`,
    createURL: (
      id: TransactionId,
      transactionType: TransactionType,
      searchParams?: Record<string, any>,
    ) => {
      const searchString = qs.stringify(searchParams, QS_STRINGIFY_OPTIONS);

      return `${ROUTES.transactionsDetails.path
        .replace(":id", id.toString())
        .replace(":transactionType", transactionType)}${searchString}`;
    },
  },
} as const;
