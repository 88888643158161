import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {handleError} from "@/utils/handleError";
import {PageContent} from "@/components/PageContent";
import {FeatureTitle} from "@/types/featureTitle";
import {
  ErrorMappingDataGridToolbar,
  ErrorMappingFiltersFormValues
} from "@/features/error-mapping/components/ErrorMappingDataGridToolbar/ErrorMappingDataGridToolbar";
import {ErrorMappingTable} from "@/features/error-mapping/components/ErrorMappingTable/ErrorMappingTable";
import {IErrorMappingData, IErrorMappingResponseItem} from "@/features/error-mapping/type";
import {useGetErrorMappingsQuery, useLazyGetErrorMappingQuery} from "@/features/error-mapping/errorMappingApi";
import {ErrorMappingCreateDialog} from "@/features/error-mapping/components/ErrorMappingCreateDialog";
import {ErrorMappingEditDialog} from "@/features/error-mapping/components/ErrorMappingEditDialog/ErrorMappingEditDialog";
import {LoadingOverlay} from "@/components/LoadingOverlay";

export const ErrorMappingPage: FC = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState<IErrorMappingData>();

  const {
    data: providersData,
    isFetching: isFetchingProviders,
    error: errorProviders
  } = useGetErrorMappingsQuery();

  const [
    getErrorMapping,
    {
      data: errorMappingData,
      isFetching: isFetchingErrorMapping,
      error: errorMappingError,
    },
  ] = useLazyGetErrorMappingQuery();

  const showEditDialog = useCallback((rowData?: IErrorMappingData) => {
    setSelectedRowData(rowData)
  }, []);

  const showCreateDialog = useCallback((isOpen = false) => {
    setOpenCreateDialog(isOpen);
  }, []);

  const providerOptions = useMemo(
    () => (
      providersData?.map((item: IErrorMappingResponseItem) => {
        return {
          label: item.pspName,
          value: item.pspName,
        }
      }) || []
    ),
    [providersData],
  );

  const handleFiltersChange = useCallback(
    (filters: ErrorMappingFiltersFormValues) => {
      getErrorMapping(filters)
    }, []);

  useEffect(() => {
    if (errorProviders) {
      handleError(errorProviders);
    }
  }, [errorProviders]);

  useEffect(() => {
    if (errorMappingError) {
      handleError(errorMappingError);
    }
  }, [errorMappingError]);

  return (
    <PageContent title={FeatureTitle.ErrorMapping}>
      <ErrorMappingDataGridToolbar
        filterOptions={providerOptions}
        isFetching={isFetchingProviders || isFetchingErrorMapping}
        showDialog={showCreateDialog}
        changeFilter={handleFiltersChange}/>
      <ErrorMappingTable isFetching={isFetchingErrorMapping} items={errorMappingData || []} showEditDialog={showEditDialog} />
      <ErrorMappingCreateDialog
        providersOptions={providerOptions}
        providerCodeOptions={[]}
        open={openCreateDialog}
        closeDialog={showCreateDialog}
        selectedPspName={errorMappingData ? errorMappingData[0].pspName : undefined}
      />
      <ErrorMappingEditDialog
        providersOptions={providerOptions}
        providerCodeOptions={errorMappingData || []}
        open={!!selectedRowData}
        closeDialog={showEditDialog}
        rowData={selectedRowData}
      />
      <LoadingOverlay open={isFetchingErrorMapping} />
    </PageContent>
  )
};
