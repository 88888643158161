import {FC, useCallback, useEffect, useId, useMemo} from "react";
import { useLocation } from "react-router-dom";
import { Button } from "@/components/Button";
import { ROUTES } from "@/routing/constants";
import * as Styled from "./UsersDataGridToolbar.styles";
import * as yup from "yup";
import {DefaultValues, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {FormTextField, MerchantsField, OperatorsAndMerchantsField} from "@/components/form";
import {useUser} from "@/hooks/useUser";
import {UserRole} from "@/types/user";
import {IUserFilterParams} from "@/features/users/types";
import {useGetUsersFiltersQuery} from "@/features/users/usersApi";
import {handleError} from "@/utils/handleError";

enum UsersFilterName {
  OperatorsAndMerchants = "operatorsAndMerchants",
  MerchantId = "merchantId",
  Search = "search",
}

const formSchema = yup
  .object()
  .shape(
    {
      [UsersFilterName.OperatorsAndMerchants]: yup.object().shape({
        operatorIds: yup.array().of(yup.number().required()),
        merchantIds: yup.array().of(yup.number().required()),
      }),
      [UsersFilterName.MerchantId]: yup
        .array()
        .of(yup.number().required()),
      [UsersFilterName.Search]: yup.string().trim(),
    }
  )
  .required();

export type UsersFiltersFormValues = yup.InferType<typeof formSchema>;
export type UsersFiltersFormDefaultValues = DefaultValues<UsersFiltersFormValues>;

const FIELD_SIZE = 'small';

interface IUsersDataGridToolbarProps {
  defaultFiltersValues: IUserFilterParams;
  onFilterChange: (value: IUserFilterParams) => void;
}

export const UsersDataGridToolbar: FC<IUsersDataGridToolbarProps> = ({
  defaultFiltersValues,
  onFilterChange
}) => {
  const location = useLocation();

  const {user} = useUser();

  const {withOperatorsFilter, withMerchantsFilter} = useMemo(() => {
    return {
      withOperatorsFilter: user?.role === UserRole.Admin,
      withMerchantsFilter: user?.role === UserRole.Operator,
    }
  }, [user]);

  const {
    data: filtersData,
    isFetching: isFetchingFilters,
    error: filtersFetchError,
  } =  useGetUsersFiltersQuery();

  const {merchants, operators} = useMemo(() => {
    return {
      merchants: filtersData?.operators?.length ? filtersData?.operators[0].merchants : [],
      operators: filtersData?.operators || [],
    }
  }, [filtersData]);

  const formId = useId();

  const { control, getValues, watch, reset} =
    useForm<UsersFiltersFormValues>({
      resolver: yupResolver<UsersFiltersFormValues>(formSchema),
      mode: "onBlur",
    });

  const search = watch(UsersFilterName.Search);

  const transformValuesForBackend = (): IUserFilterParams => {
    const values = getValues();
    console.log(values);
    return {
      search: values[UsersFilterName.Search],
      operatorIds: values[UsersFilterName.OperatorsAndMerchants]?.operatorIds || undefined,
      merchantIds: values[UsersFilterName.MerchantId] || values[UsersFilterName.OperatorsAndMerchants]?.merchantIds || undefined,
    }
  };

  const handleChangeFilters = useCallback(() => {
    onFilterChange(transformValuesForBackend());
  }, []);

  useEffect(() => {
    if (search) {
      handleChangeFilters();
    }
  }, [search]);

  useEffect(() => {
    const {operatorIds, merchantIds, search} = defaultFiltersValues;
    let value: UsersFiltersFormDefaultValues = {};
    if (withOperatorsFilter) {
      value[UsersFilterName.OperatorsAndMerchants] = {
        operatorIds: operatorIds?.length ? operatorIds.map(id => Number(id)) : [],
        merchantIds: merchantIds?.length ? merchantIds.map(id => Number(id)) : []
      }
    }
    if (withMerchantsFilter) {
      value[UsersFilterName.MerchantId] = merchantIds
    }
    console.log(value);
    reset({...value, search})
  }, [defaultFiltersValues]);

  useEffect(() => {
    if (filtersFetchError) {
      handleError(
        filtersFetchError,
        "Something went wrong during users filters fetching. Please try again.",
      );
    }
  }, [filtersFetchError]);

  return (
    <Styled.Root>
      <Styled.Form id={formId}>
        {withOperatorsFilter && (
          <OperatorsAndMerchantsField
            name={UsersFilterName.OperatorsAndMerchants}
            control={control}
            operators={operators}
            label="Operators/Merchants"
            disabled={!operators.length}
            readOnly={isFetchingFilters}
            size={FIELD_SIZE}
            onClose={handleChangeFilters}
          />
        )}
        {withMerchantsFilter && (
          <MerchantsField
            name={UsersFilterName.MerchantId}
            control={control}
            merchants={merchants}
            label="Merchants"
            disabled={!merchants.length}
            readOnly={isFetchingFilters}
            size={FIELD_SIZE}
            onClose={handleChangeFilters}
          />
        )}
        <FormTextField
          name={UsersFilterName.Search}
          control={control}
          label="Search"
          type="search"
          size={FIELD_SIZE}
        />
      </Styled.Form>
      <Button href={ROUTES.usersCreate.createURL(location.search)}>
        Create User
      </Button>
    </Styled.Root>
  );
};
