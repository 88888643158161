import {styled} from "@mui/material";

export const LinkField = styled("span")(({ theme }) => ({
  width: "100%",
  display: "inline-flex",
  color: theme.palette.success.main,
  cursor: "pointer",
  '&:hover': {
    textDecoration: "underline",
  }
}));
