export interface IErrorTranslationsResponseItem {
  code: string;
  en: string;
  sp: string;
  de: string;
}

export interface IErrorTranslationUpdateRequest {
  translations: IErrorTranslationItem;
}

export interface IErrorTranslationsDetailsResponse {
  code: string;
  translations: IErrorTranslationItem;
}

export interface IErrorTranslationItem {
  en: string;
  sp: string;
  de: string;
}

export enum ErrorTranslationFieldName {
  Code = "code",
  En = "en",
  De = "de",
  Sp = "sp"
}
