import {useCallback, useMemo} from "react";
import {toast} from "react-toastify";
import {useDeleteErrorMappingMutation} from "@/features/error-mapping/errorMappingApi";
import {useConfirmDialog} from "@/hooks/useConfirmDialog";
import {
  ErrorMappingActionTitle, IErrorMappingActionsItem,
  IErrorMappingActionsItemsData
} from "@/features/error-mapping/components/ErrorMappingActionButtons/type";

export const useErrorMappingActions = ({ data, showEditDialog }: IErrorMappingActionsItemsData) => {
  const [deleteErrorTranslation] = useDeleteErrorMappingMutation();

  const { showConfirmDialog } = useConfirmDialog();

  const handleShowConfirmDialog = useCallback(
    (message: string, onConfirm: () => void) => {
      showConfirmDialog({
        message: message,
        onConfirm,
      });
    },
    [showConfirmDialog],
  );

  const errorMappingActionsItems = useMemo(() => {
    const { pspName, code } = data;

    const itemsMap: Array<IErrorMappingActionsItem> = [
      {
        title: ErrorMappingActionTitle.EditErrorMapping,
        onClick: () => {
          showEditDialog(data);
        },
      },
      {
        title: ErrorMappingActionTitle.DeleteErrorMapping,
        onClick: () => {
          handleShowConfirmDialog(
            `Are you sure you want to delete error mapping for ${pspName} code ${code}?`,
            async () => {
              await deleteErrorTranslation({code, pspName}).unwrap();
              toast.success(`Error mapping for ${pspName} code ${code} has been deleted.`);
            },
          );
        },
      }
    ];

    return itemsMap;
  }, [
    data,
    handleShowConfirmDialog,
    deleteErrorTranslation,
  ]);

  return {
    errorMappingActionsItems,
  };
};
