import { useNavigate } from "react-router-dom";
import {useMemo} from "react";
import {
  IOperatorActionsItem,
  IOperatorActionsItemsData,
  OperatorActionTitle
} from "@/features/operators/components/OperatorActionButtons/type";
import {ROUTES} from "@/routing/constants";
import qs from "qs";

export const useOperatorActions = ({ data, showEditDialog }: IOperatorActionsItemsData) => {
  const navigate = useNavigate();

  const operatorActionsItems = useMemo(() => {

    const itemsMap: Array<IOperatorActionsItem> = [
      {
        title: OperatorActionTitle.EditOperator,
        onClick: () => {
          showEditDialog(data);
        },
      },
      {
        title: OperatorActionTitle.ViewMerchants,
        onClick: () => {
          navigate(ROUTES.merchants.createURL(qs.stringify({filters: {operatorId: data.id}}, {
            addQueryPrefix: true,
          })));
        },
      },
      {
        title: OperatorActionTitle.ViewUsers,
        onClick: () => {
          navigate(ROUTES.users.createURL(qs.stringify({
            filters: {
              operatorIds: [data.id],
              merchantIds: [
                ...data.merchantIds
              ]
            }
          }, {addQueryPrefix: true})));
        },
      }
    ];

    return itemsMap;
  }, [data]);

  return {
    operatorActionsItems,
  };
};
