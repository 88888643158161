import {FC} from "react";
import * as Styled from "@/features/operators/components/OperatorsDataGridToolbar/OperatorsDataGridToolbar.styles";
import {Button} from "@/components/Button";

interface IOperatorsDataGridToolbarProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
  showCreateDialog: (isOpen: boolean) => void;
}

export const OperatorsDataGridToolbar: FC<IOperatorsDataGridToolbarProps> = ({
  searchValue,
  onSearchChange,
  showCreateDialog
}) => {
  return (
    <Styled.Root>
      <Styled.SearchTextField value={searchValue} onChange={onSearchChange} />
      <Button onClick={() => {showCreateDialog(true)}}>
        Add New Operator
      </Button>
    </Styled.Root>
  );
};
