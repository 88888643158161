import {FC, useCallback, useEffect, useMemo} from "react";
import {useUpdateMerchantDetailsMutation, useGetMerchantDetailsQuery} from "@/features/merchants/merchantsApi";
import {toast} from "react-toastify";
import {IMerchantEditDialogProps} from "@/features/merchants/types";
import {
  MerchantDefaultValues,
  MerchantFormValues,
  MerchantsForm
} from "@/features/merchants/components/MerchantsForm/MerchantsForm";
import {LoadingOverlay} from "@/components/LoadingOverlay";
import {handleError} from "@/utils/handleError";

export const MerchantsEditDialog: FC<IMerchantEditDialogProps> = (
  { open, closeDialog, rowData }) => {
  const {
    isFetching: isFetchingMerchantDetails,
    data: merchantDetails,
    error: merchantDetailsFetchingError,
  } = useGetMerchantDetailsQuery({ id: rowData.id });

  const [updateMerchantDetails, { isLoading }] = useUpdateMerchantDetailsMutation();
  const handleSubmit = useCallback(
    async (data: MerchantFormValues) => {
      await updateMerchantDetails({...data, id: rowData.id}).unwrap();

      toast.success(`Merchant ${data.name} has been updated.`);
    },
    [updateMerchantDetails],
  );

  const defaultValues: MerchantDefaultValues = useMemo(() => {
    if (!merchantDetails) {
      return {};
    }

    const {
      name,
      secretKey,
      requireAuthCodeDeposit,
      requireAuthCodeWithdrawal,
      useMerchantTxnIDtoPSP,
      autoApprove,
      currencyCodes,
      countryCodes,
    } = merchantDetails;

    return {
      name,
      secretKey,
      requireAuthCodeDeposit,
      requireAuthCodeWithdrawal,
      useMerchantTxnIDtoPSP,
      autoApprove,
      currencyCodes,
      countryCodes,
    };
  }, [merchantDetails]);

  useEffect(() => {
    if (merchantDetailsFetchingError) {
      handleError(merchantDetailsFetchingError);
    }
  }, [merchantDetailsFetchingError]);


  return (
    <MerchantsForm
      title={`Edit Merchant ${rowData?.name || ''}`}
      submitButtonText="Update"
      isSubmitting={isLoading}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      closeDialog={closeDialog}
      open={open}
    >
      <LoadingOverlay open={isFetchingMerchantDetails} />
    </MerchantsForm>
  );
};
