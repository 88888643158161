export enum ApiTag {
  UsersList = "UsersList",
  User = "User",
  DepositTransactionsList = "DepositTransactionList",
  WithdrawalTransactionsList = "WithdrawalTransactionList",
  FeeReport = "FeeReports",
  ErrorTranslations = "ErrorTranslations",
  ErrorMapping = "ErrorMapping",
  Operator = "Operator",
  Merchant = "Merchant"
}
