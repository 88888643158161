import { useEffect, useMemo } from "react";
import { handleError } from "@/utils/handleError";
import { useLazyGetFiltersQuery } from "@/features/fee-report/feeReportApi";
import { convertArrayToSelectOptions } from "@/utils/convertToOptions";
import { TransactionType } from "@/types/transaction";
import { useGetEnumsQuery } from "@/features/app/appApiSlice";

export const useFeeReportFilters = () => {
  const {
    data: enumsData,
    isFetching: isFetchingEnums,
    error: enumsFetchError,
  } = useGetEnumsQuery();
  const [
    getFeeReportFilters,
    {
      data: feeReportFiltersData,
      isFetching: isFetchingFeeReportFilters,
      error: feeReportFiltersError,
    },
  ] = useLazyGetFiltersQuery();

  const {
    utcOffset: utcOffsetOptions = [],
  } = enumsData || {};

  const { isLiveFilter, operators = [], merchants = [] } = feeReportFiltersData || {};

  const {
    ppaNamesOptions,
    providersOptions,
    paymentMethodsOptions,
    currenciesOptions,
    transactionOptions
  } = useMemo(
    () => ({
      transactionOptions: convertArrayToSelectOptions(Object.values(TransactionType) || []),
      ppaNamesOptions: convertArrayToSelectOptions(feeReportFiltersData?.ppaNames || []),
      providersOptions: convertArrayToSelectOptions(feeReportFiltersData?.providers || []),
      paymentMethodsOptions: convertArrayToSelectOptions(
        feeReportFiltersData?.paymentMethods || [],
      ),
      currenciesOptions: convertArrayToSelectOptions(feeReportFiltersData?.currencies || []),
    }),
    [feeReportFiltersData],
  );

  useEffect(() => {
    if (feeReportFiltersError) {
      handleError(
        feeReportFiltersError,
        "Whoops! An error happen while fetching fee report filters. Please try again later.",
      );
    }
  }, [feeReportFiltersError]);

  return {
    isFetching: isFetchingFeeReportFilters,
    isLiveFilter,
    operators,
    merchants,
    utcOffsetOptions,
    ppaNamesOptions,
    providersOptions,
    paymentMethodsOptions,
    currenciesOptions,
    transactionOptions,
    getFiltersData: getFeeReportFilters,
  };
};
