import {FC, useMemo} from "react";
import {DataGrid, IDataGridColumns} from "@/components/DataGrid";
import {EMPTY_VALUE_FALLBACK} from "@/constants/common";
import {DashboardVolumeProps, IDashboardVolumeResponseItem} from "@/features/dashboard/type";

enum DashboardVolumeTableFields {
  Operator = 'operatorName',
  Merchant = 'merchantName',
  Deposits = 'depositSum',
  Withdrawals = 'withdrawalSum',
  NetCash = '',
  PPAName = 'ppaName',
  CurrencyCode = 'currencyCode'
}

enum DashboardVolumeTableLabels {
  Operator = 'Operator',
  Merchant = 'Merchant',
  Deposits = 'Deposits',
  Withdrawals = 'Withdrawals',
  NetCash = 'Net Cash',
}

const minWidth = 200;

const childrenKeys = 'perPpa';
const idRowKey = 'merchantId';

export const DashboardVolumeTable: FC<DashboardVolumeProps<IDashboardVolumeResponseItem>> = ({isFetching, items, className, title, isOnlyMerchant, isExpanded}) => {
  const columns: IDataGridColumns<any, DashboardVolumeTableFields> = [
    {
      field: DashboardVolumeTableFields.Operator,
      headerName: DashboardVolumeTableLabels.Operator,
      headCellSx: {
        minWidth
      },
      emptyValue: ' ',
    },
    {
      field: DashboardVolumeTableFields.Merchant,
      childrenFieldKey: DashboardVolumeTableFields.PPAName,
      headerName: DashboardVolumeTableLabels.Merchant,
      headCellSx: {
        minWidth
      },
    },
    {
      field: DashboardVolumeTableFields.Deposits,
      headerName: DashboardVolumeTableLabels.Deposits,
      headCellSx: {
        minWidth
      },
      renderCell: ({ rowData, parentRow }) => {
        return `${rowData[DashboardVolumeTableFields.Deposits]} ${rowData[DashboardVolumeTableFields.CurrencyCode] || parentRow[DashboardVolumeTableFields.CurrencyCode]}`
          || EMPTY_VALUE_FALLBACK;
      }
    },
    {
      field: DashboardVolumeTableFields.Withdrawals,
      headerName: DashboardVolumeTableLabels.Withdrawals,
      headCellSx: {
        minWidth
      },
      renderCell: ({ rowData, parentRow }) => {
        return `${rowData[DashboardVolumeTableFields.Withdrawals]} ${rowData[DashboardVolumeTableFields.CurrencyCode] || parentRow[DashboardVolumeTableFields.CurrencyCode]}` || EMPTY_VALUE_FALLBACK;
      }
    },
    //Todo ask about net cash column(how calculate value)
    /*{
      field: DashboardVolumeTableFields.NetCash,
      headerName: DashboardVolumeTableLabels.NetCash,
      headCellSx: {
        minWidth
      },
      renderCell: ({ rowData, parentRow }) => {
        const netCash = Number(rowData[DashboardVolumeTableFields.Deposits]) - Number(rowData[DashboardVolumeTableFields.Withdrawals]);
        return `${netCash} ${rowData[DashboardVolumeTableFields.CurrencyCode] || parentRow[DashboardVolumeTableFields.CurrencyCode]}` || EMPTY_VALUE_FALLBACK
      }
    },*/
  ];

  const updatedConfig = useMemo(() => {
    return columns.filter((item, index) => !isOnlyMerchant || index !== 0);
  }, [isOnlyMerchant]);

  return (
    <>
      <h3>{title}</h3>
      <DataGrid
        className={className}
        columns={updatedConfig}
        data={items}
        emptyMessage="Whoops! There're no volume data at the moment :("
        loading={{
          isLoadingTable: isFetching,
        }}
        childrenKeys={childrenKeys}
        idKey={idRowKey}
        isExpandedChildren={isExpanded}
      />
    </>
  );
};
