import { FC, useCallback } from "react";
import { Button } from "@/components/Button";
import { useLazyGetFileQuery } from "@/features/app/appApiSlice";
import { handleError } from "@/utils/handleError";
import { toast } from "react-toastify";
import { downloadFile } from "@/utils/downloadFile";
import { format } from "date-fns";
import {useLazyExportReportQuery} from "@/features/fee-report/feeReportApi";

interface IFeeReportExportButtonProps {
  filters: any;
  fileTitle: string;
}

export const FeeReportExportButton: FC<IFeeReportExportButtonProps> = ({
  filters, fileTitle
}) => {
  const [
    getExportReport,
    { isFetching: isFetchingReportExport, error: exportReportError },
  ] = useLazyExportReportQuery();

  const [getFile, { isFetching: isFetchingFile }] = useLazyGetFileQuery();

  const getFileName = useCallback(() => {
    return `PSP_fees_report_deposits_${format(
      new Date(),
      "yyyy-MM-dd_hh-mm-ss",
    )}`;
  }, [fileTitle]);

  const handleExport = useCallback(async () => {
    try {
      const exportData = await getExportReport(filters).unwrap();
      const file = await getFile(exportData).unwrap();
      downloadFile(file, getFileName(), { type: "text/csv" });

      toast.success("Your file is downloading.");
    } catch (error) {
      handleError(
        error,
        "Whoops! An error happen while exporting transaction fee. Please try again later.",
      );
    }
  }, [getFile, getFileName, getExportReport, filters]);

  return (
    <Button isLoading={isFetchingReportExport || isFetchingFile} onClick={handleExport}>
      Export to CSV
    </Button>
  );
};
