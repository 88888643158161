export const cleanFormValuesAfterUpdateOptions = (optionsByKey: any, formValues: any) => {
  return Object.fromEntries(Object.entries(formValues).map(([key, value]) => {
    const isArr = Array.isArray(value);
    if (!optionsByKey[key] || !value || (isArr && !value?.length)) {
      return [key, value];
    }

    if (!optionsByKey[key].length) {
      return [key, undefined];
    }

    if (Array.isArray(value)) {
      const optionValues = optionsByKey[key].map((e: any) => e.value);
      const existValues = value.filter((item: any) => optionValues.includes(item));
      return [key, (existValues || undefined)];
    }

    const elem = optionsByKey[key].find((item: any) => item.value === value);
    if (!elem) {
      return [key, undefined];
    }

    return [key, value];
  }))
};
