export enum DashboardFiltersFieldName {
  MerchantIds = "merchantIds",
  DateRange = "dateRange",
  FromDate = "fromDate",
  ToDate = "toDate",
  Currencies = "currencyCode",
  OperatorsAndMerchants = "operatorsAndMerchants",
  UtcOffset = "utcOffset",
  Type = "type",
  Expanded = "expanded",
}
