import {IActionsButtonItem} from "@/components/ActionsButton";
import {IOperator} from "@/types/operator";

export enum OperatorActionTitle {
  EditOperator = "Edit",
  ViewMerchants = "View Merchants",
  ViewUsers = "View Users",
}

export interface IOperatorActionsItemsData {
  data: IOperator,
  showEditDialog: (rowData: IOperator) => void
}

export interface IOperatorActionsItem extends IActionsButtonItem<OperatorActionTitle> {}
