import * as yup from "yup";
import {DefaultValues, useForm} from "react-hook-form";
import {Dialog, DialogActions, DialogContent, DialogProps, DialogTitle} from "@mui/material";
import {FC, useCallback, useEffect, useId} from "react";
import {useNavigate} from "react-router-dom";
import {yupResolver} from "@hookform/resolvers/yup";
import {handleError} from "@/utils/handleError";
import * as Styled
  from "@/features/operators/components/OperatorForm/OperatorFormStyles";
import {FormTextField} from "@/components/form";
import {Button} from "@/components/Button";

const FIELD_SIZE = "small";

export enum OperatorFieldName {
  Name = 'name',
}

const operatorFormSchema = yup
  .object({
    [OperatorFieldName.Name]: yup
      .string()
      .required()
      .trim()
      .label("Operator name"),
  })
  .required();


export type OperatorFormValues = yup.InferType<typeof operatorFormSchema>;
export type OperatorDefaultValues = DefaultValues<OperatorFormValues>;

interface IOperatorFormProps extends Omit<DialogProps, "onSubmit"> {
  title: string;
  submitButtonText: string;
  defaultValues: OperatorDefaultValues;
  isSubmitting?: boolean;
  onSubmit: (values: OperatorFormValues) => void;
  closeDialog: (param?: any) => void;
}

export const OperatorForm: FC<IOperatorFormProps> = ({
  title,
  submitButtonText,
  defaultValues,
  isSubmitting,
  onSubmit,
  closeDialog,
  ...restProps
}) => {
  const formId = useId();
  const navigate = useNavigate();

  const { control, handleSubmit, watch, reset, setValue } = useForm<OperatorFormValues>({
    resolver: yupResolver<OperatorFormValues>(operatorFormSchema),
    mode: "onBlur",
    defaultValues,
  });

  const handleClose = useCallback(() => {
    reset();
    closeDialog();
  }, [navigate]);

  const handleFormSubmit = useCallback(
    async (values: OperatorFormValues) => {
      try {
        await onSubmit(values);
        handleClose();
      } catch (error) {
        closeDialog();
        handleError(error);
      }
    },
    [onSubmit, handleClose],
  );

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Dialog fullWidth disableRestoreFocus {...restProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
          <Styled.Fields>
            <FormTextField
              name={OperatorFieldName.Name}
              control={control}
              label="Operator Name"
              size={FIELD_SIZE}
            />
          </Styled.Fields>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" disabled={isSubmitting} onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" form={formId} isLoading={isSubmitting}>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
