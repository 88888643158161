import {DataGrid, IDataGridColumns} from "@/components/DataGrid";
import {FC} from "react";
import {OperatorActionButtons} from "@/features/operators/components/OperatorActionButtons/OperatorsActionButtons";
import {IOperator} from "@/types/operator";
import {formatDate} from "@/utils/dataFormat/formatDate";

enum OperatorsTableFields {
  OperatorName = "name",
  OId = "id",
  Created = "createdAt",
  Updated = "updatedAt",
  Merchants= "merchantCount",
  Users = "userCount",
}

enum OperatorsTableLabels {
  OperatorName = "Operator Name",
  OId = "OID",
  Created = "Created",
  Updated = "Updated",
  Merchants = "Merchants",
  Users = "Users",
}

interface IOperatorsTableProps {
  className?: string;
  isFetching: boolean;
  items: Array<IOperator>;
  showEditDialog: (rowData: IOperator) => void;
}

export const OperatorsTable: FC<IOperatorsTableProps> = ({
  className,
  isFetching,
  items,
  showEditDialog
}) => {
  const columns: IDataGridColumns<IOperator, OperatorsTableFields> = [
    {
      field: OperatorsTableFields.OperatorName,
      headerName: OperatorsTableLabels.OperatorName,
      headCellSx: {
        minWidth: 240,
      },
    },
    {
      field: OperatorsTableFields.OId,
      headerName: OperatorsTableLabels.OId,
      headCellSx: {
        minWidth: 90
      },
    },
    {
      field: OperatorsTableFields.Created,
      headerName: OperatorsTableLabels.Created,
      headCellSx: {
        minWidth: 215,
      },
      renderCell: ({ rowData }) => formatDate(rowData[OperatorsTableFields.Created]),
    },
    {
      field: OperatorsTableFields.Updated,
      headerName: OperatorsTableLabels.Updated,
      headCellSx: {
        minWidth: 215,
      },
      renderCell: ({ rowData }) => formatDate(rowData[OperatorsTableFields.Updated]),
    },

    {
      field: OperatorsTableFields.Merchants,
      headerName: OperatorsTableLabels.Merchants,
      headCellSx: {
        minWidth: 90
      },
    },
    {
      field: OperatorsTableFields.Users,
      headerName: OperatorsTableLabels.Users,
      headCellSx: {
        minWidth: 90
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headCellSx: {
        width: "1px",
      },
      renderCell: ({ rowData }) => (<OperatorActionButtons data={rowData} showEditDialog={showEditDialog} />),
    },
  ];

  return (
    <DataGrid
      className={className}
      columns={columns}
      data={items}
      emptyMessage="Whoops! There're no operators at the moment :("
      loading={{
        isLoadingTable: isFetching,
      }}
      idKey={OperatorsTableFields.OId}
    />
  );
};
