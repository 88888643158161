import {DataGrid, IDataGridColumns} from "@/components/DataGrid";
import {FC} from "react";
import {ErrorMappingActionButtons} from "@/features/error-mapping/components/ErrorMappingActionButtons";
import {IErrorMappingData} from "@/features/error-mapping/type";

enum ErrorMappingTableFields {
  PspName = 'pspName',
  Code = "code",
  Message = "message",
  SystemError = 'error.code',
  SystemErrorMessage = 'error.translations.en',
}

enum ErrorMappingTableLabels {
  PspName = 'Provider Name',
  Code = "Provider Code",
  Message = "Provider Message",
  SystemError = 'System Error',
  SystemErrorMessage = 'System Error Message',
}

interface IErrorMappingTableProps {
  className?: string;
  isFetching: boolean;
  items: any;
  showEditDialog: (rowData: IErrorMappingData) => void;
}

export const ErrorMappingTable: FC<IErrorMappingTableProps> = ({
  className,
  isFetching,
  items,
  showEditDialog
}) => {
  const columns: IDataGridColumns<any, ErrorMappingTableFields> = [
    {
      field: ErrorMappingTableFields.Code,
      headerName: ErrorMappingTableLabels.Code,
      headCellSx: {
        minWidth: 200,
      },
    },
    {
      field: ErrorMappingTableFields.Message,
      headerName: ErrorMappingTableLabels.Message,
      headCellSx: {
        minWidth: 200,
      },
    },
    {
      field: ErrorMappingTableFields.SystemError,
      headerName: ErrorMappingTableLabels.SystemError,
      headCellSx: {
        minWidth: 200,
      },
    },
    {
      field: ErrorMappingTableFields.SystemErrorMessage,
      headerName: ErrorMappingTableLabels.SystemErrorMessage,
      headCellSx: {
        minWidth: 200,
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      headCellSx: {
        width: "1px",
      },
      renderCell: ({ rowData }) => <ErrorMappingActionButtons data={rowData} showEditDialog={showEditDialog} />,
    },
  ];

  return (
    <DataGrid
      className={className}
      columns={columns}
      data={items}
      emptyMessage="Whoops! There're no error mapping at the moment :("
      loading={{
        isLoadingTable: isFetching,
      }}
      idKey={ErrorMappingTableFields.Code}
    />
  );
};
