import {FC, useEffect, useId} from "react";
import { Button } from "@/components/Button";
import * as Styled from "./ErrorMappingDataGridToolbar.styles";
import {FormSelectField, FormSelectFieldOptions} from "@/components/form";
import * as yup from "yup";
import {ErrorMappingFilterName} from "@/features/error-mapping/type";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as StyledFilter from "@/styles/filter";

interface ErrorMappingDataGridToolbarProps {
  filterOptions: FormSelectFieldOptions;
  isFetching: boolean;
  showDialog: (isOpen: boolean) => void;
  changeFilter: (value: ErrorMappingFiltersFormValues) => void;
}

const formSchema = yup
  .object()
  .shape(
    {
      [ErrorMappingFilterName.PspName]: yup.string().required().trim(),
    }
  )
  .required();

export type ErrorMappingFiltersFormValues = yup.InferType<typeof formSchema>;

export const ErrorMappingDataGridToolbar: FC<ErrorMappingDataGridToolbarProps> = (
  {showDialog, filterOptions, isFetching, changeFilter}
  ) => {
  const formId = useId();

  const { control, getValues, setValue, watch} =
    useForm<ErrorMappingFiltersFormValues>({
      resolver: yupResolver<ErrorMappingFiltersFormValues>(formSchema),
      mode: "onBlur",
    });

  const pspName = watch(ErrorMappingFilterName.PspName);

  useEffect(() =>{
    if (filterOptions?.length) {
      setValue(ErrorMappingFilterName.PspName, filterOptions[0].value);
      changeFilter(getValues());
    }
  }, [filterOptions]);

  useEffect(() => {
    if (pspName) {
      changeFilter(getValues());
    }
  }, [pspName]);

  return (
    <Styled.ActionContainer>
      <StyledFilter.Form id={formId}>
        <FormSelectField
          name={ErrorMappingFilterName.PspName}
          label="Select PSP"
          control={control}
          options={filterOptions}
          disabled={!filterOptions.length}
          readOnly={isFetching}
          size="small"
          sx={{minWidth: '200px'}}
        />
      </StyledFilter.Form>
      <Button onClick={() => {
        showDialog(true);
      }}>
        Create New Mapping
      </Button>
    </Styled.ActionContainer>
  );
};
