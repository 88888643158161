import { useCallback, useMemo } from "react";
import { useConfirmDialog } from "@/hooks/useConfirmDialog";
import {
  useDeleteErrorTranslationMutation,
} from "@/features/system-errors/systemErrorsApi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "@/routing/constants";
import {
  ErrorTranslationActionTitle,
  IErrorTranslationActionsItem,
  IErrorTranslationActionsItemsData
} from "@/features/system-errors/components/ErrorTranslationActionButtons/type";

export const useErrorTranslationActions = ({ translation }: IErrorTranslationActionsItemsData) => {
  const navigate = useNavigate();

  const [deleteErrorTranslation] = useDeleteErrorTranslationMutation();

  const { showConfirmDialog } = useConfirmDialog();

  const handleShowConfirmDialog = useCallback(
    (message: string, onConfirm: () => void) => {
      showConfirmDialog({
        message: message,
        onConfirm,
      });
    },
    [showConfirmDialog],
  );

  const errorTranslationsActionsItems = useMemo(() => {
    const { code } = translation;

    const itemsMap: Array<IErrorTranslationActionsItem> = [
      {
        title: ErrorTranslationActionTitle.EditErrorTranslation,
        onClick: () => {
          navigate(ROUTES.errorTranslationsUpdate.createURL(code));
        },
      },
      {
        title: ErrorTranslationActionTitle.DeleteErrorTranslation,
        onClick: () => {
          handleShowConfirmDialog(
            `Are you sure you want to delete error ${code}?`,
            async () => {
              await deleteErrorTranslation({code}).unwrap();
              toast.success(`Error ${code} has been deleted.`);
            },
          );
        },
      }
    ];

    return itemsMap;
  }, [
    navigate,
    translation,
    handleShowConfirmDialog,
    deleteErrorTranslation,
  ]);

  return {
    errorTranslationsActionsItems,
  };
};
