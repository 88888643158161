import { useNavigate } from "react-router-dom";
import {useMemo} from "react";
import {ROUTES} from "@/routing/constants";
import {
  IMerchantsActionsItem,
  IMerchantsActionsItemsData,
  MerchantsActionsTitle
} from "@/features/merchants/components/MerchantsActionButtons/type";
import qs from "qs";

export const useMerchantsActions = ({ data, showEditDialog, showSettingsDialog }: IMerchantsActionsItemsData) => {
  const navigate = useNavigate();

  const merchantsActionsItems = useMemo(() => {

    const itemsMap: Array<IMerchantsActionsItem> = [
      {
        title: MerchantsActionsTitle.EditMerchant,
        onClick: () => {
          showEditDialog(data);
        },
      },
      {
        title: MerchantsActionsTitle.ViewMerchant,
        onClick: () => {
          showSettingsDialog(data);
        },
      },
      {
        title: MerchantsActionsTitle.ViewUsers,
        onClick: () => {
          navigate(ROUTES.users.createURL(qs.stringify({
            filters: {
              merchantIds: [data.id]
            }
          }, {addQueryPrefix: true})));
        },
      }
    ];

    return itemsMap;
  }, [data]);

  return {
    merchantsActionsItems,
  };
};
