import {FC, useMemo} from "react";
import {DataGrid, IDataGridColumns} from "@/components/DataGrid";
import {EMPTY_VALUE_FALLBACK} from "@/constants/common";
import {FeReportTreeTableProps} from "@/features/fee-report/components/FeeReportTreeTable/type";
import * as Styled from "@/styles/table";

enum FeeReportFields {
  Operator = 'operatorName',
  MerchantName = 'merchantName',
  PpaName = 'ppaName',
  Provider = 'providerName',
  PaymentMethod = 'paymentMethod',
  ConfirmedTotalFee = 'confirmedTotal',
  DeclinedTotalFee = 'declinedTotal',
  Currency = "currency",
}

enum FeeReportLabels {
  Operator = 'Operator',
  MerchantName = 'Merchant',
  PpaName = 'PPA name',
  Provider = 'Provider',
  PaymentMethod = 'Payment Method',
  ConfirmedTotalFee = 'Total fees for Confirmed transactions',
  DeclinedTotalFee = 'Total fees for Declined transactions'
}

const minWidth = 200;

const childrenKeys = 'collapsedRows';
const idRowKey = 'merchantId';

export const FeeReportTreeTable: FC<FeReportTreeTableProps> = ({isOnlyMerchant, className, isFetching, items, openDrillDownAction, title}) => {
  const columns: IDataGridColumns<any, FeeReportFields> = [
    {
      field: FeeReportFields.Operator,
      headerName: FeeReportLabels.Operator,
      headCellSx: {
        minWidth
      },
      emptyValue: ' ',
    },
    {
      field: FeeReportFields.MerchantName,
      headerName: FeeReportLabels.MerchantName,
      headCellSx: {
        minWidth
      },
      emptyValue: ' '
    },
    {
      field: FeeReportFields.PpaName,
      headerName: FeeReportLabels.PpaName,
      headCellSx: {
        minWidth: 240
      },
    },
    {
      field: FeeReportFields.Provider,
      headerName: FeeReportLabels.Provider,
      headCellSx: {
        minWidth
      },
    },
    {
      field: FeeReportFields.PaymentMethod,
      headerName: FeeReportLabels.PaymentMethod,
      headCellSx: {
        minWidth
      },
    },
    {
      field: FeeReportFields.ConfirmedTotalFee,
      headerName: FeeReportLabels.ConfirmedTotalFee,
      headCellSx: {
        minWidth,
        whiteSpace: "wrap",
      },
      renderCell: ({ rowData, parentRow }) => (
        <Styled.LinkField onClick={() => openDrillDownAction(rowData[FeeReportFields.Operator] ? rowData : {...rowData, merchantId: parentRow.merchantId})}>
          {`${rowData[FeeReportFields.ConfirmedTotalFee]} ${rowData[FeeReportFields.Currency] || parentRow[FeeReportFields.Currency]}` || EMPTY_VALUE_FALLBACK}
        </Styled.LinkField>
      )
    },
    {
      field: FeeReportFields.DeclinedTotalFee,
      headerName: FeeReportLabels.DeclinedTotalFee,
      headCellSx: {
        minWidth,
        whiteSpace: "wrap",
      },
      renderCell: ({ rowData, parentRow }) => (
        <Styled.LinkField onClick={() => openDrillDownAction(rowData[FeeReportFields.Operator] ? rowData : {...rowData, merchantId: parentRow.merchantId}, false)}>
          {`${rowData[FeeReportFields.DeclinedTotalFee]} ${rowData[FeeReportFields.Currency] || parentRow[FeeReportFields.Currency]}` || EMPTY_VALUE_FALLBACK}
        </Styled.LinkField>
      )
    }
  ];

  const updatedConfig = useMemo(() => {
    return columns.filter((item, index) => !isOnlyMerchant || index !== 0);
  }, [isOnlyMerchant]);

  return (
    <>
      <h3>{title}</h3>
      <DataGrid
        className={className}
        columns={updatedConfig}
        data={items}
        emptyMessage="Whoops! There're no reports at the moment :("
        loading={{
          isLoadingTable: isFetching,
        }}
        childrenKeys={childrenKeys}
        idKey={idRowKey}
      />
    </>
  );
};
