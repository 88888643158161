import {FC, useCallback} from "react";
import {useCreateErrorTranslationsMutation} from "@/features/system-errors/systemErrorsApi";
import {toast} from "react-toastify";
import {
  ErrorTranslationDialog,
  ErrorTranslationFormValues
} from "@/features/system-errors/components/ErrorTranslationFormDialog";

export const ErrorTranslationCreateDialog: FC<{open: boolean}> = ({ open }) => {
  const [createErrorTranslation, { isLoading }] = useCreateErrorTranslationsMutation();
  const handleSubmit = useCallback(
    async ({
             code,
             en,
             de,
             sp
           }: ErrorTranslationFormValues) => {
      await createErrorTranslation({
        code,
        translations: {
          en,
          de,
          sp
        }
      }).unwrap();

      toast.success(`Error ${code} has been created.`);
    },
    [createErrorTranslation],
  );
  return (
    <ErrorTranslationDialog
      title={`Create Error`}
      submitButtonText="Create"
      isSubmitting={isLoading}
      disabledFields={[]}
      defaultValues={{}}
      onSubmit={handleSubmit}
      open={open}
    />
  );
};
