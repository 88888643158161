import {FC, useCallback, useEffect, useMemo} from "react";
import {useGetMerchantDetailsQuery, useUpdateMerchantSettingsMutation} from "@/features/merchants/merchantsApi";
import {toast} from "react-toastify";
import {
  IMerchantSettingsDialogProps,
  IMerchantSettingsRequest,
  MerchantSettingsTimes
} from "@/features/merchants/types";
import {LoadingOverlay} from "@/components/LoadingOverlay";
import {handleError} from "@/utils/handleError";
import {
  MerchantSettingsDefaultValues,
  MerchantsSettingsForm
} from "@/features/merchants/components/MerchantsSettingsForm/MerchantsSettingsForm";

export const MerchantsSettingsDialog: FC<IMerchantSettingsDialogProps> = (
  { open, closeDialog, rowData }) => {
  const {
    isFetching: isFetchingMerchantDetails,
    data: merchantDetails,
    error: merchantDetailsFetchingError,
  } = useGetMerchantDetailsQuery({ id: rowData.id });

  const [updateMerchantSettings, { isLoading }] = useUpdateMerchantSettingsMutation();
  const handleSubmit = useCallback(
    async (data: IMerchantSettingsRequest) => {
      await updateMerchantSettings({...data, id: rowData.id}).unwrap();

      toast.success(`Merchant settings ${rowData.name} has been updated.`);
    },
    [updateMerchantSettings],
  );

  const getIntervalAndTimeValues = useCallback((interval: number) => {
    return [MerchantSettingsTimes.Hour, MerchantSettingsTimes.Min].reduce((acc, cur, index, arr) => {
      const calcVal = interval/cur;

      if (!calcVal.toString().includes('.')) {
        arr.splice(1);
        return {timeOption: cur, value: calcVal}
      }
      return {timeOption: MerchantSettingsTimes.Sec, value: interval};
    }, {timeOption: MerchantSettingsTimes.Sec, value: interval})
  }, []);

  const defaultValues: MerchantSettingsDefaultValues = useMemo(() => {
    if (!merchantDetails) {
      return {};
    }

    const {
      callbackRetryType,
      callbackRetryTimeInterval: callbackRetryTimeIntervalDetails,
      callbackRetryTimeStep: callbackRetryTimeStepDetails,
      callbackRetryTimeStepMultiplier,
      callbackRetries,
    } = merchantDetails;

    const {timeOption: callbackRetryTimeStepParam, value: callbackRetryTimeStep} = getIntervalAndTimeValues(callbackRetryTimeStepDetails);
    const {timeOption: callbackRetryTimeIntervalParam, value: callbackRetryTimeInterval} = getIntervalAndTimeValues(callbackRetryTimeIntervalDetails);

    return {
      callbackRetryType,
      callbackRetryTimeInterval,
      callbackRetryTimeStep,
      callbackRetryTimeStepParam,
      callbackRetryTimeIntervalParam,
      callbackRetryTimeStepMultiplier,
      callbackRetries,
    };

  }, [merchantDetails]);

  useEffect(() => {
    if (merchantDetailsFetchingError) {
      handleError(merchantDetailsFetchingError);
    }
  }, [merchantDetailsFetchingError]);


  return (
    <MerchantsSettingsForm
      title={`Merchant settings ${rowData?.name || ''}`}
      submitButtonText="Update"
      isSubmitting={isLoading}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      closeDialog={closeDialog}
      open={open}
    >
  <LoadingOverlay open={isFetchingMerchantDetails} />
  </MerchantsSettingsForm>
);
};
