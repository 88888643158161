import {FC, useCallback, useEffect, useState} from "react";
import {PageContent} from "@/components/PageContent";
import {FeatureTitle} from "@/types/featureTitle";
import {useLazyGetDashboardCountsQuery, useLazyGetDashboardVolumesQuery} from "@/features/dashboard/dashboardApi";
import {handleError} from "@/utils/handleError";
import {DashboardFilters} from "@/features/dashboard/components/DashboardFilters/DashboardFilters";
import {DashboardVolumeTable} from "@/features/dashboard/components/DashboardVolumeTable/DashboardVolumeTable";
import {DashboardCountTable} from "@/features/dashboard/components/DashboardCountTable/DashboardCountTable";
import {useUser} from "@/hooks/useUser";
import {DashboardFilterTypes, IDashboardFormFilter} from "@/features/dashboard/type";

export const DashboardPage: FC = () => {
  const [appliedFilters, setAppliedFilters] = useState<IDashboardFormFilter>();

  const { permissions } = useUser();
  const {
    feeReport: { withAdminFilter },
  } = permissions;

  const [
    getDashboardVolumesData,
    {
      data: dashboardVolumesData,
      isFetching: isFetchingDashboardVolumesData,
      error: dashboardDataVolumesError,
    },
  ] = useLazyGetDashboardVolumesQuery();

  const [
    getDashboardCountsData,
    {
      data: dashboardCountsData,
      isFetching: isFetchingDashboardCountsData,
      error: dashboardDataCountsError,
    },
  ] = useLazyGetDashboardCountsQuery();

  const handleFiltersChange = useCallback(
    (filters: any) => {
      setAppliedFilters(filters);
      getDashboardVolumesData(filters);
      getDashboardCountsData(filters);
    }, [getDashboardVolumesData, getDashboardCountsData]);

  const handleClearAllFilters = useCallback((filters: any) => {
    handleFiltersChange(filters);
  }, [handleFiltersChange]);


  useEffect(() => {
    if (dashboardDataVolumesError) {
      handleError(dashboardDataVolumesError);
    }
  }, [dashboardDataVolumesError]);

  useEffect(() => {
    if (dashboardDataCountsError) {
      handleError(dashboardDataCountsError);
    }
  }, [dashboardDataCountsError]);

  return (
    <PageContent title={FeatureTitle.Dashboard}>
      <DashboardFilters onClearAllClick={handleClearAllFilters} onApply={handleFiltersChange} />
      {(!appliedFilters?.type?.length || appliedFilters?.type.includes(DashboardFilterTypes.Volume)) && <DashboardVolumeTable
          title="Volume"
          isFetching={isFetchingDashboardVolumesData}
          items={dashboardVolumesData || []}
          isOnlyMerchant={!withAdminFilter}
          isExpanded={!!appliedFilters?.expanded}
        />}
      {(!appliedFilters?.type?.length || appliedFilters?.type.includes(DashboardFilterTypes.Count)) && <DashboardCountTable
          title="Count"
          isFetching={isFetchingDashboardCountsData}
          items={dashboardCountsData || []}
          isOnlyMerchant={!withAdminFilter}
          isExpanded={!!appliedFilters?.expanded}
        />}
    </PageContent>
  );
};
