import {api} from "@/store/api";
import {ApiTag} from "@/types/api";
import {IErrorMappingCreateRequest, IErrorMappingData, IErrorMappingResponseItem} from "@/features/error-mapping/type";

type PspName = string;

const URL_PREFIX = "/error-psp-translations";

const errorMappingApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getErrorMappings: builder.query<Array<IErrorMappingResponseItem>, void>({
      query: () => ({
        url: `${URL_PREFIX}`,
        method: "GET",
      }),
    }),
    createErrorMapping: builder.mutation<IErrorMappingData, IErrorMappingCreateRequest>({
      query: (body) => ({
        url: `${URL_PREFIX}`,
        method: "POST",
        body,
      }),
      invalidatesTags: [ApiTag.ErrorMapping],
    }),
    getErrorMapping: builder.query<Array<IErrorMappingData>, { pspName: PspName }>({
      query: ({ pspName }) => ({
        url: `${URL_PREFIX}/${pspName}`,
      }),
      providesTags: [ApiTag.ErrorMapping],
    }),
    updateErrorMappingDetails: builder.mutation<IErrorMappingData, IErrorMappingCreateRequest>({
      query: (data) => ({
        url: `${URL_PREFIX}/${data.pspName}/${data.pspCode}`,
        method: "PATCH",
        body: data,
      }),
      invalidatesTags: [ApiTag.ErrorMapping],
    }),
    deleteErrorMapping: builder.mutation<void, { code: string, pspName: PspName }>({
      query: ({ code, pspName }) => ({
        url: `${URL_PREFIX}/${pspName}/${code}`,
        method: "DELETE",
      }),
      invalidatesTags: [ApiTag.ErrorMapping],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetErrorMappingsQuery,
  useCreateErrorMappingMutation,
  useLazyGetErrorMappingQuery,
  useUpdateErrorMappingDetailsMutation,
  useDeleteErrorMappingMutation,
} = errorMappingApi;

