import {FC, useCallback, useEffect, useState} from "react";
import {PageContent} from "@/components/PageContent";
import {FeatureTitle} from "@/types/featureTitle";
import {OperatorsTable} from "@/features/operators/components/OperatorsTable/OperatorsTable";
import {useSearchParams} from "@/hooks/useSearchParams";
import {useDebouncedValue} from "@/hooks/useDebouncedValue";
import {IGetOperatorsRequestParams} from "@/features/operators/types";
import {OperatorsDataGridToolbar} from "@/features/operators/components/OperatorsDataGridToolbar/OperatorsDataGridToolbar";
import {OperatorCreateDialog} from "@/features/operators/components/OperatorCreateDialog/OperatorCreateDialog";
import {OperatorEditDialog} from "@/features/operators/components/OperatorEditDialog/OperatorEditDialog";
import {handleError} from "@/utils/handleError";
import {useGetOperatorsQuery} from "@/features/operators/operatorsApi";
import {IOperator} from "@/types/operator";

export const OperatorsPage: FC = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<IOperator>();

  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<IGetOperatorsRequestParams>();
  const debouncedParsedSearchParams = useDebouncedValue(parsedSearchParams);

  const { data, isFetching, error } = useGetOperatorsQuery({
    ...debouncedParsedSearchParams,
  });

  const { items = [] } = data || {};

  const handleSearchChange = useCallback(
    (search: string) => {
      mergeSearchParams({
        filters: {
          search: search || undefined,
        },
      });
    },
    [mergeSearchParams],
  );

  const showEditDialog = useCallback((rowData?: IOperator) => {
    setSelectedRowData(rowData)
  }, []);

  const showCreateDialog = useCallback((isOpen = false) => {
    setOpenCreateDialog(isOpen);
  }, []);

  useEffect(() => {
    if (error) {
      handleError(error);
    }
  }, [error]);

  return (
    <PageContent title={FeatureTitle.Operators}>
      <OperatorsDataGridToolbar
        searchValue={parsedSearchParams?.filters?.search || ""}
        onSearchChange={handleSearchChange}
        showCreateDialog={showCreateDialog} />
      <OperatorsTable isFetching={isFetching} items={items} showEditDialog={showEditDialog} />
      <OperatorCreateDialog
        open={openCreateDialog}
        closeDialog={showCreateDialog}
      />
      <OperatorEditDialog
        open={!!selectedRowData}
        closeDialog={showEditDialog}
        rowData={selectedRowData}
      />
    </PageContent>
  );
};
