import {styled} from "@mui/material";

export const Root = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
`;

export const Form = styled("form")`
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: 1fr 1fr;
  padding-top: 1rem;
  margin-top: -1rem;
`;
