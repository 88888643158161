import {FC, useCallback, useEffect, useState} from "react";
import {
  Calculate as CalculateIcon,
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Group as GroupIcon,
  Menu as MenuIcon,
  SyncAlt as SyncAltIcon,
  Settings as SettingsIcon,
  ErrorOutline as ErrorOutlineIcon,
  TranslateOutlined as TranslateOutlinedIcon,
  Tune as TuneIcon,
  Work as WorkIcon,
  BusinessCenter as BusinessCenterIcon,
} from "@mui/icons-material";
import {Collapse, Divider, IconButton, List, ListItemText} from "@mui/material";
import {useUser} from "@/hooks/useUser";
import {FeatureTitle} from "@/types/featureTitle";
import {ROUTES} from "@/routing/constants";
import {ISidebarItem} from "@/components/Sidebar/types";
import * as Styled from "./Sidebar.styles";

interface ISidebarProps {
  isOpen: boolean;
  onToggleOpen: () => void;
}

export const Sidebar: FC<ISidebarProps> = ({ isOpen, onToggleOpen }) => {
  const { permissions } = useUser();
  const [sidebarItems, setSidebarItems] = useState<ISidebarItem[]>([]);

  const getSideBarConfig = () => {
    const result: ISidebarItem[] = [
      {
        title: FeatureTitle.Dashboard,
        url: ROUTES.dashboard.createURL(),
        Icon: DashboardIcon,
      },
      {
        title: FeatureTitle.TransactionManager,
        url: ROUTES.transactionsManager.createURL(),
        Icon: SyncAltIcon,
      },
    ];

    if (permissions.users) {
      result.push({
        title: FeatureTitle.UserManagement,
        url: ROUTES.users.createURL(),
        Icon: GroupIcon,
      });
    }

    result.push({
      title: FeatureTitle.FeeReport,
      url: ROUTES.feeReport.createURL(),
      Icon: CalculateIcon,
    });

    if (permissions.operators || permissions.merchants) {
      const configurations: ISidebarItem = {
        title: FeatureTitle.Configurations,
        url: '',
        Icon: TuneIcon,
        isCollapsed: false,
        children: []
      };

      if (permissions.operators) {
        configurations.children?.push(
          {
            title: FeatureTitle.Operators,
            url: ROUTES.operators.createURL(),
            Icon: WorkIcon,
          }
        )
      }

      if (permissions.merchants) {
        configurations.children?.push(
          {
            title: FeatureTitle.Merchants,
            url: ROUTES.merchants.createURL(),
            Icon: BusinessCenterIcon,
          }
        )
      }

      result.push(configurations);
    }

    if (permissions.settings) {
      result.push({
        title: FeatureTitle.Settings,
        url: '',
        Icon: SettingsIcon,
        isCollapsed: false,
        children: [
          {
            title: FeatureTitle.SystemErrors,
            url: ROUTES.errorTranslations.createURL(),
            Icon: TranslateOutlinedIcon,
          },
          {
            title: FeatureTitle.ErrorMapping,
            url: ROUTES.errorMapping.createURL(),
            Icon: ErrorOutlineIcon,
          },
        ]
      });
    }

    return result;
  };

  const updateSideBarConfig = (index: number): void => {
    setSidebarItems(sidebarItems.map((conf, i) => i === index ?
      ({...conf, isCollapsed: !conf.isCollapsed}) : conf));
  };

  const handleClick = (index: number) => {
   updateSideBarConfig(index);
  };

  const renderSidebarItem = useCallback(
    ({ title, Icon, isOpen}: any) => (
      <>
        <Styled.ListItemIcon $isOpen={isOpen}>
          {<Icon />}
        </Styled.ListItemIcon>
        {isOpen && <ListItemText primary={title} />}
      </>
    ),
    [],
  );

  useEffect(() => {
    setSidebarItems(getSideBarConfig());
  }, [permissions]);

  return (
    <Styled.Drawer variant="permanent" $isOpen={isOpen}>
      <Styled.DrawerHeader>
        <IconButton onClick={onToggleOpen}>
          {isOpen ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </Styled.DrawerHeader>
      <Divider />
      <List>
        {sidebarItems.map(({ title, url, Icon, isCollapsed, children }, index: number) => (
          <>
            <Styled.ListItem key={title} disablePadding>
              {children?.length ?
                <Styled.ListItemButton onClick={() => handleClick(index)}>
                  {renderSidebarItem({title, Icon, isOpen})}
                  {isCollapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </Styled.ListItemButton> :
                <Styled.Link to={url}>
                  {renderSidebarItem({title, Icon, isOpen})}
                </Styled.Link>
              }
            </Styled.ListItem>
            {children?.length && <Collapse in={isCollapsed} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ pl: 1 }}>
                {children.map(({ title, url, Icon}) => (
                  <Styled.ListItem key={title} disablePadding>
                    <Styled.Link to={url}>
                      {renderSidebarItem({title, Icon, isOpen})}
                    </Styled.Link>
                  </Styled.ListItem>))}
              </List>
            </Collapse>}
          </>
        ))}
      </List>
      <Divider />
    </Styled.Drawer>
  );
};
