import {ReactNode, useCallback, useEffect, useState} from "react";
import { ISortData, SortOrder } from "@/types/base";
import { EMPTY_VALUE_FALLBACK } from "@/constants/common";
import {
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableProps,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import { TableEmptyMessage } from "@/components/TableEmptyMessage";
import { dataGridClasses } from "./constants";
import {
  IUseDataGridScrollData,
  useDataGridScroll,
} from "./hooks/useDataGridScroll";
import {
  IDataGridColumn,
  IDataGridColumns,
  IDataGridLoadingData,
  IDataGridPinnedColumns,
} from "./types";
import * as Styled from "./DataGrid.styles";
import {ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon} from '@mui/icons-material';

const DEFAULT_SKELETON_ROWS_COUNT = 5;

export interface IDataGridProps<TEntity, TSortKey = string> {
  className?: string;
  columns: IDataGridColumns<TEntity, TSortKey>;
  data: TEntity[];
  emptyMessage: ReactNode;
  sortData?: ISortData<TSortKey>;
  tableFooter?: ReactNode;
  loading?: IDataGridLoadingData;
  pinnedColumns?: IDataGridPinnedColumns;
  size?: TableProps["size"];
  getRowId?: (rowData: TEntity) => string | number;
  getRowClassName?: (rowData: TEntity) => string;
  onSortChange?: (sortData?: ISortData<TSortKey>) => void;
  onScroll?: IUseDataGridScrollData["onScroll"];
  childrenKeys?: string;
  idKey?: string;
  isExpandedChildren?: boolean;
}

type IDefaultEntityType = Record<string, any>;

const getDefaultRowId = (rowData: IDefaultEntityType, key = 'id') => rowData[key];

export const DataGrid = <TEntity extends IDefaultEntityType, TSortKey>({
  className,
  columns,
  data,
  sortData,
  emptyMessage,
  tableFooter,
  loading,
  pinnedColumns,
  size = "small",
  getRowId = getDefaultRowId,
  getRowClassName,
  onSortChange,
  onScroll,
  childrenKeys,
  isExpandedChildren,
  idKey = 'id'
}: IDataGridProps<TEntity, TSortKey>) => {
  const [collapsedRows, setCollapsedRows] = useState<any>({});
  const { column: sortColumn, order: sortOrder } = sortData || {};

  const { tableContainerRef, scrollData, handleScrollChange } =
    useDataGridScroll({ onScroll });

  const {
    isLoadingTable = false,
    skeletonRowsCount = DEFAULT_SKELETON_ROWS_COUNT,
    skeletonProps,
  } = loading || {};

  const shouldShowEmptyMessage = data.length === 0 && !isLoadingTable;

  const createSortHandler = useCallback(
    (sortKey: TSortKey) => () => {
      if (!onSortChange) {
        return;
      }

      switch (true) {
        case sortColumn !== sortKey:
          return onSortChange({ column: sortKey, order: SortOrder.Asc });
        case sortOrder === SortOrder.Asc:
          return onSortChange({ column: sortKey, order: SortOrder.Desc });
        default:
          onSortChange();
      }
    },
    [sortColumn, sortOrder, onSortChange],
  );

  const handleRenderHeaderRowCell = useCallback(
    (column: IDataGridColumn<TEntity, TSortKey>) => {
      const { headerName, sortKey } = column;

      if (!sortKey) {
        return headerName;
      }

      return (
        <TableSortLabel
          active={sortColumn === sortKey}
          direction={sortOrder}
          onClick={createSortHandler(sortKey)}
        >
          {headerName}
        </TableSortLabel>
      );
    },
    [sortColumn, sortOrder, createSortHandler],
  );

  const valueFromPointStr = useCallback(
    (row: any, childrenKeys: string): any => {
      return childrenKeys.split('.').reduce((previousValue, currentValue, index, arr) => {
        if (previousValue[currentValue] === undefined) {
          arr.splice(1);
          return;
        }
        return previousValue[currentValue]
      }, row);
    }, []
  );

  const childrenPath = useCallback(
    (row: any, childrenKeys: string): Array<any> => {
      return (childrenKeys.split('.').reduce((previousValue, currentValue) => {
        return previousValue[currentValue]
      }, row) as Array<any>) || [];
    }, []
  );

  const handleRenderBodyRowCell = useCallback(
    (column: IDataGridColumn<TEntity, TSortKey>, row: TEntity, isChildrenRow = false, parentRow?: TEntity) => {
      const { field, renderCell, emptyValue, childrenFieldKey } = column;

      if (renderCell) {
        return renderCell({ rowData: row, parentRow });
      }

      if (column.field.includes('.')) {
        return valueFromPointStr(row, column.field) || emptyValue || EMPTY_VALUE_FALLBACK;
      }

      return (isChildrenRow && childrenFieldKey ? row[childrenFieldKey] : row[field]) || emptyValue || EMPTY_VALUE_FALLBACK;
    },
    [],
  );

  const handleRenderSkeletonLoadingRows = useCallback(() => {
    return Array.from(Array(skeletonRowsCount).keys()).map((index) => (
      <TableRow key={index}>
        {columns.map((column) => {
          const { field, headerName } = column;

          return (
            <TableCell key={field + headerName}>
              <Skeleton {...skeletonProps} />
            </TableCell>
          );
        })}
      </TableRow>
    ));
  }, [skeletonRowsCount, skeletonProps, columns]);

  const getCellClassName = useCallback(
    (field: string) => {
      switch (true) {
        case pinnedColumns?.left?.includes(field):
          return dataGridClasses.cellStickyLeft;
        case pinnedColumns?.right?.includes(field):
          return dataGridClasses.cellStickyRight;
        default:
          return undefined;
      }
    },
    [pinnedColumns],
  );

  const collapseTreeRows = useCallback(
    (row: any, isCollapsed = true) => {
      const rowId = getDefaultRowId(row, idKey).toString();
      setCollapsedRows({...collapsedRows, [rowId]: isCollapsed});
    }, [collapsedRows]
  );

  useEffect(() => {
    if (!!childrenKeys) {
      const collapsedObj = data.reduce((acc: any, cur: any) => {
        const rowId = getDefaultRowId(cur, idKey).toString();
        acc[rowId] = isExpandedChildren;
        return acc;
      }, {});
      setCollapsedRows(collapsedObj);
    }
  }, [isExpandedChildren]);

  return (
    <Styled.TableContainer
      className={className}
      ref={tableContainerRef}
      $isFullyScrolledToLeft={scrollData.edgeOffset.left <= 0}
      $isFullyScrolledToRight={scrollData.edgeOffset.right <= 0}
      onScroll={handleScrollChange}
    >
      <Styled.Table stickyHeader size={size}>
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              const { field, headerName, sortKey, headCellSx } = column;

              return (
                <Styled.HeadCell
                  key={field + headerName}
                  className={getCellClassName(field)}
                  variant="head"
                  sortDirection={sortColumn === sortKey && sortOrder}
                  sx={headCellSx}
                >
                  {handleRenderHeaderRowCell(column)}
                </Styled.HeadCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {isLoadingTable
            ? handleRenderSkeletonLoadingRows()
            : data.map((row, index) => {
                const rowClassName = getRowClassName
                  ? getRowClassName(row)
                  : undefined;

                return <>
                  <TableRow
                    className={rowClassName}
                    key={getRowId(row) || index}
                    hover
                  >
                    {columns.map((column, columnIndex) => {
                      const { field, headerName, bodyCellSx } = column;

                      return (
                        <TableCell
                          key={field + headerName}
                          className={getCellClassName(field)}
                          sx={bodyCellSx}
                        >
                          {!!childrenKeys && columnIndex === 0 ?
                            <Styled.ExpendedCellWrapper>
                              {(collapsedRows[getDefaultRowId(row, idKey)] ?
                                <ExpandLessIcon onClick={() => collapseTreeRows(row, false)}/> :
                                <ExpandMoreIcon
                                  onClick={() => collapseTreeRows(row)}/>)}{handleRenderBodyRowCell(column, row)}</Styled.ExpendedCellWrapper> :
                            handleRenderBodyRowCell(column, row)
                          }
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  {!!childrenKeys && collapsedRows[getDefaultRowId(row, idKey)] && childrenPath(row, childrenKeys).map((childrenRow, childrenIndex) => {
                    return (<TableRow
                      className={rowClassName}
                      key={(index + childrenIndex.toString())}
                      hover
                    >
                      {columns.map((column, childrenColumnIndex: number) => {
                        const { field, headerName, bodyCellSx } = column;

                        return (
                          <Styled.ChildrenCell
                            key={field + headerName + childrenColumnIndex}
                            className={getCellClassName(field)}
                            sx={{...bodyCellSx}}
                          >
                            {handleRenderBodyRowCell(column, childrenRow, true, row)}
                          </Styled.ChildrenCell>
                        );
                      })}
                    </TableRow>)
                  })}
                </>
              })}
        </TableBody>
        <caption className={dataGridClasses.caption}>
          {shouldShowEmptyMessage && (
            <TableEmptyMessage>{emptyMessage}</TableEmptyMessage>
          )}
          {tableFooter}
        </caption>
      </Styled.Table>
    </Styled.TableContainer>
  );
};
