import * as yup from "yup";
import {DefaultValues, useForm} from "react-hook-form";
import {FC, useCallback, useEffect, useId} from "react";
import {useUser} from "@/hooks/useUser";
import {yupResolver} from "@hookform/resolvers/yup";
import {FeeReportFiltersFieldName} from "@/features/fee-report/components/FeeReportFilters/types";
import {useFeeReportFilters} from "@/features/fee-report/components/FeeReportFilters/useFeeReportFilters";
import {FilterDateRange} from "@/types/base";
import {getBrowserUtcOffset} from "@/utils/getBrowserUtcOffset";
import * as Styled from "@/styles/filter";
import * as FeeReportStyled from "./FeeReportFIlters.styles";
import {
  feeReportFiltersFieldLabelMap
} from "@/features/fee-report/components/FeeReportFilters/constants";
import {
  FormCheckboxTreeSelectField,
  FormDateField,
  FormSelectField,
  FormSelectFieldOptions,
  MerchantsField,
  DatePickerProps,
  OperatorsAndMerchantsField,
  FormCheckboxField
} from "@/components/form";
import {Button} from "@/components/Button";
import {CheckboxTreeNodes} from "@/components/CheckboxTree";
import {dateRangeOptions} from "@/utils/convertToOptions";
import {cleanFormValuesAfterUpdateOptions} from "@/utils/formUtils";
import {transformValuesForBackend} from "@/utils/transformValuesForBackend";

const FIELD_SIZE = "small";

const formSchema = yup
  .object()
  .shape(
    {
      [FeeReportFiltersFieldName.TransactionType]: yup.array().of(yup.string()),
      [FeeReportFiltersFieldName.OperatorsAndMerchants]: yup.object().shape({
        operatorIds: yup.array().of(yup.number().required()),
        merchantIds: yup.array().of(yup.number().required()),
      }),
      [FeeReportFiltersFieldName.FromDate]: yup
        .date()
        .when(FeeReportFiltersFieldName.ToDate, ([toDate], schema) => {
          if (!toDate) {
            return schema;
          }

          return schema
            .required()
            .max(
              toDate,
              `"${feeReportFiltersFieldLabelMap.fromDate}" can't be after "${feeReportFiltersFieldLabelMap.toDate}"`,
            );
        })
        .label(feeReportFiltersFieldLabelMap.fromDate),
      [FeeReportFiltersFieldName.ToDate]: yup
        .date()
        .when(FeeReportFiltersFieldName.FromDate, ([fromDate], schema) => {
          if (!fromDate) {
            return schema;
          }

          return schema
            .required()
            .min(
              fromDate,
              `"${feeReportFiltersFieldLabelMap.toDate}" can't be before "${feeReportFiltersFieldLabelMap.fromDate}"`,
            );
        })
        .label(feeReportFiltersFieldLabelMap.toDate),
      [FeeReportFiltersFieldName.UtcOffset]: yup.string(),
      [FeeReportFiltersFieldName.MerchantIds]: yup
        .array()
        .of(yup.number().required()),
      [FeeReportFiltersFieldName.PpaNames]: yup
        .array()
        .of(yup.string().required()),
      [FeeReportFiltersFieldName.Providers]: yup
        .array()
        .of(yup.string().required()),
      [FeeReportFiltersFieldName.PaymentMethods]: yup
        .array()
        .of(yup.string().required()),
      [FeeReportFiltersFieldName.Currencies]: yup.string(),
      [FeeReportFiltersFieldName.DateRange]: yup
        .mixed<FilterDateRange>()
        .oneOf(Object.values(FilterDateRange))
        .required(),
      [FeeReportFiltersFieldName.ProcessingCurrency]: yup.boolean(),
    },
    [
      [
        FeeReportFiltersFieldName.FromDate,
        FeeReportFiltersFieldName.ToDate,
      ],
    ],
  )
  .required();

export type FeeReportFiltersFormValues = yup.InferType<typeof formSchema>;

type FeeReportFiltersFormDefaultValues =
  DefaultValues<FeeReportFiltersFormValues>;

interface IFeeReportFiltersProps {
  isDataExist: boolean,
  className?: string;
  defaultValues?: FeeReportFiltersFormDefaultValues;
  onClearAllClick: (values: FeeReportFiltersFormValues) => void;
  onApply: (values: FeeReportFiltersFormValues) => void;
}

export const FeeReportFilters: FC<IFeeReportFiltersProps> = ({
  isDataExist,
  className,
  defaultValues,
  onClearAllClick,
  onApply,
}) => {

  const { permissions } = useUser();
  const {
    feeReport: { withAdminFilter, withOperatorsFilter, withMerchantsFilter },
  } = permissions;

  const {
    isFetching,
    isLiveFilter,
    operators,
    merchants,
    utcOffsetOptions,
    ppaNamesOptions,
    providersOptions,
    paymentMethodsOptions,
    getFiltersData,
    currenciesOptions,
    transactionOptions
  } = useFeeReportFilters();

  const formId = useId();

  const { control, handleSubmit, getValues, watch, reset, setValue} =
    useForm<FeeReportFiltersFormValues>({
      resolver: yupResolver<FeeReportFiltersFormValues>(formSchema),
      mode: "onBlur",
    });

  const handleGetFiltersData = useCallback(() => {
    const values = getValues();
    getFiltersData(transformValuesForBackend(values));
  }, [getValues, getFiltersData]);



  const cleanNotExistOptions =(values: any) => {
    const optionConfig ={
      [FeeReportFiltersFieldName.Currencies]: [...currenciesOptions],
      [FeeReportFiltersFieldName.PpaNames]: [...ppaNamesOptions],
      [FeeReportFiltersFieldName.Providers]: [...providersOptions],
      [FeeReportFiltersFieldName.PaymentMethods]: [...paymentMethodsOptions],
    };
     let cleanedFormValues = cleanFormValuesAfterUpdateOptions(optionConfig, values);
     if (values[FeeReportFiltersFieldName.ProcessingCurrency]) {
       cleanedFormValues[FeeReportFiltersFieldName.Currencies] = '';
     } else if(!cleanedFormValues[FeeReportFiltersFieldName.Currencies] && currenciesOptions.length) {
      cleanedFormValues[FeeReportFiltersFieldName.Currencies] =  currenciesOptions[0].value;
    }

    reset(cleanedFormValues);
  };



  const handleFormSubmit = useCallback(
    (values: FeeReportFiltersFormValues) => {
      handleGetFiltersData();
      onApply(transformValuesForBackend(values));
    },
    [handleGetFiltersData, onApply],
  );

  const handleLiveFiltersRefresh = useCallback(() => {
    if (isLiveFilter) {
      handleGetFiltersData();
    }
  }, [isLiveFilter, handleGetFiltersData]);

  const handleClearAllClick = useCallback(() => {
    const values = getValues();
    reset(mergedDefaultValues);
    handleGetFiltersData();
    onClearAllClick(transformValuesForBackend(values));
  }, [getFiltersData, onClearAllClick]);


  const renderCheckboxTreeSelectField = useCallback(
    (name: FeeReportFiltersFieldName, nodes: CheckboxTreeNodes) => (
      <FormCheckboxTreeSelectField
        name={name}
        label={feeReportFiltersFieldLabelMap[name]}
        control={control}
        nodes={nodes}
        disabled={!nodes.length}
        readOnly={isFetching}
        size={FIELD_SIZE}
        onClose={handleLiveFiltersRefresh}
      />
    ),
    [control, isFetching, handleLiveFiltersRefresh],
  );

  const renderSelectField = useCallback(
    (name: FeeReportFiltersFieldName, options: FormSelectFieldOptions, additionalDisableOption = false) => (
      <FormSelectField
        name={name}
        label={feeReportFiltersFieldLabelMap[name]}
        control={control}
        options={options}
        disabled={!options.length || additionalDisableOption}
        readOnly={isFetching}
        size={FIELD_SIZE}
        onClose={handleLiveFiltersRefresh}
      />
    ),
    [control, isFetching, handleLiveFiltersRefresh],
  );

  const mergedDefaultValues = useCallback(() => {
    let result = Object.values(FeeReportFiltersFieldName).reduce(
      (accumulator, fieldName) => ({
        ...accumulator,
        [fieldName]: undefined,
      }),
      {} as FeeReportFiltersFormValues,
    );
    /*@ts-ignore*/
    result = {
      ...result,
      [FeeReportFiltersFieldName.DateRange]: FilterDateRange.Today,
      [FeeReportFiltersFieldName.UtcOffset]: getBrowserUtcOffset(),
      [FeeReportFiltersFieldName.ProcessingCurrency]: false,
      ...defaultValues,
    };
    return result;
  }, []);

  const dateRangeValue = watch(FeeReportFiltersFieldName.DateRange);
  const fromDateValue = watch(FeeReportFiltersFieldName.FromDate);
  const toDateValue = watch(FeeReportFiltersFieldName.ToDate);
  const processingCurrency = watch(FeeReportFiltersFieldName.ProcessingCurrency);

  const renderDateField = useCallback(
    (name: FeeReportFiltersFieldName, datePickerProps?: DatePickerProps) => (
    <FormDateField
      name={name}
      control={control}
      label={feeReportFiltersFieldLabelMap[name]}
      readOnly={isFetching}
      slotProps={{
        textField: {
          size: FIELD_SIZE,
        },
      }}
      onClose={handleLiveFiltersRefresh}
      {...datePickerProps}
    />
  ),
    [control, isFetching, handleLiveFiltersRefresh]);

  const renderCheckboxField = useCallback(
    (name: FeeReportFiltersFieldName ) => (
      <FeeReportStyled.CheckboxWrapper>
        <FormCheckboxField
          name={name}
          control={control}
          label={feeReportFiltersFieldLabelMap[name]}
          readOnly={isFetching}
          onChange={handleLiveFiltersRefresh}
        />
      </FeeReportStyled.CheckboxWrapper>
    ),
    [control, isFetching, handleLiveFiltersRefresh],
  );

  useEffect(() => {
    reset(mergedDefaultValues);
  }, []);

  useEffect(() => {
    handleGetFiltersData();
  }, [handleGetFiltersData]);

  useEffect(() => {
    const values = getValues();
    cleanNotExistOptions(values);

    if (!isDataExist && !values[FeeReportFiltersFieldName.Currencies] && currenciesOptions?.length) {
      onApply(transformValuesForBackend(getValues()));
    }
  }, [ppaNamesOptions]);

  return (
    <div className={className}>
      <Styled.Form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
        <Styled.Fields>
          {withAdminFilter && renderCheckboxTreeSelectField(
            FeeReportFiltersFieldName.TransactionType,
            transactionOptions,
          )}
          {withAdminFilter && (
            <OperatorsAndMerchantsField
              name={FeeReportFiltersFieldName.OperatorsAndMerchants}
              control={control}
              operators={operators}
              label={
                feeReportFiltersFieldLabelMap[
                  FeeReportFiltersFieldName.OperatorsAndMerchants
                  ]
              }
              disabled={!operators.length}
              readOnly={isFetching}
              size={FIELD_SIZE}
              onClose={handleLiveFiltersRefresh}
            />
          )}
          {withOperatorsFilter && (
            <MerchantsField
              name={FeeReportFiltersFieldName.MerchantIds}
              control={control}
              merchants={merchants}
              label={
                feeReportFiltersFieldLabelMap[
                  FeeReportFiltersFieldName.MerchantIds
                  ]
              }
              disabled={!merchants.length}
              readOnly={isFetching}
              size={FIELD_SIZE}
              onClose={handleLiveFiltersRefresh}
            />
          )}
          {renderCheckboxTreeSelectField(
            FeeReportFiltersFieldName.PpaNames,
            ppaNamesOptions,
          )}
          {renderCheckboxTreeSelectField(
            FeeReportFiltersFieldName.Providers,
            providersOptions,
          )}
          {renderCheckboxTreeSelectField(
            FeeReportFiltersFieldName.PaymentMethods,
            paymentMethodsOptions,
          )}
          {renderSelectField(
            FeeReportFiltersFieldName.Currencies,
            currenciesOptions,
            !!processingCurrency
          )}
          {renderCheckboxField(FeeReportFiltersFieldName.ProcessingCurrency)}
        </Styled.Fields>
        <Styled.Fields>
          {renderSelectField(
            FeeReportFiltersFieldName.DateRange,
            dateRangeOptions,
          )}
          {dateRangeValue === FilterDateRange.CustomPeriod && (
            <>
              {renderDateField(FeeReportFiltersFieldName.FromDate, {
                maxDate: toDateValue,
              })}
              {renderDateField(FeeReportFiltersFieldName.ToDate, {
                minDate: fromDateValue,
              })}
            </>
          )}
          {renderSelectField(
            FeeReportFiltersFieldName.UtcOffset,
            utcOffsetOptions,
          )}
        </Styled.Fields>
      </Styled.Form>
      <Styled.Actions>
        <Button variant="outlined" onClick={handleClearAllClick} disabled={isFetching}>
          Clear All
        </Button>
        <Button type="submit" form={formId} disabled={isFetching}>
          Apply Filters
        </Button>
      </Styled.Actions>
    </div>
  );
};


