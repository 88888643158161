export enum MerchantsFieldName {
  Name = 'name',
  SecretKey = 'secretKey',
  AuthCodeDeposit = 'requireAuthCodeDeposit',
  AuthCodeWithdrawal = 'requireAuthCodeWithdrawal',
  UseMerchantTxnIDtoPSP = 'useMerchantTxnIDtoPSP',
  AutoApprove = 'autoApprove',
  CurrencyIds = 'currencyCodes',
  CountryIds = 'countryCodes',
  OperatorId = 'operatorId',
  AdminCreateType = 'isAdminCreateType',
}
