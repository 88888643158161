import {FC, useCallback} from "react";
import {useCreateErrorMappingMutation} from "@/features/error-mapping/errorMappingApi";
import {toast} from "react-toastify";
import {
  ErrorMappingFieldName,
  ErrorMappingForm,
  ErrorMappingFormValues
} from "@/features/error-mapping/components/ErrorMappingForm/ErrorMappingForm";
import {IErrorMappingDialogProps} from "@/features/error-mapping/type";

const disabledFields = [
  ErrorMappingFieldName.PspName,
];


export const ErrorMappingCreateDialog: FC<IErrorMappingDialogProps> = (
  { open, providersOptions, selectedPspName, closeDialog }) => {
  const [createErrorMapping, { isLoading }] = useCreateErrorMappingMutation();
  const handleSubmit = useCallback(
    async ({
             pspName,
             pspCode,
             systemCode,
             message
           }: ErrorMappingFormValues) => {
      await createErrorMapping({
        pspName,
        pspCode,
        message,
        systemCode,
      }).unwrap();

      toast.success(`Mapping for ${pspName} ${pspCode} has been created.`);
    },
    [createErrorMapping],
  );

  return (
    <ErrorMappingForm
      title={`Create Mapping`}
      submitButtonText="Create"
      isSubmitting={isLoading}
      disabledFields={disabledFields}
      defaultValues={{pspName: selectedPspName}}
      onSubmit={handleSubmit}
      providerCodeOptions={[]}
      providersOptions={providersOptions}
      closeDialog={closeDialog}
      open={open}
      isCreateMode={true}
    />
  );
};
