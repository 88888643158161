import * as yup from "yup";
import {DefaultValues, useForm} from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import {FC, useCallback, useEffect, useId} from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import {ROUTES} from "@/routing/constants";
import {handleError} from "@/utils/handleError";
import * as Styled from "./ErrorTranslationFormDialog.styles";

import {
  FormTextField,
} from "@/components/form";
import {Button} from "@/components/Button";
import {ErrorTranslationFieldName} from "@/features/system-errors/type";

const errorTranslationFormSchema = yup
  .object({
    [ErrorTranslationFieldName.Code]: yup
      .string()
      .required()
      .trim()
      .label("Code"),
    [ErrorTranslationFieldName.En]: yup.string().required().trim().label("English"),
    [ErrorTranslationFieldName.Sp]: yup.string().required().trim().label("Spanish"),
    [ErrorTranslationFieldName.De]: yup.string().required().trim().label("German"),
  })
  .required();


export type ErrorTranslationFormValues = yup.InferType<typeof errorTranslationFormSchema>;
export type ErrorTranslationDefaultValues = DefaultValues<ErrorTranslationFormValues>;

interface IErrorTranslationFormDialogProps extends Omit<DialogProps, "onSubmit"> {
  title: string;
  submitButtonText: string;
  defaultValues: ErrorTranslationDefaultValues;
  isSubmitting?: boolean;
  disabledFields?: ErrorTranslationFieldName[];
  onSubmit: (values: ErrorTranslationFormValues) => void;
}

export const ErrorTranslationDialog: FC<IErrorTranslationFormDialogProps> = ({
  title,
  submitButtonText,
  defaultValues,
  isSubmitting,
  disabledFields,
  onSubmit,
  ...restProps
}) => {
  const formId = useId();

  const navigate = useNavigate();

  const { control, handleSubmit, watch, reset, setValue } = useForm<ErrorTranslationFormValues>({
    resolver: yupResolver<ErrorTranslationFormValues>(errorTranslationFormSchema),
    mode: "onBlur",
    defaultValues,
  });

  const handleClose = useCallback(() => {
    navigate(ROUTES.errorTranslations.createURL());
  }, [navigate]);


  const handleFormSubmit = useCallback(
    async (values: ErrorTranslationFormValues) => {
      try {
        await onSubmit(values);
        handleClose();
      } catch (error) {
        handleError(error);
      }
    },
    [onSubmit, handleClose],
  );

  const isFieldDisabled = useCallback(
    (fieldName: ErrorTranslationFieldName) => {
      if (isSubmitting) {
        return true;
      }

      return !!disabledFields?.includes(fieldName);
    }, [isSubmitting, disabledFields]);

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  return (
    <Dialog fullWidth disableRestoreFocus {...restProps}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
          <Styled.Fields>
            <FormTextField
              name={ErrorTranslationFieldName.Code}
              control={control}
              label="Code"
              disabled={isFieldDisabled(ErrorTranslationFieldName.Code)}
            />

            <FormTextField
              name={ErrorTranslationFieldName.En}
              control={control}
              label="En"
              disabled={isFieldDisabled(ErrorTranslationFieldName.En)}
            />

            <FormTextField
              name={ErrorTranslationFieldName.Sp}
              control={control}
              label="Sp"
              disabled={isFieldDisabled(ErrorTranslationFieldName.Sp)}
            />

            <FormTextField
              name={ErrorTranslationFieldName.De}
              control={control}
              label="De"
              disabled={isFieldDisabled(ErrorTranslationFieldName.De)}
            />

          </Styled.Fields>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="text" disabled={isSubmitting} onClick={handleClose}>
          Cancel
        </Button>
        <Button type="submit" form={formId} isLoading={isSubmitting}>
          {submitButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
