import {ReactElement, useCallback, useEffect, useState} from "react";
import { Control, Controller, Path, FieldValues } from "react-hook-form";
import {
  FormControl,
  Select,
  InputLabel,
  FormHelperText,
  MenuItem,
  SelectProps,
} from "@mui/material";
import * as Styled from './FormSelectField.styles';

interface IFormSelectFieldOption<TValue> {
  label: string;
  value: TValue;
}

export type FormSelectFieldOptions<TValue = string> =
  IFormSelectFieldOption<TValue>[];

export type FormSelectFieldProps<
  TFormValues extends FieldValues,
  TValue extends string | number = string,
> = Omit<SelectProps<TValue | TValue[]>, "value" | "onChange"> & {
  name: Path<TFormValues>;
  control: Control<TFormValues>;
  options: FormSelectFieldOptions<TValue>;
  helperText?: string;
  showSearch?: boolean;
};

export const FormSelectField = <
  TFormValues extends FieldValues,
  TValue extends string | number = string,
>({
  className,
  name,
  control,
  options,
  helperText,
  label,
  multiple,
  placeholder = "Select...",
  showSearch = false,
  size,
  ...restProps
}: FormSelectFieldProps<TFormValues, TValue>): ReactElement => {
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredOptions, setFilteredOptions ] = useState<FormSelectFieldOptions<TValue>>([]);

  const renderMultipleValue = useCallback((selected: TValue | TValue[]) => {
    if (Array.isArray(selected)) {
      return selected.join(", ");
    }

    return selected;
  }, []);

  const cleanFilteredOptions = useCallback(() => {
    if (!showSearch) {
      return;
    }
    setFilteredOptions([...options]);
  }, []);

  useEffect(() => {
    setFilteredOptions(options.filter(option => !searchTerm?.trim() || option.label.toLowerCase().includes(searchTerm)))
  }, [options, searchTerm]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const { value, name, ...restField } = field;

        return (
          <FormControl className={className} error={!!error} size={size}>
            <InputLabel>{label}</InputLabel>
            <Select
              label={label}
              error={!!error}
              value={value || (multiple ? [] : "")}
              multiple={multiple}
              renderValue={multiple ? renderMultipleValue : undefined}
              {...restField}
              {...restProps}
            >
              {showSearch && <Styled.Search value={searchTerm} onChange={setSearchTerm} />}
              {filteredOptions.map((option) => (
                <MenuItem key={option.value} value={option.value} onClick={cleanFilteredOptions}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{error?.message || helperText}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};
