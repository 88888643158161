import * as yup from "yup";
import {DefaultValues, useForm} from "react-hook-form";
import {FC, useCallback, useEffect, useId} from "react";
import {useUser} from "@/hooks/useUser";
import {yupResolver} from "@hookform/resolvers/yup";
import {FilterDateRange} from "@/types/base";
import * as Styled from "@/styles/filter";
import {
  FormDateField,
  FormSelectField,
  FormSelectFieldOptions,
  MerchantsField,
  DatePickerProps,
  OperatorsAndMerchantsField,
  FormCheckboxField,
  FormCheckboxTreeSelectField,
} from "@/components/form";
import {Button} from "@/components/Button";
import {DashboardFiltersFieldName} from "@/features/dashboard/components/DashboardFilters/type";
import {dashboardFiltersFieldLabelMap} from "@/features/dashboard/components/DashboardFilters/constants";
import {useDashboardFilters} from "@/features/dashboard/components/DashboardFilters/useDashboardFilters";
import {dateRangeOptions} from "@/utils/convertToOptions";
import {getBrowserUtcOffset} from "@/utils/getBrowserUtcOffset";
import {CheckboxTreeNodes} from "@/components/CheckboxTree";
import {transformValuesForBackend} from "@/utils/transformValuesForBackend";

const FIELD_SIZE = "small";

const formSchema = yup
  .object()
  .shape(
    {
      [DashboardFiltersFieldName.OperatorsAndMerchants]: yup.object().shape({
        operatorIds: yup.array().of(yup.number().required()),
        merchantIds: yup.array().of(yup.number().required()),
      }),
      [DashboardFiltersFieldName.FromDate]: yup
        .date()
        .when(DashboardFiltersFieldName.ToDate, ([toDate], schema) => {
          if (!toDate) {
            return schema;
          }

          return schema
            .required()
            .max(
              toDate,
              `"${dashboardFiltersFieldLabelMap.fromDate}" can't be after "${dashboardFiltersFieldLabelMap.toDate}"`,
            );
        })
        .label(dashboardFiltersFieldLabelMap.fromDate),
      [DashboardFiltersFieldName.ToDate]: yup
        .date()
        .when(DashboardFiltersFieldName.FromDate, ([fromDate], schema) => {
          if (!fromDate) {
            return schema;
          }

          return schema
            .required()
            .min(
              fromDate,
              `"${dashboardFiltersFieldLabelMap.toDate}" can't be before "${dashboardFiltersFieldLabelMap.fromDate}"`,
            );
        })
        .label(dashboardFiltersFieldLabelMap.toDate),
      [DashboardFiltersFieldName.MerchantIds]: yup
        .array()
        .of(yup.number().required()),
      [DashboardFiltersFieldName.Currencies]: yup.string(),
      [DashboardFiltersFieldName.Type]: yup.array().of(yup.string().required()),
      [DashboardFiltersFieldName.Expanded]: yup.boolean(),
      [DashboardFiltersFieldName.UtcOffset]: yup.string(),
      [DashboardFiltersFieldName.DateRange]: yup
        .mixed<FilterDateRange>()
        .oneOf(Object.values(FilterDateRange))
        .required(),
    },
    [
      [
        DashboardFiltersFieldName.FromDate,
        DashboardFiltersFieldName.ToDate,
      ],
    ],
  )
  .required();

export type DashboardFiltersFormValues = yup.InferType<typeof formSchema>;

type DashboardFiltersFormDefaultValues =
  DefaultValues<DashboardFiltersFormValues>;

interface IDashboardFiltersProps {
  className?: string;
  defaultValues?: DashboardFiltersFormDefaultValues;
  onClearAllClick: (values: DashboardFiltersFormValues) => void;
  onApply: (values: DashboardFiltersFormValues) => void;
}

export const DashboardFilters: FC<IDashboardFiltersProps> = ({
  className,
  defaultValues,
  onClearAllClick,
  onApply,
}) => {
  const { permissions } = useUser();
  const {
    feeReport: { withAdminFilter, withOperatorsFilter},
  } = permissions;

  const {
    isFetching,
    operators,
    merchants,
    getFiltersData,
    dashboardFiltersData,
    utcOffsetOptions,
    currenciesOptions,
    typeOptions,
  } = useDashboardFilters();

  const formId = useId();

  const { control, handleSubmit, getValues, watch, reset, setValue } =
    useForm<DashboardFiltersFormValues>({
      resolver: yupResolver<DashboardFiltersFormValues>(formSchema),
      mode: "onBlur",
    });

  const handleGetFiltersData = useCallback(() => {
    const values = getValues();
    getFiltersData(transformValuesForBackend(values));
  }, [getValues, getFiltersData]);

  const handleFormSubmit = useCallback(
    (values: DashboardFiltersFormValues) => {
      handleGetFiltersData();
      onApply(transformValuesForBackend(values));
    },
    [handleGetFiltersData, onApply],
  );

  const handleClearAllClick = useCallback(() => {
    const values = getValues();
    reset(mergedDefaultValues);
    handleGetFiltersData();
    onClearAllClick(transformValuesForBackend(values));
  }, [getFiltersData, onClearAllClick]);

  const renderSelectField = useCallback(
    (name: DashboardFiltersFieldName, options: FormSelectFieldOptions) => (
      <FormSelectField
        name={name}
        label={dashboardFiltersFieldLabelMap[name]}
        control={control}
        options={options}
        disabled={!options.length}
        readOnly={isFetching}
        size={FIELD_SIZE}
      />
    ),
    [control, isFetching],
  );

  const mergedDefaultValues = useCallback(() => {
    let result = Object.values(DashboardFiltersFieldName).reduce(
      (accumulator, fieldName) => ({
        ...accumulator,
        [fieldName]: undefined,
      }),
      {} as DashboardFiltersFormValues,
    );
    /*@ts-ignore*/
    result = {
      ...result,
      [DashboardFiltersFieldName.DateRange]: FilterDateRange.Today,
      [DashboardFiltersFieldName.UtcOffset]: getBrowserUtcOffset(),
      ...defaultValues,
    };

    return result;
  }, []);

  const dateRangeValue = watch(DashboardFiltersFieldName.DateRange);
  const fromDateValue = watch(DashboardFiltersFieldName.FromDate);
  const toDateValue = watch(DashboardFiltersFieldName.ToDate);

  const renderDateField = useCallback(
    (name: DashboardFiltersFieldName, datePickerProps?: DatePickerProps) => (
      <FormDateField
        name={name}
        control={control}
        label={dashboardFiltersFieldLabelMap[name]}
        readOnly={isFetching}
        slotProps={{
          textField: {
            size: FIELD_SIZE,
          },
        }}
        {...datePickerProps}
      />
    ),
    [control, isFetching]);

  const renderCheckboxField = useCallback(
    (name: DashboardFiltersFieldName ) => (
      <FormCheckboxField
        name={name}
        control={control}
        label={dashboardFiltersFieldLabelMap[name]}
        readOnly={isFetching}
      />
    ),
    [control, isFetching],
  );

  const renderCheckboxTreeSelectField = useCallback(
    (name: DashboardFiltersFieldName, nodes: CheckboxTreeNodes) => (
      <FormCheckboxTreeSelectField
        name={name}
        label={dashboardFiltersFieldLabelMap[name]}
        control={control}
        nodes={nodes}
        disabled={!nodes.length}
        readOnly={isFetching}
        size={FIELD_SIZE}
      />
    ),
    [control, isFetching],
  );

  useEffect(() => {
    reset(mergedDefaultValues);
  }, []);

  useEffect(() => {
    handleGetFiltersData();
  }, [handleGetFiltersData]);

  useEffect(() => {
    const values = getValues();
    if (!values[DashboardFiltersFieldName.Currencies] && currenciesOptions?.length) {
      setValue(DashboardFiltersFieldName.Currencies, currenciesOptions[0].value);
      onApply(transformValuesForBackend(getValues()));
    }
  }, [currenciesOptions]);

  return (
    <div className={className}>
      <Styled.Form id={formId} onSubmit={handleSubmit(handleFormSubmit)}>
        <Styled.Fields>
          {renderSelectField(
            DashboardFiltersFieldName.DateRange,
            dateRangeOptions,
          )}
          {dateRangeValue === FilterDateRange.CustomPeriod && (
            <>
              {renderDateField(DashboardFiltersFieldName.FromDate, {
                maxDate: toDateValue,
              })}
              {renderDateField(DashboardFiltersFieldName.ToDate, {
                minDate: fromDateValue,
              })}
            </>
          )}
          {renderSelectField(
            DashboardFiltersFieldName.UtcOffset,
            utcOffsetOptions,
          )}
          {withAdminFilter && (
            <OperatorsAndMerchantsField
              name={DashboardFiltersFieldName.OperatorsAndMerchants}
              control={control}
              operators={operators}
              label={
                dashboardFiltersFieldLabelMap[
                  DashboardFiltersFieldName.OperatorsAndMerchants
                  ]
              }
              disabled={!operators.length}
              readOnly={isFetching}
              size={FIELD_SIZE}
            />
          )}
          {withOperatorsFilter && (
            <MerchantsField
              name={DashboardFiltersFieldName.MerchantIds}
              control={control}
              merchants={merchants}
              label={
                dashboardFiltersFieldLabelMap[
                  DashboardFiltersFieldName.MerchantIds
                  ]
              }
              disabled={!merchants.length}
              readOnly={isFetching}
              size={FIELD_SIZE}
            />
          )}
          {renderSelectField(
            DashboardFiltersFieldName.Currencies,
            currenciesOptions,
          )}
          {renderCheckboxTreeSelectField(
            DashboardFiltersFieldName.Type,
            typeOptions,
          )}
          {renderCheckboxField(DashboardFiltersFieldName.Expanded)}
        </Styled.Fields>
      </Styled.Form>
      <Styled.Actions>
        <Button variant="outlined" onClick={handleClearAllClick}>
          Clear All
        </Button>
        <Button type="submit" form={formId}>
          Apply Filters
        </Button>
      </Styled.Actions>
    </div>
  );
};
