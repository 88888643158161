import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useSearchParams} from "@/hooks/useSearchParams";
import {useDebouncedValue} from "@/hooks/useDebouncedValue";
import {FeatureTitle} from "@/types/featureTitle";
import {PageContent} from "@/components/PageContent";
import {MerchantsDataGridToolbar} from "@/features/merchants/components/MerchantsDataGridToolbar/MerchantsDataGridToolbar";
import {MerchantsTable} from "@/features/merchants/components/MerchantsTable";
import {MerchantCreateDialog} from "@/features/merchants/components/MerchantsCreateDialog";
import {MerchantsEditDialog} from "@/features/merchants/components/MerchantsEditDialog";
import {IGetMerchantsRequestParams, IMerchantsFilters} from "@/features/merchants/types";
import {useLazyGetMerchantsQuery} from "@/features/merchants/merchantsApi";
import {useUser} from "@/hooks/useUser";
import {useGetOperatorsQuery} from "@/features/operators/operatorsApi";
import {UserRole} from "@/types/user";
import {IOperator} from "@/types/operator";
import {handleError} from "@/utils/handleError";
import {MerchantsSettingsDialog} from "@/features/merchants/components/MerchantsSettingsDialog";
import {IMerchant} from "@/types/merchant";

export const MerchantsPage: FC = () => {
  const [openCreateDialog, setOpenCreateDialog] = useState<boolean>(false);
  const [selectedRowData, setSelectedRowData] = useState<any>();
  const [openSettingsDialog, setOpenSettingsDialog] = useState<boolean>(false);
  const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

  const { parsedSearchParams, mergeSearchParams } =
    useSearchParams<IGetMerchantsRequestParams>();
  const debouncedParsedSearchParams = useDebouncedValue(parsedSearchParams);

  const [getMerchants, {data, isFetching, error} ] = useLazyGetMerchantsQuery();

  const { user: currentUser } = useUser();

  const {
    data: operatorsData,
    isFetching: isFetchingOperators,
    error: operatorsFetchError,
  } = useGetOperatorsQuery(undefined, {
    skip: currentUser?.role !== UserRole.Admin,
  });
  const { items: operators = [] } = operatorsData || {};

  const filterOptions = useMemo(() => {
    return operators.map((item: IOperator) => ({
      label: item.name,
      value: item.id,
    }));
  }, [operators]);

  const handleFiltersChange = useCallback(
    ({search, operatorId}: IMerchantsFilters) => {
      mergeSearchParams({
        filters: {
          search: search || undefined,
          operatorId: operatorId || undefined,
        }
      });
    },
    [mergeSearchParams],
  );

  const selectEditData = useCallback((rowData: IMerchant) => {
    setSelectedRowData(rowData);
    showEditDialog();
  }, []);

  const selectSettingsData = useCallback((rowData: IMerchant) => {
    setSelectedRowData(rowData);
    showSettingsDialog();
  }, []);

  const showCreateDialog = useCallback((isOpen = true) => {
    setOpenCreateDialog(isOpen);
  }, []);

  const showEditDialog = useCallback((isOpen = true) => {
    setOpenEditDialog(isOpen);
    if (!isOpen) {
      setSelectedRowData(null);
    }
  }, []);

  const showSettingsDialog = useCallback((isOpen = true) => {
    setOpenSettingsDialog(isOpen);
    if (!isOpen) {
      setSelectedRowData(null);
    }
  }, []);


  useEffect(() => {
    getMerchants({...debouncedParsedSearchParams})
  }, [debouncedParsedSearchParams]);

  useEffect(() => {
    if (operatorsFetchError) {
      handleError(
        operatorsFetchError,
        "Something went wrong during operators fetching. Please try again.",
      );
    }
  }, [operatorsFetchError]);

  return (
    <PageContent title={FeatureTitle.Merchants}>
      <MerchantsDataGridToolbar
        defaultFilterValues={parsedSearchParams?.filters || {}}
        onFilterChange={handleFiltersChange}
        showCreateDialog={showCreateDialog}
        filterOptions={filterOptions}
      />
      <MerchantsTable isFetching={isFetching} items={data?.items || []} showEditDialog={selectEditData} showSettingsDialog={selectSettingsData} />
      <MerchantCreateDialog open={openCreateDialog} closeDialog={showCreateDialog} operatorsOptions={filterOptions} />
      {openEditDialog && <MerchantsEditDialog open={openEditDialog} rowData={selectedRowData} closeDialog={showEditDialog} />}
      {openSettingsDialog &&
      <MerchantsSettingsDialog open={openSettingsDialog} rowData={selectedRowData} closeDialog={showSettingsDialog}/>}
    </PageContent>
  )
};
