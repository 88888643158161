import {api} from "@/store/api";
import {
  IDashboardCountResponseItem,
  IDashboardFilterRequest,
  IDashboardFilterResponse,
  IDashboardMainRequest,
  IDashboardVolumeResponseItem
} from "@/features/dashboard/type";

const URL_PREFIX = "/dashboard";

const dashboardApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardFilters: builder.query<IDashboardFilterResponse, IDashboardFilterRequest>({
      query: (body) => ({
        url: `${URL_PREFIX}/filter`,
        method: "POST",
        body,
      }),
    }),
    getDashboardCounts: builder.query<Array<IDashboardCountResponseItem>, IDashboardMainRequest>({
      query: (body) => ({
        url: `${URL_PREFIX}/transaction-count`,
        method: "POST",
        body,
      }),
    }),
    getDashboardVolumes: builder.query<Array<IDashboardVolumeResponseItem>, IDashboardMainRequest>({
      query: (body) => ({
        url: `${URL_PREFIX}/transaction-volume`,
        method: "POST",
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {useLazyGetDashboardFiltersQuery, useLazyGetDashboardCountsQuery, useLazyGetDashboardVolumesQuery} = dashboardApi;
